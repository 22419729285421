import { TransitionService } from "@uirouter/angularjs";
import { copy } from "angular";
import { Appendix, IGlOption } from "app/core/services/appendix";
import {
  IConsolidatedInjection,
  IGlPatientProcedureInjectionExtended,
  InjectionHelperService,
} from "app/core/services/injection-helper/injection-helper.service";
import { cloneDeep, every, isArray, isEmpty, isEqual, isNil, isObject, omit, set } from "lodash";
import { IGlSide } from "models/gl-side.model";
import { IGlInjection, IGlInjectionRecord, IGlInjectionRecordData } from "models/injection";
import { PATIENT_RECORD_EVENT_SIGN } from "../../../../../app/pages/main.record/record";
import { IGlFormMode } from "../../../../../models/gl-form-mode";
import { PatientProcedureInjection } from "../../../../../models/patient-procedure";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import "./patient-injections.scss";


export type NewOrExisting<T extends PatientProcedureInjection> = Pick<
  T,
  "data" | "id"
>;

export class PatientInjectionsController
  implements angular.IComponentController {
  // @Inputs()
  currentRecordId: number;
  injections: IConsolidatedInjection[];

  // minimised version that only checks data of the first injection of the group
  injectionsClone: IGlPatientProcedureInjectionExtended[];

  mode: IGlFormMode = "display";

  // @Outputs

  // Controller Properties
  showInjectionForm = false;
  injectionOptions = this.appendix.get("intravitreal-injection");
  frequencyOptions = this.appendix.get("injection-frequency", true);
  eyeModel: IGlSide;
  leftInjectionModel: NewOrExisting<PatientProcedureInjection>;
  rightInjectionModel: NewOrExisting<PatientProcedureInjection>;
  saveInProgress = false;
  deleteInProgress = false;
  dropsForRecord: PatientProcedureInjection[];
  editInjectionForm: angular.IFormController;

  // any changes detected without going through edit model?
  changesDetected: boolean = false;

  // filtered options that removes 1 and complete
  filteredFrequencyOptions: IGlOption[] = [];

  // pristine (default is yes)
  injectionsPristine: boolean = true;

  // update injections
  onInjectionsUpdate: () => void;

  constructor(
    private $q: angular.IQService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private appendix: Appendix,
    private InjectionHelperService: InjectionHelperService,
    private PatientProcedureService: PatientProcedureService,
    private toastr: angular.toastr.IToastrService,
    private $transitions: TransitionService,
    private $scope: angular.IScope,
  ) {
    "ngInject";
  }

  $onInit(): void {
    // removes the full injection and 
    this.filteredFrequencyOptions = this.frequencyOptions.filter((f: IGlOption) => !["1", "complete"].includes(f.key));

    // setup listener for signing which will save all changes on record save
    this.$scope.$on(PATIENT_RECORD_EVENT_SIGN, () => {
      if (!this?.injectionsPristine && this.currentRecordId) {
        this.saveClickedWithoutEditForm();
      }
    });

    // on exit hook
    this.$transitions.onExit({}, () => {
      if (this.injectionsPristine === false) {
        const confirm: boolean = window.confirm(
          `There are unsaved injection frequency changes, are you sure you want to exit? All changes will be lost if you do so.`
        );

        if (confirm) {
          // no changes 
          this.injectionsPristine = true;
        }

        return confirm;
      }
    });
  }


  $postLink(): void {
    this.updateInjectionsClone();
  }

  updateInjectionsClone() {
    this.injectionsClone = cloneDeep((this.injections ?? [])?.map((i) => i?.injections?.[0]));
    this.injectionsHasChanged();
  }

  isEditMode() {
    return this.mode === "edit";
  }

  addInjection(injectionDefaults?: PatientProcedureInjection) {
    if (!injectionDefaults) {
      this.eyeModel = "both";
      this.leftInjectionModel = {
        data: {
          eye: "left",
          name: undefined,
          frequency: undefined,
          initial_count: undefined,
          repeat_count: undefined,
        },
      };
      this.rightInjectionModel = {
        data: {
          eye: "right",
          name: undefined,
          frequency: undefined,
          initial_count: undefined,
          repeat_count: undefined,
        },
      };
    } else {
      this.eyeModel = injectionDefaults.data.eye;
      if (injectionDefaults.data.eye === "left") {
        this.leftInjectionModel = { data: { ...injectionDefaults.data } };
        // delete initial count if the defaults includes it
        this.leftInjectionModel.data.initial_count = undefined;
      }
      if (injectionDefaults.data.eye === "right") {
        this.rightInjectionModel = { data: { ...injectionDefaults.data } };
        this.rightInjectionModel.data.initial_count = undefined;
      }
    }
    this.showInjectionForm = true;
    this.setEditFormToPristine();
  }

  editInjection(
    injection: PatientProcedureInjection,
    event: CustomEvent<MouseEvent>
  ) {
    this._stopPropagation(event);

    if (injection.record_id !== this.currentRecordId) {
      this.addInjection(injection);
    } else {
      if (injection.data.eye === "left") {
        this.eyeModel = "left";
        this.leftInjectionModel = copy(injection);
      }
      if (injection.data.eye === "right") {
        this.eyeModel = "right";
        this.rightInjectionModel = copy(injection);
      }
      this.showInjectionForm = true;
      this.setEditFormToPristine();
    }
  }

  canEditInjection(injection: PatientProcedureInjection) {
    return injection.record_id === this.currentRecordId;
  }

  getDrops() {
    return this.dropsForRecord || this.injections;
  }

  saveClicked() {
    let savePromise;
    let create: boolean = false;

    if (!this.leftInjectionModel?.id && !this.rightInjectionModel?.id) {
      const data: any = {};
      if (["both", "left"].includes(this.eyeModel)) {
        data.left = this.leftInjectionModel.data;
      }
      if (["both", "right"].includes(this.eyeModel)) {
        data.right = this.rightInjectionModel.data;
      }
      savePromise = this.PatientProcedureService.createInjection(
        this.currentRecordId,
        data
      );
      create = true;
    } else {
      // depending on whether the model is defined, determine which one
      // to update
      const injectionToUpdate = this.leftInjectionModel?.id
        ? this.leftInjectionModel
        : this.rightInjectionModel;

      savePromise = this.PatientProcedureService.updateInjection(
        this.currentRecordId,
        injectionToUpdate
      );
    }

    this.$q
      .resolve()
      .then(() => {
        this.saveInProgress = true;
        return savePromise;
      })
      .then((res) => {
        // if create mode, for each of the injections found, add to the clone list
        // create returns an array 
        if (create && isArray(res)) {
          // filter out one off/complete injections
          const injectionsToAdd: IGlPatientProcedureInjectionExtended[] =
            res
              .filter(
                (g) =>
                  this.isFrequencyChangeable((g as PatientProcedureInjection).data.frequency.key)
              );

          // add in and update
          this.injectionsClone.concat(
            cloneDeep(injectionsToAdd)
          );
          this.injectionsHasChanged();
        } else if (isObject(res) && !isArray(res)) {
          // otherwise we are updating the clone model
          // update by reference to the model being updated
          const injectionToUpdate = this.leftInjectionModel?.id
            ? this.leftInjectionModel
            : this.rightInjectionModel;

          // update if frequency is toggleable
          // delete if not toggleable
          this.updateInjectionFromCloneGroup(
            injectionToUpdate.id,
            // if not changeable, delete
            !this.isFrequencyChangeable(injectionToUpdate.data.frequency.key)
          );
        }

        // finalise
        this.cancelClicked();
        this.toastr.success(`Successfully ${create ? 'created' : "saved"} injection!`);
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error(`An error has occured whilst ${create ? 'creating' : "saving"}, please try agian.`);
      })
      .finally(() => (this.saveInProgress = false));
  }

  deleteClicked() {
    const shouldDeleteInjection = this.$window.confirm(
      "Are you sure you want to remove this injection?"
    );
    if (shouldDeleteInjection) {
      this.$q
        .resolve()
        .then(() => {
          this.deleteInProgress = true;
          if (this.leftInjectionModel?.id) {
            // get parent injection group for future reference
            const parentInjectionGroup: IConsolidatedInjection =
              this.injections.find(
                (g) => g.injections.find(
                  (i) => i.id === this.leftInjectionModel.id)
              );

            return this.PatientProcedureService.deleteInjection(
              this.currentRecordId,
              this.leftInjectionModel.id
            )
              .then(() => {
                // update clone group
                this.deleteInjectionFromCloneGroup(
                  parentInjectionGroup,
                  this.leftInjectionModel?.id
                );
              });
          }
        })
        .then(() => {
          if (this.rightInjectionModel?.id) {
            // get parent injection group
            const parentInjectionGroup: IConsolidatedInjection =
              this.injections.find(
                (g) => g.injections.find(
                  (i) => i.id === this.rightInjectionModel.id)
              );

            return this.PatientProcedureService.deleteInjection(
              this.currentRecordId,
              this.rightInjectionModel.id
            )
              .then(() => {
                // update clone group
                this.deleteInjectionFromCloneGroup(
                  parentInjectionGroup,
                  this.rightInjectionModel?.id
                );
              });
          }
        })
        .then(() => {
          this.cancelClicked();
          this.toastr.success('Successfully deleted injection!');
        })
        .catch((err) => {
          console.error(err);
          this.toastr.error(`An error has occured whilst deleting, please try agian.`);
        })
        .finally(() => (this.deleteInProgress = false));
    }
  }

  cancelClicked() {
    this.showInjectionForm = false;
    this.leftInjectionModel = undefined;
    this.rightInjectionModel = undefined;
  }

  injectionTypeDidChange(side: "left" | "right") {
    const injection =
      side === "left" ? this.leftInjectionModel : this.rightInjectionModel;
    const name = injection?.data?.name;
    const defaultFrequency = ["triesence", "ozurdex"].includes(name?.key)
      ? this.frequencyOptions.find((f) => f.key === "1")
      : this.frequencyOptions.find((f) => f.key === "4");
    if (defaultFrequency) {
      injection.data.frequency = defaultFrequency;
      this.injectionFrequencyDidChange(side);
    }
    if (!name) {
      // clear all other fields;
      injection.data.frequency =
        injection.data.initial_count =
        injection.data.repeat_count =
        undefined;
    }
  }

  injectionFrequencyDidChange(side: "left" | "right") {
    const injection =
      side === "left" ? this.leftInjectionModel : this.rightInjectionModel;
    const frequency = injection?.data?.frequency;
    if (frequency) {
      injection.data.repeat_count = frequency.repeats;
    }
  }

  copyName(toSide: "left" | "right") {
    const fromSide = toSide === "left" ? "right" : "left";
    const fromData = this.getInjectionDataForSide(fromSide);
    const toData = this.getInjectionDataForSide(toSide);
    const name = fromData?.data?.name;
    const nameOther = fromData?.data?.name_other;
    if (name) {
      toData.data.name = { ...name };
    }
    if (nameOther) {
      toData.data.name_other = nameOther;
    }
    this.injectionTypeDidChange(toSide);
  }

  formatInjectionForDisplay(record: IGlInjectionRecord, side: IGlSide) {
    const data: IGlInjectionRecordData = record.data?.[side];

    const routineStr = data?.routine ? "Routine" : "Complicated";
    const delayedStr = data?.delayed ? " (Delayed)" : "";
    return routineStr + delayedStr;
  }

  getInjectionBgColor(injectionGroup: IConsolidatedInjection) {
    // return error/red if any one of the completed injections is complicated
    const isComplicated = injectionGroup.injections
      .flatMap((i) => i.records ?? [])
      .find((r) => {
        if (injectionGroup.eye === "left") {
          return r.data?.left?.routine === false;
        } else {
          return r.data?.right?.routine === false;
        }
      });
    if (isComplicated) {
      return "bg-complicated";
    } else if (
      injectionGroup.injections[0].data?.frequency?.key === "complete"
    ) {
      return "bg-completed";
    }
  }

  getInjectionNumber(injection: PatientProcedureInjection, recordId: number) {
    return this.InjectionHelperService.getInjectionCountForRecord(
      injection,
      recordId
    );
  }

  getInjectionGroupTotal(injectionGroup: IConsolidatedInjection) {
    // not empty
    return this.InjectionHelperService.getInjectionGroupTotal(injectionGroup);
  }

  injectionAtOrExceedMaxRepeatCount(injection: PatientProcedureInjection, recordId: number) {
    const currentCycleCount: number = this.getInjectionNumber(injection, recordId);
    const maxCycleCount: number = injection?.data?.repeat_count;

    return currentCycleCount >= maxCycleCount;
  }

  /* enhanced injection button stuff */
  increaseFrequency(injection: PatientProcedureInjection, event: CustomEvent<MouseEvent>) {
    this._stopPropagation(event);

    // if at max ignore
    const frequency: string = injection?.data?.frequency?.key;

    // if not at max, set to the next one above index wise
    if (this.canIncreaseFrequency(injection)) {
      const index: number = this.filteredFrequencyOptions.findIndex((f: IGlOption) => f.key === frequency);
      injection.data.frequency = this.filteredFrequencyOptions[index + 1];

      // enact a change
      this.injectionsHasChanged();
    }
  }

  decreaseFrequency(injection: PatientProcedureInjection, event: CustomEvent<MouseEvent>) {
    this._stopPropagation(event);

    // if at min ignore
    const frequency: string = injection?.data?.frequency?.key;

    // if not at min, set to the next below index wise
    if (this.canDecreaseFrequency(injection)) {
      const index: number = this.filteredFrequencyOptions.findIndex((f: IGlOption) => f.key === frequency);
      injection.data.frequency = this.filteredFrequencyOptions[index - 1];

      // enact a change
      this.injectionsHasChanged();
    }
  }

  /*
    resetting a cycle is the same as creating a new injection procedure
    since all of the same injections are grouped under one, 
    we will use the exisiting data for it 

    a reset cycle will put its parent injection group tot he top
  */
  resetCycle(injection: PatientProcedureInjection, event: CustomEvent<MouseEvent>) {
    this._stopPropagation(event);

    // create a new cycle given the data information
    const data: IGlInjection = cloneDeep(injection.data);
    // if new cycle, there is no intial count
    delete data.initial_count;

    // format it so we know which eye to create for
    const createParam: { left?: IGlInjection, right?: IGlInjection; } = this._createInjectionRequestBody(injection);

    // update 
    this.$q
      .resolve()
      .then(() => {
        this.saveInProgress = true;
        return this.PatientProcedureService.createInjection(this.currentRecordId, createParam);
      })
      .then(() => {
        // update injection group individually
        // since with cycle resets, it applies all changes
        this.updateInjectionGroupByInjectionId(injection.id);
      })
      .then(() => {
        // update injection clone group individually
        this.updateInjectionFromCloneGroup(injection.id);

        this.toastr.success('Successfully reset cycle!');
        this.cancelClicked();
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error(`An error has occured whilst resetting the cycle, please try agian.`);
      })
      .finally(() => (this.saveInProgress = false));
  }

  // some options cant be changed
  isFrequencyChangeable(frequencyKey: string) {
    // 1 is a once off, complete doesnt need to be actioend
    return !["1", "complete"].includes(frequencyKey);
  }

  // general guards
  canIncreaseFrequency(injection: PatientProcedureInjection) {
    return ![
      "1",
      "complete",
      this.filteredFrequencyOptions.slice(-1)[0].key
    ].includes(injection?.data?.frequency?.key);

  }

  canDecreaseFrequency(injection: PatientProcedureInjection) {
    return ![
      "1",
      "complete",
      this.filteredFrequencyOptions[0].key
    ].includes(injection?.data?.frequency?.key);
  }

  /*
    a cycle can only be reset if 
    - it isnt a one off or completed
    - no pending frequency changes have been made to it yet

    currently resetting a cycle will also override any changes when 
    editing the injection

    we need a way to stop

    NOTE: for performance purposes we terminate early if any of these fail
  */
  canResetCycle(injection: PatientProcedureInjection) {
    // cycle must has at least one injection in it 
    const currentCycleCount: number =
      this.getInjectionNumber(injection, this.currentRecordId);
    if (currentCycleCount <= 0) {
      return false;
    }

    // can be toggled
    const frequencyChangeable: boolean = ![
      "1",
      "complete"
    ].includes(injection?.data?.frequency?.key);
    if (!frequencyChangeable) {
      return false;
    }

    // is the injection pristine?
    // get the reference injection to determine if its pristine
    // frequency is sufficient enough
    const referenceInjection = this.injectionsClone
      ?.find((i) => i.id === injection.id);
    // then do a comaprison
    const injectionUnchanged: boolean =
      referenceInjection && (referenceInjection?.data?.frequency?.key === injection?.data?.frequency?.key);
    if (!injectionUnchanged) {
      return false;
    }

    // else ok, can reset
    return true;
  }

  // save clicked without edit form, will save all
  // injections that have been edited
  saveClickedWithoutEditForm() {
    // get reference as there is a chance the 
    // passed variable could be lost 
    // e.g. on sign
    const recordId: number = cloneDeep(this.currentRecordId);
    // find injections with changes 
    // map them out to just the first injection since
    // thats what will be changed
    const injectionsWithChanges: IGlPatientProcedureInjectionExtended[] =
      this.injections?.filter((g: IConsolidatedInjection) => {
        return !isEqual(
          // get first injection from group
          g.injections[0],
          // compare it to a clone instance
          this.injectionsClone.find((i) => i.id === g.injections[0].id)
        );
      })?.map((g: IConsolidatedInjection) => g?.injections?.[0]) ?? [];


    // nothing to update? dont bother
    if (isEmpty(injectionsWithChanges)) {
      return;
    }

    // initiate save
    this.saveInProgress = true;

    // create promises depending on whether its within same record id or not
    // to determine if we need to update or create
    this.$q
      .resolve()
      .then(() => {
        // as we are passing down a singular injection, a side has been declared for it already
        // the chances of it being "Both" is nil
        return Promise.all(injectionsWithChanges.map((i: IGlPatientProcedureInjectionExtended) =>
          // easiest way is to go by whether created_in record id === record.id 
          i?.record_id !== recordId
            ? this.PatientProcedureService.createInjection(
              recordId,
              this._createInjectionRequestBody(i)
            )
            : this.PatientProcedureService.updateInjection(
              recordId,
              i
            )
        )
        );
      })
      .then(() => {
        this.toastr.success('Successfully saved changes to injections!');
        this.updateInjectionsClone();
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error('An error has occured whilst saving, please try agian.');
      })
      .finally(() => {
        this.saveInProgress = false;
      });

  }

  // given an injection
  // check against the clone version to see if there are any changes
  injectionsHasChanged() {
    // if array is emtpy return true
    if (isNil(this.injectionsClone) || isEmpty(this.injectionsClone)) {
      return true;
    }

    // save on processing to avoid extreme comparison
    // across all attributes
    // only the first injection of the group will be altered
    this.injectionsPristine = every(this.injections, (group: IConsolidatedInjection) => {
      // if the group has no injections, return true
      // as there isnt anything to compare realistically
      if (isNil(group.injections) || isEmpty(group.injections)) {
        return true;
      }

      // else we prepare to compare

      // omit some fields as we want to just compare the data 
      // from the latest injection data we have
      const primary: Partial<IGlPatientProcedureInjectionExtended> = omit(
        group.injections[0],
        'updated_at'
      );
      // what we have as a reference
      const clone = omit(this.injectionsClone.find((i) => i.id === group.injections[0].id), 'updated_at') ?? null;

      // otherwise compare outright
      return isEqual(
        primary, clone,
      );
    });
  }

  // updates individual reference to avoid messing around with 
  // any unchanged data
  updateInjectionGroupByInjectionId(injectionId: number) {
    // this should replace the clone injection by old reference
    // 1. with reference to new cloned injections, find the one that includes the old injection
    const replacementGroup: IConsolidatedInjection = this.PatientProcedureService.getConsolidatedInjections()
      .find((g) => g.injections.find((i) => i.id === injectionId));

    // then find the current group with said injection
    const currentGroupIndex: number = this.injections
      .findIndex((g) => g.injections.find((i) => i.id === injectionId));

    // replace if index is found
    if (currentGroupIndex !== -1) {
      this.injections[currentGroupIndex] = replacementGroup;
      this.injectionsHasChanged();
    }
  }

  // with reference to the old injection before update
  // update the injection clone group
  updateInjectionFromCloneGroup(oldInjectionId: number, deleteInjection: boolean = false) {
    // this should replace the clone injection by old reference
    // 1. with reference to new cloned injections, find the one that includes the old injection
    const newInjectionGroup: IConsolidatedInjection = this.PatientProcedureService.getConsolidatedInjections()
      .find((g) => g.injections.find((i) => i.id === oldInjectionId));

    // 2. with reference to that group, replace the index in the cloned array
    const targetIndex: number = this.injectionsClone.findIndex((i) => i.id === oldInjectionId);
    // update by reference to the most recent injection
    if (targetIndex !== -1) {
      // if deleting, remove the clone
      // otherwise update the clone
      deleteInjection
        ? this.injectionsClone.splice(targetIndex, 1)
        : set(this.injectionsClone, targetIndex, newInjectionGroup.injections?.[0]);
    }

    // if we arent deleting the injection, also update the reference based 
    // on its injection group
    if (!deleteInjection && oldInjectionId) {
      this.updateInjectionGroupByInjectionId(oldInjectionId);
    }

    // then do a refresh
    this.injectionsHasChanged();
  }

  // with reference to the deleted injection
  // delete the injection from the clone group
  // or replace with the most recent remaining one from said group
  deleteInjectionFromCloneGroup(injectionGroup: IConsolidatedInjection, injectionId: number) {
    // filter out remaining injections
    const remainingInjections = injectionGroup.injections.filter((i) => i.id !== injectionId);
    // find relevant index in clone group
    const deleteIndex: number = this.injectionsClone.findIndex((i) => i.id === injectionId);
    // if theres an index, delete
    if (deleteIndex !== -1) {
      // if after deleting the injection,
      // theres still more remaining in the cycle, persist with the next most
      // recent one remaining
      // otherwise delete as is
      remainingInjections?.length
        ? this.injectionsClone.splice(deleteIndex, 1, remainingInjections[0])
        : this.injectionsClone.splice(deleteIndex, 1);

      // enact a change
      this.injectionsHasChanged();
    }
  }

  /**
   * if an injection frequency has been edited via the quick edit buttons, this allows
   * a reset back to original state for all
   */
  resetChangesWithoutEditForm() {
    const confirm: boolean = window.confirm('All unsaved frequency changes will be lost, are you sure you want to reset all changes?');
    // ignore if false
    if (!confirm) {
      return;
    }

    // otherwise continue by setting the original state 
    this.injectionsClone?.forEach((injection: IGlPatientProcedureInjectionExtended) => {
      // find which injection group it belongs to 
      const parentGroupIndex: number = this.injections
        .findIndex((g) => g.injections.find((i) => i.id === injection.id));

      if (parentGroupIndex === -1) {
        return;
      }

      // parent group found? continue to replace with what we have as a baseline
      const indexOfInjection: number = this.injections[parentGroupIndex]?.injections
        ?.findIndex((i) => i.id === injection.id);

      // we just need to update the data as frequency is changed
      // ensure we have a copy to avoid any comparison problems
      if (indexOfInjection !== -1) {
        this.injections[parentGroupIndex].injections[indexOfInjection]
          .data = { ...injection.data };
      }
    });

    // update at the end
    this.injectionsHasChanged();
  }

  private _stopPropagation(event: CustomEvent<MouseEvent>) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }


  private getInjectionDataForSide(side: "left" | "right") {
    return side === "left" ? this.leftInjectionModel : this.rightInjectionModel;
  }

  private setEditFormToPristine() {
    // use timeout so we set the form after angular has had a chance to update
    // hacky - yes - but it works
    this.$timeout().then(() => {
      if (this.editInjectionForm) {
        this.editInjectionForm.$setPristine();
      }
    });
  }

  private _createInjectionRequestBody(injection: PatientProcedureInjection) {
    // create a new cycle given the data information
    const data: IGlInjection = cloneDeep(injection.data);
    // if new cycle, there is no intial count
    delete data.initial_count;

    // format it so we know which eye to create for
    const createRequest: { left?: IGlInjection, right?: IGlInjection; } = {};
    createRequest[data.eye] = data;

    return createRequest;
  }
}

export class PatientInjectionsComponent implements angular.IComponentOptions {
  static selector = "patientInjections";
  static template = require("./patient-injections.html");
  static controller = PatientInjectionsController;
  static bindings = {
    currentRecordId: "<",
    injections: "<",
    mode: "@",
    recordData: "<",
    onInjectionsUpdate: "&?"
  };
}
