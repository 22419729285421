import { AppointmentService } from "app/core/services/appointments/appointments.service";
import { defaults } from "lodash";
import {
  Appendix,
  IGlOption
} from "../../../../../app/core/services/appendix";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import "./segment-clinical-data.scss";
import angular = require("angular");

export const FIELDS = [
  "va",
  "iop",
  "refraction",
  "lens",
  "lens_notes",
  "cct",
  "tech_notes",
  "dominance",
] as const;

class SegmentClinicalDataController
  extends GlFormController
  implements angular.IController, angular.IOnChanges {
  showReasonForReferral = true;
  appointmentNotes?: string;
  presentingComplaintOptions = this.appendix.get("presentingComplaint", true);
  public record: {
    presenting_complaint: IGlOption;
    reason_for_referral: string;
  };

  constructor(
    private appendix: Appendix,
    private AppointmentService: AppointmentService,
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (
      (changes.record || changes.isEditable) &&
      this.record &&
      this.isEditable
    ) {
      defaults(this.record, {
        presenting_complaint: this.getDefaultPresentingComplaint(),
      });
    }
  }

  private getDefaultPresentingComplaint(): IGlOption {
    const presentingComplaintFromAppointmentNotes =
      this.determinPresentingComplaintFromAppointment(this.appointmentNotes);
    return (
      presentingComplaintFromAppointmentNotes ??
      (this.appendix.getDefaultKey("presentingComplaint") as IGlOption)
    );
  }

  private determinPresentingComplaintFromAppointment(
    appointmentNotes: string
  ): IGlOption {
    if (!appointmentNotes || typeof appointmentNotes !== "string") {
      return;
    }

    // find out appointment type by regex
    const lowercaseAppointmentNotes = appointmentNotes.toLocaleLowerCase();
    // fetch options to assing the correct one
    const allOptions = this.appendix.get("presentingComplaint");

    if (this.AppointmentService.isRetinaAppointmentByRegex(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "retina");
    } else if (this.AppointmentService.isGlaucomaAppointmentByRegex(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "glaucoma");
    } else if (this.AppointmentService.isCataractAppointmentByRegex(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "cataract");
    } else if (this.AppointmentService.isDryEyeAppointmentByRegex(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "dry_eye");
    } else {
      // default we assume is cataract
      return allOptions.find((o) => o.key === "cataract");
    }
  }
}

export class SegmentClinicalData implements angular.IComponentOptions {
  static selector = "segmentClinicalData";
  static template = require("./segment-clinical-data.html");
  static controller = SegmentClinicalDataController;
  static bindings = {
    record: "<",
    medicalHistoryData: "<?",
    recordHistory: "<",
    showReasonForReferral: "<?",
    enableLeft: "<",
    appointmentNotes: "<?",
    enableRight: "<",
    newRecord: "<?",
    ...GlFormControllerBindings,
  };
}
