import { IConsolidatedInjection } from "app/core/services/injection-helper/injection-helper.service";
import { isFunction, isNil } from "lodash";
import { GlPatientRecordWorkflowState, PatientRecord } from "models/patient-record.model";
import { Patient, User } from "models/user.model";

import './button-power.scss';

export interface IButtonPowerActionSteps {
  createInjection?: boolean,
  sign?: boolean,
  sendToAdmin?: boolean,
}

// disable if
export interface IButtonPowerActionDisableConditions {
  // must have injections and none of them must be at max cycle
  validInjections?: boolean;
  recordSigned?: boolean;
}

export interface ButtonPowerAction {
  text: string,
  id: string, // also testid
  steps: IButtonPowerActionSteps;
  // disable if certain conditions are met?
  disableIf?: IButtonPowerActionDisableConditions;
}



export const BUTTON_POWER_ACTION_TYPES: ButtonPowerAction[] = [
  {
    id: 'btn-sign-admin',
    text: 'Sign + Send to Admin',
    steps: {
      sign: true,
      sendToAdmin: true
    },
    disableIf: {
      recordSigned: true
    }
  },
  {
    text: 'Sign + Create Injection',
    id: 'btn-sign-injection',
    steps: {
      sign: true,
      createInjection: true
    },
    disableIf: {
      validInjections: true,
      recordSigned: true
    }
  },
  {
    text: 'Sign + Create Injection + Send to Admin',
    id: 'btn-sign-admin-injection',
    steps: {
      sign: true,
      createInjection: true,
      sendToAdmin: true
    },
    disableIf: {
      validInjections: true,
      recordSigned: true
    }
  }
];

export class ButtonPowerController implements angular.IController {
  user: User;
  patient: Patient;
  record: PatientRecord;

  recordType: "history" | "record" = "record";

  saveInProgress: boolean = false;
  signInProgress: boolean = false;
  canCreateInjection: boolean = false;

  // state dependencies
  injections: IConsolidatedInjection[];

  save: () => void;
  gotoState: (arg: { nextWorkflowState?: GlPatientRecordWorkflowState; }) => void;
  updateWorkflow: (arg: { state: string, activeRecord: PatientRecord; }) => angular.IPromise<void>;

  signRecord: (arg?: { skipOphthalReview?: boolean; }) => void;

  createInjection: () => void;

  buttonPowerActions: {
    text: string,
    id: string, // also testid
    steps: IButtonPowerActionSteps;
  }[] = BUTTON_POWER_ACTION_TYPES;

  actionInProgress: boolean = false;
  // used to display the loading thing
  actionExecutingId: string = undefined;

  constructor(
    private $window: angular.IWindowService,
    private $q: angular.IQService,
    private toastr: angular.toastr.IToastrService,
  ) {
    "ngInject";
  }

  // guards
  hasInjections() {
    return this.injections?.length > 0;
  }

  recordIsSigned() {
    return this?.record?.data_status === 'SIGNED';
  }

  // check action guards 
  actionIsDisabled(disableConditions: IButtonPowerActionDisableConditions) {
    // if a save or sign is in progress disable outright
    if (this.saveInProgress || this.signInProgress) {
      return true;
    }
    // if nothing, ignore
    if (!disableConditions) {
      return false;
    }

    // else break it up
    const {
      validInjections,
      recordSigned
    } = disableConditions;


    // if there are no valid injections to create from
    // also ahndels no injetions case
    if (validInjections && !this.canCreateInjection) {
      return true;
    }
    // is signed
    if (recordSigned && this.recordIsSigned()) {
      return true;
    }

    // default nothing is stopping it
    return false;
  }

  // action being executed
  isExecuting(id: string) {
    return !isNil(this.actionExecutingId) && this.actionExecutingId === id;
  }

  // we have a group of functions that are passed down
  // we just create the logic using a promise chain based on what we have 
  // kind of a build a bear for the record itself
  handleCompoundAction(action: ButtonPowerAction) {
    // silently return if an action is executing
    if (this.actionInProgress) {
      return;
    }

    // warn if action is disabled
    if (this.actionIsDisabled(action?.disableIf)) {
      return this.toastr.error('Action disabled');
    }

    // FLAGS
    this.actionInProgress = true;
    this.actionExecutingId = action?.id;

    // actions
    const {
      createInjection = false,
      sign = false,
      sendToAdmin = false
    } = action.steps;


    // if current user is an optometrist and user is subscribed
    // give them a prompt to confirm whether to make a VR or not 
    // proxy sign is disabled here as this is for regular record usage
    // default is to skip
    let skipOphthalReview: boolean = true;
    if (this.patient?.subscribed && this.user.type.name === 'optometrist') {
      // Yes === dont skip (false), No === Skip (true)
      skipOphthalReview = !this.$window.confirm('Would you like to create/update a Virtual Review for this patient as well?');
    }

    // then promise chain
    this.$q
      .resolve()
      // we handle in a specific order to avoid issues
      // similar to how a noraml workflow would work
      .then(() => {
        if (createInjection && isFunction(this.createInjection)) {
          return this.createInjection();
        }
      })
      .then(() => {
        if (sign && isFunction(this.signRecord)) {
          return this.signRecord({ skipOphthalReview });
        }
      })
      .then(() => {
        if (sendToAdmin && isFunction(this.gotoState)) {
          // first get what workflow it is at
          const workflowDenotation = this.getWorkflowStateDenotation(this.record.workflow_state);
          const adminState = `Admin ${workflowDenotation}`;

          // then set the workflow so that it will save and progress
          this.updateWorkflow({ state: adminState, activeRecord: this.record });
          this.gotoState({ nextWorkflowState: adminState as GlPatientRecordWorkflowState });
        }
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error('Failed to execute compound actions, please try executing them manually');
      })
      .finally(() => {
        this.actionInProgress = false;
        this.actionExecutingId = undefined;
      });

    // debugger
    // this.$timeout(10000)
    //   .then(() => {
    //     console.log(skipOphthalReview);
    //     this.actionInProgress = false;
    //     this.actionExecutingId = undefined;
    //   });
  }


  // returns the (C) ,(O) etc..., default is (O)
  getWorkflowStateDenotation(workflowState: GlPatientRecordWorkflowState) {
    const re = /\((.*)\)/i;
    // get inner matching group
    const state = workflowState?.match(re)?.[1];
    const validStates: string[] =
      ["INJ", "R", "G", "C", "O"];

    // not a valid state? ignore
    if (!validStates.includes(state)) {
      return "(O)";
    }

    // else return it as it is 
    // the first match is the whole thing
    return workflowState?.match(re)?.[0];

  }

}

export class ButtonPower implements angular.IComponentOptions {
  static selector = "glButtonPower";
  static controller = ButtonPowerController;
  static template = require('./button-power.html');
  static bindings = {
    user: "<",
    patient: "<",
    record: "<",

    injections: "<",

    save: "&",
    gotoState: "&",
    signRecord: "&",
    updateWorkflow: "&",
    createInjection: "&",

    // can create injection?
    canCreateInjection: "<",

    signInProgress: "<?",
    saveInProgress: "<?"
  };
}