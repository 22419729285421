import { IConsentFormRisk } from "app/pages/main.cataract-consent/cataract-consent";
import { IGlOption } from "../app/core/services/appendix";
import { Appointment } from "./appointment.model";
import { Clinic } from "./clinic.model";
import { IGlSide, IGlSideBilateral } from "./gl-side.model";
import { PatientDocument } from "./patient-document.model";
import { IGlUserType, Patient, User } from "./user.model";

export interface GlBilateral<T> {
  left?: T;
  right?: T;
}

// GET INSPIRATION FROM RECORD SIGN TO IMPLEMENT INDEPENDENT STATE
export type IGlVisitDropDilationState =
  | "NONE"
  | "ONGOING"
  /*
    finished is an implicit state when 
    - timer ended
    - not marked as complete
  */
  | "FINISHED"
  | "COMPLETE";

// visit drops
export interface GlVisitDropDilation {
  hours: number; // number
  minutes: number; // number
  state: IGlVisitDropDilationState;
  end_time: string; // ISO8601

  // iop observation, some drops dont have it
  iop_after?: GlBilateral<number>;

  // signature
}

export interface GlVisitDrop {
  type: IGlOption;
  // ISO8601 timestamp
  time: Date | string;
  eye: IGlSide;

  // dilation is optional
  dilation?: GlVisitDropDilation;
}

// weird edge case where all observations are just .type.
export interface GlLensObservation {
  type?: IGlOption;
}

export interface GlLens {
  status: GlBilateral<IGlOption>;
  status_other?: GlBilateral<string>;
  observations: GlBilateral<GlLensObservation[]>;
}

export const GL_LENS_ATTRIBUTES: string[] = [
  "status",
  "status_other",
  "observations",
];

export type PatientProviderType = "managing_optom" | "optometrist" | "gp";
export interface GlProvider {
  firstName?: string;
  lastName?: string;
  clinicName?: string;
  fax?: string;
  address?: string;
}

export type GlPatientManagementWho =
  | "optometrist"
  | "in house optometrist"
  | "ophthalmologist"
  | "discharged";

export interface GlDiagnosisOptionExtra extends GlDiagnosisOption {
  disableMultiSelect?: boolean;
}

export interface GlDiagnosisOption {
  name: string;
  key: string;
  diagnosis?: string;
  showOther?: boolean;
  modifiers?: GlDiagnosisOption[];
}

export interface GlDiagnosis {
  id?: string; // identifier
  level1: GlDiagnosisOption;
  level2?: GlDiagnosisOption;
  level2_other?: string;
  level3?: GlDiagnosisOption;
  level4?: GlDiagnosisOption;
}

// CATARACTS RELATED
export interface GlCataractForm {
  cataract_pre_op?: GlBilateral<IGlOption[]>; // multiple
  cataract_aim?: GlBilateral<IGlOption>;
  cataract_lens?: GlBilateral<IGlOption>;
  // cataract_first_addition?: GlBilateral<IGlOption>;
  // cataract_second_addition?: GlBilateral<IGlOption>;

  cataract_migs?: GlBilateral<IGlOption>;
  cataract_injectables?: GlBilateral<IGlOption[]>; // multiple
  cataract_steroid?: GlBilateral<IGlOption[]>;
  cataract_anaesthetic?: GlBilateral<IGlOption>;
  cataract_operation?: GlBilateral<IGlOption[]>; // multiple
  cataract_dry_eye?: IGlOption;
  cataract_post_op?: GlBilateral<IGlOption>;
}

// glaucoma related
export interface GlGlaucomaForm {
  // GLAUCOMA RELATED
  glaucoma_risk?: GlBilateral<IGlOption>;
  target_iop?: GlBilateral<number>;
  diagnosis_progression?: GlBilateral<IGlOption>;
}

export interface GlRecordDataManagement extends GlCataractForm, GlGlaucomaForm {
  /**
   * @deprecated a signle diagnosis has been replaced by diagnosis_array
   */
  diagnosis?: GlBilateral<GlDiagnosis>;
  diagnosis_array?: GlBilateral<GlDiagnosis[]>;

  // FOLLOWUPS
  what?: {
    string: boolean;
  };
  who?: GlPatientManagementWho;
  months_until?: IGlOption;
  comments?: string;
  admin_comment?: string;
  reason_for_virtual_review?: string;
}
/**
 * providers is a child object so that when it is merged with the record
 * history, only the latest set of providers comes through
 */
export interface GlPatientProviders {
  referrer: PatientProviderType;
  gp: GlProvider;
  optometrist: GlProvider;
  referrerDetails: GlProvider;
}

/**
 * This is the latest format for patient data.
 */
export type PatientRecordData = Partial<{
  [field: string]: any;
  medical_history: any;
  has_family_history: boolean;
  has_family_history_eye_disease: boolean;
  ocular_history: any;
  presenting_complaint: IGlOption;
  reason_for_referral: string;
  reason_for_referral_array?: GlBilateral<IGlOption[]>;
  referral_urgency?: IGlOption;
  medications_list: string;
  family_history: string;
  family_history_eye_disease: string;
  providers: GlPatientProviders;
  management: GlRecordDataManagement;
  /**
   * these 2 fields are used onl for virtual reviews. They are set by an
   * ophthalmologist completing a virtual review of an optometrists review
   */
  accept_review?: "agree" | "disagree";
  ac: GlBilateral<IGlOption>;
  ac_other: GlBilateral<string>;
  acQuantifier: GlBilateral<IGlOption>;
  blepharitis: GlBilateral<IGlOption>;
  conj: GlBilateral<IGlOption>;
  conj_other: GlBilateral<string>;
  cornea: GlBilateral<IGlOption>;
  cornea_other: GlBilateral<string>;
  vit: GlBilateral<IGlOption>;
  disc: GlBilateral<IGlOption>;
  disc_other: GlBilateral<string>;
  /**
   * @deprecated
   * oct is an old field. Old records may include it, newer records record one
   * or more of oct_mac, oct_gcc or oct_rnfl
   */
  oct: GlBilateral<IGlOption>;
  /**
   * @deprecated
   * oct is an old field. Old records may include it, newer records record one
   * or more of oct_mac, oct_gcc or oct_rnfl
   */
  oct_other: GlBilateral<string>;
  oct_gcc: GlBilateral<IGlOption>;
  oct_gcc_other: GlBilateral<string>;

  /* oct_mac === mac_oct */
  // legacy
  oct_mac: GlBilateral<IGlOption>;
  oct_mac_other: GlBilateral<string>;

  // current
  // each side could have an accompanying diagnosis
  oct_mac_v2: GlBilateral<IGlOption[]>;

  oct_rnfl: GlBilateral<IGlOption>;
  oct_rnfl_other: GlBilateral<string>;
  cdr: GlBilateral<number>;
  lens_status: GlBilateral<IGlOption>;
  lens: GlLens;

  // legacy macular
  macular: GlBilateral<IGlOption>;
  macular_other: GlBilateral<string>;

  // v2
  macular_v2: GlBilateral<IGlOption[]>;

  periphery: GlBilateral<IGlOption>;
  periphery_other: GlBilateral<string>;
  posterior_laterality: GlBilateral<boolean>;
  version: 2;
  va_numerator: GlBilateral<IGlOption>;
  va_denominator: GlBilateral<IGlOption>;
  aided_va_numerator: GlBilateral<IGlOption>;
  aided_va_denominator: GlBilateral<IGlOption>;
  subjective_va_numerator: GlBilateral<IGlOption>;
  subjective_va_denominator: GlBilateral<IGlOption>;
  // va_method: "aided | unaided";
  ph_numerator: GlBilateral<IGlOption>;
  ph_denominator: GlBilateral<IGlOption>;
  max_iop: GlBilateral<number>;
  iop: GlBilateral<number>;
  cct: GlBilateral<number>;
  dominance?: IGlSideBilateral; // its either R or L but optional
  refraction?: {
    status: boolean;
    sphere: GlBilateral<number>;
    cylinder: GlBilateral<number>;
    axis: GlBilateral<number>;
  };
  repeat: {
    amount: number;
    units: IGlOption;
  };

  gonio_date: string;
  gonioSuperior: GlBilateral<IGlOption>;
  gonioTemporal: GlBilateral<IGlOption>;
  gonioNasal: GlBilateral<IGlOption>;
  gonioInferior: GlBilateral<IGlOption>;
  gonioFinding: GlBilateral<IGlOption>;
  gonioFindingFree: GlBilateral<string>;
  gonioComment: GlBilateral<IGlOption>;
  gonioCommentFree: GlBilateral<string>;
  md: GlBilateral<number>;
  vfi: GlBilateral<number>;
  vfi_reliable: GlBilateral<IGlOption>;
  vfi_status: GlBilateral<IGlOption>;
  vfi_status_other: GlBilateral<string>;
  psd: GlBilateral<number>;
  visit_drops: GlVisitDrop[];

  /**
   * This is for dry eye fields
   */
  osdi: number;
  schirmer: GlBilateral<string>;
  tear_meniscus_height: GlBilateral<number>;
  nitbut: GlBilateral<number>;
  tbut: GlBilateral<number>;
  lids: GlBilateral<string>;
  lissamine: GlBilateral<number>;
  lissamine_other: GlBilateral<string>;
  meibography: GlBilateral<IGlOption>;
  meibography_other: GlBilateral<string>;
  meibum: string;

  /**
   * This is used to keep the details of the responsible practitioner for this
   * visit. Ie; the optometrist or ophthalmologist you completed the review.
   */
  practitioner: GlPatientRecordPractitioner;

}>;

export interface GlPatientRecordPractitioner {
  id: number;
  name: string;
  clinic: { id: number; name: string; };
  clinic_id: number;
  type_id: number;
  type: IGlUserType;
}

export type GlPatientRecordState = "IN PROGRESS" | "COMPLETE";

export type GlPatientRecordWorkflowState =
  | "admin"
  | "technician"
  | "optometrist"
  | "ophthalmologist"
  | "virtual review"
  | "tech_record"
  | "Arrived (R)"
  | "Arrived (G)"
  | "Arrived (C)"
  | "Arrived (O)"
  | "Arrived (INJ)"
  | "Admin"
  | "injection"
  | "dilation review"
  | "Arrived (DE)";

export type GlPatientRecordType =
  | "complete_amendment"
  | "incomplete_record"
  | "management_record"
  | "partial_record"
  | "patient_record"
  | "procedure"
  | "tech_record"
  | "virtual_review"
  | "referral"
  | "history"
  | "admin_update"
  | "dilation_review"
  | "consent_form_cataract";

/**
 * In a record, the data can either be "in_progress" and editable or "signed"
 * and not editable. Only an Ophthal or Optom can lock or unlock a record.
 */
export type GlPatientRecordDataStatus = "SIGNED" | "IN_PROGRESS";

export interface PatientRecord {
  id: number;
  /**
   * @type ISO8601 Date String
   */
  created_at: string;
  /**
   * @type ISO8601 Date String
   */
  updated_at: string;
  data: PatientRecordData;
  status: GlPatientRecordState; //complete
  type: GlPatientRecordType;
  user_id: number;
  clinic_id: number;
  clinic?: Clinic;
  created_by: User;
  provider_id: number;
  created_by_id: number;
  workflow_state: GlPatientRecordWorkflowState;
  documents?: PatientDocument[];
  /**
   * The user that last updated the data field
   */
  data_updated_by?: User;
  /**
   * The user id who last changed the data field
   */
  data_updated_by_id: number;
  /**
   * @type ISO8601 Date String
   * @description The timestamp when the data field was created. ie: when this record began to be edited
   */
  data_created_at: string;
  /**
   * @type ISO8601 Date String
   * @description The timestamp when this data field was last updated.
   */
  data_updated_at: string;
  /**
   * @type ISO8601 Date String
   * @description The status of the data field. The record can only be signed by an
   * Ophthalmologist or Optometrist. If signed, it can't be edited unless it is
   * opened by an Ophthal or Optom.
   */
  data_status: GlPatientRecordDataStatus;
  /**
   * @type ISO8601 Date String
   * @description The timestamp when the data field was signed.
   */
  data_signed_at: string;
  /**
   *
   */
  data_signed_by_id: number;
  data_signed_by?: User;

  /*
    records can now be signed by proxy
    this records who the user that proxied was

    e.g. tech...
  */
  data_proxy_signed_by_id: number;
  data_proxy_signed_by: User;

  optom_record_id: number;
  virtual_review?: PatientRecord;
  /**
   * @type ISO8601 Date String
   * @description The timestamp when this appointment was scheduled.
   */
  appointment_date: string;
  appointment_id: number;
  appointment?: Appointment;

  // dilation review
  dilation_record_id: number;
  // dilation records are fully dependent on the parent record and
  // only exists to allow signing indepndent to record state
  dilation_review?: PatientRecord;

  // cataract form
  cataract_consent_form?: PatientCataractConsentRecord;
}

export interface PatientReferralStatus {
  status: IGlOption;
  status_other?: string;
  appointment_date?: Date | string;
}

export interface PatientReferral extends PatientRecord {
  type: "referral";
  referral_clinic_id: number;
  referral_provider_id: number;
  referral_status: PatientReferralStatus;
}

// generic procedure form to extend off
export interface PatientProcedureFormSignatureData extends PatientRecordData {
  status: GlPatientRecordDataStatus; // signed or not

  // optional as there are cases where the record has to be dormant
  signed_at?: string; // ISO8601
  signed_by_id?: number; // patient who signed it
  signed_by?: Patient; // patient?

  // signature
  signature?: string; // has to be optional
}

export interface PatientConsentFormRecord extends PatientRecord {
  // patients signature is required on a cataract form
  data: PatientRecordData & {
    // signatureData
    signature_data: {
      patient: PatientProcedureFormSignatureData;
      /*
         NOTE: for the user here we add extra metadata instead 
         because its safer for it to be encrypted rather than extending
         PatientRecord
      */
      user: PatientProcedureFormSignatureData;
    };
    // store status of checks
    procedure_risk_acknowledgements: IConsentFormRisk[];

    // which procedure we are basing it off
    linked_procedure_id: number;
  };

  // to avoid infinite loops by association, just get the parent record id
  parent_record_id?: number;
}

// for cataract form
export interface PatientCataractConsentRecord extends PatientConsentFormRecord {
  type: "consent_form_cataract";
}
