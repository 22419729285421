import angular = require("angular");
import { GlToastrCategory, ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { isFunction, isNil } from "lodash";
import { Clinic } from "models/clinic.model";
import { PatientRecord } from "models/patient-record.model";
import { Patient } from "models/user.model";
import { IOrthancDocumentMatchResults, OrthancDocumentService } from "../../../../../app/pages/main.document-upload/services/orthanc-document/orthanc-document.service";

export class DocumentImportController
  implements angular.IComponentController {
  // can upload
  documentImportIsEnabled: boolean = false;

  // import?
  fetchInProgress: boolean = false;

  // the essentials pretty much
  clinic: Clinic;
  record: PatientRecord;
  patient: Patient;

  attemptedOnLoad: boolean = false;
  // timestamp of last fetch
  // then move this over
  lastFetchedTimestamp: Date;

  // assumes the user can connect to the edge server
  // toggles if they cant
  edgeServerCanConnect: boolean = true;

  orthancDocuments: IOrthancDocumentMatchResults = {};

  orthancDocumentMessages: GlToastrCategory = this.ToastrAppendix.getOrthancDocumentMessages();

  // on ready
  onOrthancDocumentsReady: (arg: { documents: IOrthancDocumentMatchResults; }) => void;

  constructor(
    private toastr: angular.toastr.IToastrService,
    private ToastrAppendix: ToastrAppendix,
    private OrthancDocumentService: OrthancDocumentService,
  ) {
    "ngInject";
  }


  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.clinic && this.clinic?.data) {
      this.documentImportIsEnabled = this.OrthancDocumentService.checkIfDocumentImportsAreEnabled(this.clinic.data);
    }

    // if there are any changes and we can import
    // if we havent treid to load it once, load it
    if ((changes?.record || changes?.patient || changes?.clinic) &&
      this.record && this.patient && this.clinic?.data && this.documentImportIsEnabled &&
      !this.attemptedOnLoad
    ) {
      this.fetchDocuments();
    }
  }

  // fetch document
  fetchDocuments() {
    // no record or patient ignore
    if (!this.record || !this.patient) {
      return;
    }

    this.fetchInProgress = true;
    // imports based on the record itself
    return this.OrthancDocumentService.importOrthancDocsForPatient(
      this.patient?.data?.file_no,
      this.record?.appointment_date,
    )
      .then((results: IOrthancDocumentMatchResults) => {
        // send over to callback
        this.handleOnOrthancDocumentsReady(results);

        // set timestamps
        const timestamp: Date = new Date();
        this.lastFetchedTimestamp = timestamp;
        this.edgeServerCanConnect = true;

        this.toastr.success(this.orthancDocumentMessages.success.import);
      })
      .catch((error: any) => {
        // in the event we cant reach the server
        // aka no status code: toggle a flag
        if (isNil(error?.status) || error?.status === -1) {
          this.edgeServerCanConnect = false;
          this.toastr.error(this.orthancDocumentMessages.error.edge_server.cant_connect);
        } else {
          this.edgeServerCanConnect = true;
          console.error("Error importing documents for ", { patient: this.patient, record: this.record }, error);
        }

        // on load attempted
        if (!this.attemptedOnLoad) {
          this.attemptedOnLoad;
        }
      })
      .finally(() => {
        this.fetchInProgress = false;
      });
  }

  // on documents ready
  handleOnOrthancDocumentsReady(documents: IOrthancDocumentMatchResults) {
    // assign
    this.orthancDocuments = documents;

    if (isFunction(this.onOrthancDocumentsReady)) {
      this.onOrthancDocumentsReady({ documents });
    }
  }
}


export class DocumentImport implements angular.IComponentOptions {
  static selector: string = "documentImport";
  static controller = DocumentImportController;
  static template = require('./document-import.html');
  static bindings = {
    record: "<",
    patient: "<",
    clinic: "<",

    // documents and errors
    documents: "<",
    errors: "<",

    onOrthancDocumentsReady: "&"
  };
}

