import { Appointment } from "../../../../models/appointment.model";
import { IGlApiResponse } from "../../../../models/gl-api-response.model";
import { API_PATH_v2 } from "../api-paths";
import { APPOINTMENT_REGEX } from "../appendix";

export class AppointmentService {
  static injectionName = "AppointmentService";

  constructor(private $http: angular.IHttpService, private API_URL: string) {
    "ngInject";
  }

  get(args?: { providerId?: number; patientId?: number; date?: string; }) {
    return this.$http
      .get<IGlApiResponse<Appointment[]>>(
        `${this.API_URL}/${API_PATH_v2}/appointments`,
        { params: args }
      )
      .then((response) => response.data.data);
  }

  delete(appointmentId: number) {
    return this.$http.delete(
      `${this.API_URL}/${API_PATH_v2}/appointments/${appointmentId}`
    );
  }

  // given a string, tries to find out if its x type appointment
  isGlaucomaAppointmentByRegex(text: string) {
    const glaucomaRegex: RegExp = APPOINTMENT_REGEX.Glaucoma.regex;
    return glaucomaRegex.test(text);
  }

  isCataractAppointmentByRegex(text: string) {
    const cataractRegex: RegExp = APPOINTMENT_REGEX.Cataract.regex;
    return cataractRegex.test(text);
  }

  isRetinaAppointmentByRegex(text: string) {
    const retinaRegex: RegExp = APPOINTMENT_REGEX.Retina.regex;
    return retinaRegex.test(text);
  }

  isDryEyeAppointmentByRegex(text: string) {
    const dryEyeRegex: RegExp = APPOINTMENT_REGEX["Dry Eye"].regex;
    return dryEyeRegex.test(text);
  }

}
