import { IPromise } from "angular";
import { GlClinicActiveRecord } from "models/clinic-active-records.model";
import { ClinicData } from "models/clinic.model";
import { PatientDocument } from "models/patient-document.model";
import {
  GlPatientRecordType,
  PatientRecord,
} from "models/patient-record.model";
import { IGlPatientData, User } from "models/user.model";
import * as moment from "moment";
import {
  DICOM_MODALITY_DISC,
  DICOM_MODALITY_OPHTHALMIC_PHOTO,
  DICOM_TAG_DOCUMENT_TITLE,
  DICOM_TAG_ID_IMAGE_LATERALITY,
  DICOM_TAG_ID_LATERALITY,
  DICOM_TAG_ID_MODAILTY as DICOM_TAG_ID_MODALITY,
  DICOM_TAG_ID_SOP_INSTANCE_UID,
  DICOM_TAG_MANUFACTURER_MODEL_NAME,
  DicomTagHelper,
} from "../../../../core/services/dicom-tag-helper/dicom-tag-helper";
import {
  DocumentsService,
  GL_DOCUMENT_A_SCAN,
  GL_DOCUMENT_DISC_LEFT,
  GL_DOCUMENT_DISC_RIGHT,
  GL_DOCUMENT_FIELD_LEFT,
  GL_DOCUMENT_FIELD_RIGHT,
  GL_DOCUMENT_OCT_GCC,
  GL_DOCUMENT_OCT_MAC,
  GL_DOCUMENT_OCT_RNFL,
  GlDocumentType,
  IRecordDocuments,
} from "../../../../core/services/documents-service/documents.service";
import {
  IOrthancApiServiceConfig,
  OrthancApiService,
} from "../../../../core/services/orthanc-api/orthanc-api.service";
import {
  IOrthancDicomTags,
  IOrthancPatient,
} from "../../../../core/services/orthanc-api/orthanc.models";

export interface IDocumentImportUpload {
  progress: number;
}

export interface IDocumentImportError {
  patient: User;
  errorDescription: string;
}

export interface IOrthancInstanceWithTags {
  id: string;
  tags: IOrthancDicomTags;
}

export type IOrthancDocumentMatchResults = Partial<
  Record<GlDocumentType, IOrthancInstanceWithTags[]>
>;

export interface IDocumentImportMatchUpload {
  documentType: GlDocumentType;
  record: PatientRecord;
  orthancDocumentId: string;
  orthancDocumentTags: IOrthancDicomTags;
  existingPatientDocumentToDelete?: PatientDocument;
}

export interface IDocumentImportMatchError {
  documentType: GlDocumentType;
  record: PatientRecord;
  orthancMatches: IOrthancInstanceWithTags[];
}

export interface IDocumentUploadPatientMatch {
  uploads: IDocumentImportMatchUpload[];
  errors: IDocumentImportMatchError[];
}

// different to OrthancDocumentMatchResults
// as this has the dicom tags categorised by ID already
export type IDocumentWithOrthancDicomTags =
  Partial<Record<GlDocumentType, IOrthancDicomTags>>;

/**
 * for the importing/fetching of DICOM scans
 * used in patient calculations
 */
export class OrthancDocumentService {
  static injectionName = "OrthancDocumentService";

  constructor(
    private $q: angular.IQService,
    private OrthancApiService: OrthancApiService,
    private DocumentsService: DocumentsService,
    private DicomTagHelper: DicomTagHelper
  ) {
    "ngInject";
  }

  setConfig(config: Partial<IOrthancApiServiceConfig>) {
    this.OrthancApiService.setConfig(config);
  }

  /**
   * @description this function removes the patients from the Orthanc server
   * that are included in the active records array. This ensures that when
   * documents are imported from Forum, only the most up to dat documents are
   * imported. Any any documents previously imported from Forum that have been
   * subsequently removed are also removed from Orthanc as well
   * @param activeRecords
   */
  deletePatientsFromOrthanc(activeRecords?: GlClinicActiveRecord[]) {
    return this.OrthancApiService.patientsList(true).then((patients) => {
      const orthancPatientsToDelete = activeRecords
        ? this.patientsToDeleteFromOrthanc(activeRecords, patients)
        : patients;
      return this.$q.all(
        orthancPatientsToDelete.map((p) =>
          this.OrthancApiService.patientDelete(p.ID)
        )
      );
    });
  }

  patientsToCheckForDocuments(activeRecords: GlClinicActiveRecord[]) {
    const validRecordTypes: GlPatientRecordType[] = [
      "patient_record",
      "tech_record",
    ];
    return activeRecords.filter(
      (ar) =>
        ar.record.data_status === "IN_PROGRESS" &&
        validRecordTypes.includes(ar.record.type)
    );
  }

  /* 
    GENERAL IMPORT DOCUMENT FUNCTIONS 

    some are ported over from document-uploads.ts
  */
  /**
   * given a patient record and patient 
   * fetch documents that are associated with that patient's record
   * @param record 
   * @param patient 
   * @returns { uploads, errors } IDocumentUploadPatientMatch 
   */
  importOrthancDocsForPatient(patientFileNo: string, appointmentDate?: string):
    IPromise<IOrthancDocumentMatchResults | void> {
    // use appointment date or just the current date
    // this is to also preserve the current historical state as well 
    const studyDate: string =
      moment(appointmentDate || moment.now())
        .format('YYYYMMDD');
    // 1. import documents from forum based on the patient's file number
    return this.importPatientDocumentsFromForum(
      patientFileNo,
      studyDate
    );
  }


  /**
   * imports patient documents from forum given patient id
   * @param patientId patient id
   * @param studyDate optional but can be included to get a specific date
   * @returns 
   */
  importPatientDocumentsFromForum(patientId: string, studyDate?: string) {
    // to void issues importing patient from Forum, make sure the patient number
    // is 5 digits, 0 padded string.
    const paddedPatientId = patientId.padStart(5, "0");

    // 1. fetch a list of instance id scans linked to the patients file number
    // and with an optional study date
    return this.OrthancApiService.patientGetInstanceIds(paddedPatientId, studyDate)
      .then((instanceIds) =>
        this.$q.all(
          // for all the found instance ids, map out by { id, tags }
          instanceIds.map((id) =>
            this.OrthancApiService.instanceGetTags(id).then((tags) => ({
              id,
              tags,
            }))
          )
        )
      )
      // 3. for all the sorted ones, 
      // map them out into GL documents
      .then((instances) => {
        return this.sortOrthancDocsIntoKnownGlDocuments(instances);
      });
  }


  /**
   * sorts documents and filters them based on
   * the scans we want
   * 
   * @param record record of the scans
   * @param orthancDocuments documents of said record
   * @returns 
   */
  sortOrthancDocumentsForRecord(
    record: PatientRecord,
    orthancDocuments: IOrthancDocumentMatchResults
  ): IDocumentUploadPatientMatch {

    // fetch a list of legacy/knwon documents associated with the record
    const knownDocumentsForRecord = this.DocumentsService.getNamedDocuments(
      record.documents
    );

    // setup a list of documents and some error ones
    const result: IDocumentUploadPatientMatch = {
      uploads: [],
      errors: [],
    };

    // fetch a list of documents we usually handle
    this.getAutomaticallyManagedDocumentList(record?.documents ?? []).forEach((docType) => {
      // fetch the potential sorted document types found
      // from the api (array)
      const possibleOrthancDocs = orthancDocuments[docType];

      // its an orthanc doc if we have at least a reference
      const orthancDoc =
        possibleOrthancDocs &&
        possibleOrthancDocs.length === 1 &&
        possibleOrthancDocs[0];

      // fetch the orthanc doc id from it 
      const orthancDocId =
        orthancDoc &&
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_SOP_INSTANCE_UID,
          orthancDoc.tags
        );

      // CHECK THE KNOWN DOCUMENTS
      // try and find a known updated document
      // and its dicom tag
      const existingDoc = knownDocumentsForRecord[docType] as PatientDocument;
      const existingDicomId =
        existingDoc?.dicom_data &&
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_SOP_INSTANCE_UID,
          existingDoc.dicom_data
        );


      // if we have a possible list of orthanc documents
      // and it is not a duplciate
      if (
        orthancDoc &&
        possibleOrthancDocs.length === 1 &&
        orthancDocId !== existingDicomId
      ) {
        // update the known results 
        result.uploads.push({
          record,
          documentType: docType,
          orthancDocumentId: orthancDoc.id,
          orthancDocumentTags: orthancDoc.tags,
          // If there is an existing automatically uploaded Dicom Image and a
          // new one to replace it, include details on the existing document
          // that needs to be delete prior to uploading the new one
          ...(existingDicomId && {
            existingPatientDocumentToDelete: existingDoc,
          }),
        });
      } else if (possibleOrthancDocs && possibleOrthancDocs.length > 1) {
        // otherwise if we find an identical one,
        // this is an error. Add it to the errors array
        result.errors.push({
          record,
          documentType: docType,
          orthancMatches: possibleOrthancDocs,
        });
      }
    });

    // return the result
    return result;
  }

  // CAN UPLOAD/IMPORT DOCUMENTS?
  checkIfDocumentImportsAreEnabled(clinicConfig: ClinicData) {
    const {
      dicom_server_aet: orthancAet,
      dicom_server_url: orthancUrl,
      forum_server_aet: forumAet,
    } = clinicConfig;
    const documentImportIsEnabled: boolean = !!orthancUrl || !!orthancAet || !!forumAet;
    // if enabled, set the config
    if (documentImportIsEnabled) {
      this.setConfig({
        ...(orthancUrl && { orthancUrl }),
        ...(orthancAet && { orthancAet }),
        ...(forumAet && { forumAet }),
      });
    }
    // return state
    return documentImportIsEnabled;
  }

  // sort documents
  sortOrthancDocsIntoKnownGlDocuments(
    // id, tags
    orthancDocs: IOrthancInstanceWithTags[]
  ) {
    // list of documents with matches (final reutrn result)
    // one type can have multiple scans associated with it
    const documentObj: IOrthancDocumentMatchResults = {};

    // DOCUMENT SORT
    // for each of the provided orthanc documents
    orthancDocs.forEach((doc) => {
      // modality (type of scan)
      const modality = this.DicomTagHelper.getValueForDicomId(
        DICOM_TAG_ID_MODALITY,
        doc.tags
      );

      // laterality/side scan that we pull
      const side =
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_LATERALITY,
          doc.tags
        ) ||
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_IMAGE_LATERALITY,
          doc.tags
        );

      // document name
      const documentTitle = this.DicomTagHelper.getValueForDicomId(
        DICOM_TAG_DOCUMENT_TITLE,
        doc.tags
      );

      // try to determine what document type this
      // DICOM scan would correspond to
      const glDocumentType: GlDocumentType =
        this.glDocumentType(modality, side, documentTitle, doc?.tags) ||
        this.matchFundusImage(doc?.tags);

      // if we find a scan that matches it
      if (glDocumentType) {
        // if existing, fetch a list of documents associated with that type
        const docs = documentObj[glDocumentType] || [];
        // add the orthanc document
        docs.push(doc);

        // assign (or reassign if existing ones are found)
        documentObj[glDocumentType] = docs;
      }
      // otherwise the file is ignored
    });

    return documentObj;
  }

  private patientsToDeleteFromOrthanc(
    glPatientsToCheck: GlClinicActiveRecord[],
    orthancPatientList: IOrthancPatient[]
  ) {
    return orthancPatientList.filter((p) =>
      glPatientsToCheck.some(
        // Both Glauconet & Zeiss Forum use the Mediwiz patient id stored as a 0
        // padded string as the file number. To avoid problems with the zero
        // padding, convert these strings to integers and compare the patients
        // based on the integer patient number
        (ar) =>
          +(ar.patient.data as IGlPatientData).file_no ===
          +p.MainDicomTags.PatientID
      )
    );
  }

  /**
   * Work out which Forum documents to import. We only import Forum documents
   * that haven't already been uploaded into Glauconet previously
   * @param activeRecords
   * @param forumQueryResponse
   */
  private getDicomDocumentIdsAlreadyImported(record: PatientRecord) {
    // get all the dicom document IDs from the documents list
    // only include docuents with dicom data
    const documents = record?.documents || [];
    return documents
      .filter((d) => d.dicom_data)
      .map((d) => {
        return this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_SOP_INSTANCE_UID,
          d.dicom_data
        );
      });
  }

  /**
   * given a record, if there are any mapped documents attached to it
   * (i.e. mapped through _mapLegacyRecord is PatientRecordService)
   * add it to the list
   * @param recordDocuments 
   * @returns 
   */
  private getAutomaticallyManagedDocumentList(recordDocuments: PatientDocument[]): GlDocumentType[] {
    // these documents are the ones that we care about
    const allDocuments: GlDocumentType[] = [
      GL_DOCUMENT_A_SCAN,
      GL_DOCUMENT_DISC_LEFT,
      GL_DOCUMENT_DISC_RIGHT,
      GL_DOCUMENT_FIELD_LEFT,
      GL_DOCUMENT_FIELD_RIGHT,
      GL_DOCUMENT_OCT_GCC,
      GL_DOCUMENT_OCT_MAC,
      GL_DOCUMENT_OCT_RNFL,
    ];

    // map out the currently known list of documents
    // attached to the record
    const knownUploadedDocuments: IRecordDocuments = this.DocumentsService.getNamedDocuments(
      recordDocuments
    );

    // automatically managed documents are those document types which either
    // haven't been uploaded yet or do not have a manually uploaded document
    // (which doesn't have dicom tags)
    return allDocuments.filter((docType) => {
      const knownDocument = knownUploadedDocuments[docType] as PatientDocument;
      // if there is no document that matches that type 
      // and that document doenst have dicom data 
      // return that document type which we might need to add
      return !(knownDocument && !knownDocument.dicom_data);
    });
  }



  // returns what type of document
  // an orthanc file would be in GNET terms
  private glDocumentType(
    modality: string,
    side: string,
    documentTitle: string,
    document: IOrthancDicomTags
  ): GlDocumentType {
    let isDisc = false;
    if (
      [DICOM_MODALITY_DISC, DICOM_MODALITY_OPHTHALMIC_PHOTO].includes(modality)
    ) {
      const tags = JSON.stringify(document);
      // work out if it is a DISC or "central" photo
      isDisc = tags.includes("OPTIC_DISK") || tags.includes("OpticDisc");
    }
    if (isDisc) {
      return side === "L" ? GL_DOCUMENT_DISC_LEFT : GL_DOCUMENT_DISC_RIGHT;
    } else if (documentTitle?.includes("SFA")) {
      return side === "L" ? GL_DOCUMENT_FIELD_LEFT : GL_DOCUMENT_FIELD_RIGHT;
    } else if (documentTitle === "IOLMaster 700 Report") {
      return GL_DOCUMENT_A_SCAN;
    } else if (documentTitle === "Cirrus_OU_ONH and RNFL OU Analysis") {
      return GL_DOCUMENT_OCT_RNFL;
    } else if (documentTitle === "OU Macular Thickness OU Analysis") {
      return GL_DOCUMENT_OCT_MAC;
    } else if (documentTitle === "OU Ganglion Cell OU Analysis") {
      return GL_DOCUMENT_OCT_GCC;
    }
  }

  // for fundus images, it might need a different way to differentiate
  // this sorts it out
  private matchFundusImage(document: IOrthancDicomTags) {
    const machine = this.DicomTagHelper.getValueForDicomId(
      DICOM_TAG_MANUFACTURER_MODEL_NAME,
      document
    ) as string;
    const modality = this.DicomTagHelper.getValueForDicomId(
      DICOM_TAG_ID_MODALITY,
      document
    );
    const side = this.DicomTagHelper.getValueForDicomId(
      DICOM_TAG_ID_LATERALITY,
      document
    );

    const tags = JSON.stringify(document);
    // work out if it is a DISC or "central" photo
    const isDisc = tags.includes("OPTIC_DISK") || tags.includes("OpticDisc");

    if (
      machine?.toLowerCase() === "CIRRUS photo 800".toLowerCase() &&
      modality === DICOM_MODALITY_OPHTHALMIC_PHOTO &&
      isDisc
    ) {
      // work out if it is a DISC or "central" photo
      return side === "L" ? GL_DOCUMENT_DISC_LEFT : GL_DOCUMENT_DISC_RIGHT;
    }
  }
}
