import { GlToastrCategory } from "../toastr-appendix";

export const ORTHANC_DOCUMENT_TOASTR_MESSAGES: GlToastrCategory = {
  success: {
    import: "Imported DICOM documents for patient record!"
  },
  info: {

  },
  error: {
    edge_server: {
      cant_connect: "Can't connect to the edge server, please try again on site."
    }
  }
};