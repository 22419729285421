import { GL_VIEWS } from "app/pages/main.record/components/view-manager/view-manager.component";
import { find, get, isEmpty, isNil, replace, set, some } from "lodash";
import { IGlSide } from "models/gl-side.model";
import {
  GlBilateral,
  GlDiagnosis,
  GlDiagnosisOption,
  GlDiagnosisOptionExtra,
} from "models/patient-record.model";

const OTHER_COMMENT_PLACEHOLDER = "[OTHER]";

export type GlManagementType = "glaucoma" | "cataract";

export class DiagnosisService {
  static injectionName = "DiagnosisService";
  public MANAGEMENT_DIAGNOSIS_ARRAY_KEY: string = "management.diagnosis_array";
  public LEVEL1_OPTIONS: GlDiagnosisOption[] = [
    { name: "Amblyopia", key: "amblyopia", diagnosis: "Amblyopia" },
    { name: "Blepharitis", key: "blepharitis", diagnosis: "Blepharitis" },
    { name: "Blind", key: "blind", diagnosis: "Blind" },
    { name: "Cataract", key: "cataract", diagnosis: "Cataract" },
    {
      name: "Cystoid Macular Oedema",
      key: "cystoid_macular_oedema",
      diagnosis: "Cystoid Macular Oedema",
    },
    { name: "Dry Eye", key: "dry_eye", diagnosis: "Dry Eye" },
    {
      name: "Glaucoma - Primary Open Angle",
      key: "primary_open_angle",
    },
    {
      name: "Glaucoma - Primary Angle Closure",
      key: "primary_angle_closure",
    },
    {
      name: "Glaucoma - Secondary Open Angle",
      key: "secondary_open_angle",
    },
    {
      name: "Glaucoma - Secondary Angle Closure",
      key: "secondary_angle_closure",
    },
    { name: "Healthy", key: "healthy", diagnosis: "Healthy" },
    { name: "Hyperope", key: "hyperope", diagnosis: "Hyperope" },
    { name: "Myope", key: "myope", diagnosis: "Myope" },
    { name: "Pingueculum", key: "pingueculum", diagnosis: "Pingueculum" },
    { name: "Plastics", key: "plastics" },
    {
      name: "Posterior Capsular Opacification",
      key: "posterior_capsular_opacification",
      diagnosis: "Posterior Capsular Opacification",
    },
    {
      name: "Posterior Vitreous Detachment",
      key: "posterior_vitreous_detachment",
      diagnosis: "Posterior Vitreous Detachment",
    },
    { name: "Prosthetic", key: "prosthetic", diagnosis: "Prosthetic" },
    { name: "Pterygium", key: "pterygium", diagnosis: "Pterygium" },
    { name: "Retina", key: "retina", diagnosis: "Retina" },
    {
      name: "Other",
      key: "diagnosis_other",
      diagnosis: OTHER_COMMENT_PLACEHOLDER,
      showOther: true,
    },
  ];

  public LEVEL1_OPTIONS_EXTRA: GlDiagnosisOptionExtra[] = [
    {
      name: "Healthy",
      key: "healthy",
      diagnosis: "Healthy",
      disableMultiSelect: true,
    },
  ];

  public LEVEL2_OPTIONS: { [key: string]: GlDiagnosisOption[]; } = {
    primary_open_angle: [
      {
        name: "Ocular Hypertension",
        key: "ocular_hypertension",
        diagnosis: "Ocular Hypertension",
      },
      {
        name: "Suspect",
        key: "glaucoma_suspect",
        diagnosis: "Glaucoma Suspect",
      },
      {
        name: "Glaucoma",
        key: "ocular_ht_glaucoma",
      },
    ],
    primary_angle_closure: [
      {
        name: "Treated",
        key: "treated_angle_closure_suspect",
        diagnosis: "Treated Angle Closure Suspect",
      },
      {
        name: "Suspect",
        key: "angle_closure_suspect",
        diagnosis: "Angle Closure Suspect",
      },
      {
        name: "Closure",
        key: "primary_angle_closure",
        diagnosis: "Primary Angle Closure",
      },
      {
        name: "Glaucoma",
        key: "primary_angle_closure_glaucoma",
      },
      {
        name: "Plateau Iris Configuration",
        key: "plateau_iris_configuration",
        diagnosis: "Plateau Iris Configuration",
      },
      {
        name: "Plateau Iris Syndrome",
        key: "plateau_iris_syndrome",
        diagnosis: "Plateau Iris Syndrome",
      },
      {
        name: "Plateau Iris Glaucoma",
        key: "plateau_iris_glaucoma",
      },
    ],
    secondary_open_angle: [
      {
        name: "Pseudoexfoliation",
        key: "secondary_open_angle_pseudoexfoliation",
      },
      {
        name: "Pigment Dispersion",
        key: "secondary_open_angle_pigment_dispersion",
      },
      {
        name: "Other",
        key: "secondary_open_angle_other",
        showOther: true,
      },
    ],
    secondary_angle_closure: [
      {
        name: "Neovascular",
        key: "secondary_angle_closure_neovascular",
      },
      {
        name: "Other",
        key: "secondary_angle_closure_other",
        showOther: true,
      },
    ],
    retina: [
      {
        name: "Diabetic Retinopathy",
        key: "diabetic_retinopathy",
      },
      // TODO: clear this with Nancy then re-enable it
      // {
      //   name: "DMO",
      //   key: "diabetic_macula_oedema",
      //   diagnosis: "Diabetic Macular Oedema"
      // },
      {
        name: "Macular Degeneration",
        key: "macular_degeneration",
      },
      {
        name: "Vein Occlusion",
        key: "vein_occlusion",
      },
      {
        name: "Arterial Occlusion",
        key: "arterial_occlusion",
      },
      {
        name: "CMO",
        key: "cmo",
        diagnosis: "Cystoid Macular Oedema",
      },
      {
        name: "ERM",
        key: "erm",
        diagnosis: "Epiretinal Membrane",
      },
      {
        name: "CSC",
        key: "csc",
        diagnosis: "Central Serous Chorioretinopathy",
      },
      {
        name: "CPV",
        key: "cpv",
        diagnosis: "Choroidal Polypoidal Vasculopathy",
      },
      {
        name: "Hypertensive Retinopathy",
        key: "hypertensive_retinopathy",
        diagnosis: "Hypertensive Retinopathy",
      },
      {
        name: "Surgical Retina",
        key: "surgical_retina",
        showOther: true,
        diagnosis: OTHER_COMMENT_PLACEHOLDER,
      },
      {
        name: "Other",
        key: "retina_other",
        showOther: true,
        diagnosis: OTHER_COMMENT_PLACEHOLDER,
      },
    ],
    plastics: [
      {
        name: "Ectropion",
        key: "ectropiol", // the key is left spelled incorrectly for legacy data
        diagnosis: "Ectropion",
      },
      {
        name: "Entopion",
        key: "entopiol", // the key is left spelled incorrectly for legacy data
        diagnosis: "Entopion",
      },
      {
        name: "Ptosis",
        key: "ptosis",
        diagnosis: "Ptosis",
      },
      {
        name: "Dermatochalasis",
        key: "dermatochalasis",
        diagnosis: "Dermatochalasis",
      },
      {
        name: "Other",
        key: "plastics_other",
        showOther: true,
        diagnosis: OTHER_COMMENT_PLACEHOLDER,
      },
    ],
  };

  public LEVEL3_OPTIONS: { [key: string]: GlDiagnosisOption[]; } = {
    ocular_ht_glaucoma: [
      {
        name: "Mild",
        key: "mild_open_angle_glaucoma",
        diagnosis: "Mild Open Angle Glaucoma",
      },
      {
        name: "Moderate",
        key: "moderate_open_angle_glaucoma",
        diagnosis: "Moderate Open Angle Glaucoma",
      },
      {
        name: "Severe",
        key: "severe_open_angle_glaucoma",
        diagnosis: "Severe Open Angle Glaucoma",
      },
      {
        name: "End-Stage ",
        key: "end_stage_open_angle_glaucoma",
        diagnosis: "End-stage Open Angle Glaucoma",
      },
    ],
    primary_angle_closure_glaucoma: [
      {
        name: "Mild",
        key: "mild_angle_closure_glaucoma",
        diagnosis: "Mild Angle Closure Glaucoma",
      },
      {
        name: "Moderate",
        key: "moderate_angle_closure_glaucoma",
        diagnosis: "Moderate Angle Closure Glaucoma",
      },
      {
        name: "Severe",
        key: "severe_angle_closure_glaucoma",
        diagnosis: "Severe Angle Closure Glaucoma",
      },
      {
        name: "End-Stage",
        key: "end_stage_angle_closure_glaucoma",
        diagnosis: "End-Stage Angle Closure Glaucoma",
      },
    ],
    plateau_iris_glaucoma: [
      {
        name: "Mild",
        key: "mild_plateau_iris_glaucoma",
        diagnosis: "Mild Plateau Iris Glaucoma",
      },
      {
        name: "Moderate",
        key: "moderate_plateau_iris_glaucoma",
        diagnosis: "Moderate Plateau Iris Glaucoma",
      },
      {
        name: "Severe",
        key: "severe_plateau_iris_glaucoma",
        diagnosis: "Severe Plateau Iris Glaucoma",
      },
      {
        name: "End-Stage",
        key: "end_stage_plateau_iris_glaucoma",
        diagnosis: "End-Stage Plateau Iris Glaucoma",
      },
    ],
    secondary_open_angle_pseudoexfoliation: [
      {
        name: "Not Glaucoma",
        key: "pseudoexfoliation_not_glaucoma",
        diagnosis: "Pseudoexfoliation",
      },
      {
        name: "Ocular Hypertension",
        key: "pseudoexfoliation_syndrome",
        diagnosis: "Pseudoexfoliation Syndrome",
      },
      {
        name: "Suspect",
        key: "pseudoexfoliation_glaucoma_suspect",
        diagnosis: "Pseudoexfoliation Glaucoma Suspect",
      },
      {
        name: "Glaucoma",
        key: "secondary_open_angle_pseudoexfoliation_glaucoma",
      },
    ],
    secondary_open_angle_pigment_dispersion: [
      {
        name: "Not Glaucoma",
        key: "pigment_dispersion_not_glaucoma",
        diagnosis: "Pigment Dispersion",
      },
      {
        name: "Ocular Hypertension",
        key: "pigment_dispersion_syndrome",
        diagnosis: "Pigment Dispersion Syndrome",
      },
      {
        name: "Suspect",
        key: "pigment_dispersion_glaucoma_suspect",
        diagnosis: "Pigment Dispersion Glaucoma Suspect",
      },
      { name: "Glaucoma", key: "pigment_dispersion_glaucoma" },
    ],
    secondary_open_angle_other: [
      {
        name: "Ocular Hypertension",
        key: "secondary_open_angle_other_ocular_hypertension",
        diagnosis: "[OTHER] Ocular Hypertension",
      },
      {
        name: "Suspect",
        key: "secondary_open_angle_other_glaucoma_suspect",
        diagnosis: "[OTHER] Glaucoma Suspect",
      },
      { name: "Glaucoma", key: "secondary_open_angle_other_glaucoma" },
    ],
    secondary_angle_closure_neovascular: [
      {
        name: "Ocular Hypertension",
        key: "secondary_angle_closure_neovascular_ocular_hypertension",
        diagnosis: "Neovascular Ocular Hypertension",
      },
      {
        name: "Suspect",
        key: "secondary_angle_closure_neovascular_glaucoma_suspect",
        diagnosis: "Neovascular Glaucoma Suspect",
      },
      { name: "Glaucoma", key: "secondary_angle_closure_neovascular" },
    ],
    secondary_angle_closure_other: [
      {
        name: "Ocular Hypertension",
        key: "secondary_angle_closure_other_ocular_hypertension",
        diagnosis: "[OTHER] Ocular Hypertension",
      },
      {
        name: "Suspect",
        key: "secondary_angle_closure_glaucoma_suspect_other_glaucoma_suspect",
        diagnosis: "[OTHER] Glaucoma Suspect",
      },
      { name: "Glaucoma", key: "secondary_angle_closure_other_glaucoma" },
    ],
    diabetic_retinopathy: [
      {
        name: "No DR",
        key: "diabetic_retinopathy_no_dr",
        diagnosis: "No diabetic retinopathy ",
      },
      {
        name: "Min NPDR",
        key: "diabetic_retinopathy_min_npdr",
        diagnosis: "Mild Non-Proliferative Diabetic Retinopathy",
      },
      {
        name: "Mod NPDR",
        key: "diabetic_retinopathy_mod_npdr",
        diagnosis: "Moderate Non-Proliferative Diabetic Retinopathy",
      },
      {
        name: "Severe NPDR",
        key: "diabetic_retinopathy_severe_npdr",
        diagnosis: "Severe Non-Proliferative Diabetic Retinopathy",
      },
      {
        name: "PDR",
        key: "diabetic_retinopathy_pdr",
        diagnosis: "Proliferative Diabetic Retinopathy",
      },
      {
        name: "High Risk PDR",
        key: "diabetic_retinopathy_high_risk_pdr",
        diagnosis: "High Risk Proliferative Diabetic Retinopathy",
      },
    ],
    macular_degeneration: [
      {
        name: "Dry",
        key: "macular_degeneration_dry",
        diagnosis: "Dry Macular Degeneration",
      },
      {
        name: "Wet",
        key: "macular_degeneration_wet",
        diagnosis: "Wet Macular Degeneration",
      },
      {
        name: "Disciform Scar",
        key: "macular_degeneration_disciform_scar",
        diagnosis: "Disciform Scar",
      },
      {
        name: "Geographic Atrophy",
        key: "macular_degeneration_geographic_atrophy",
        diagnosis: "Geographic Atrophy"
      }
    ],
    vein_occlusion: [
      {
        name: "BRVO",
        key: "vein_occlusion_brvo",
      },
      {
        name: "CRVO",
        key: "vein_occlusion_crvo",
        diagnosis: "CRVO Vein Occlusion",
      },
      {
        name: "HRVO",
        key: "vein_occlusion_hrvo",
      },
    ],
    arterial_occlusion: [
      {
        name: "CRAO",
        key: "arterial_occlusion_crao",
        diagnosis: "Central Retinal Artery Occlusion",
      },
      {
        name: "BRAO",
        key: "arterial_occlusion_brao",
      },
    ],
  };

  public LEVEL4_OPTIONS: { [key: string]: GlDiagnosisOption[]; } = {
    secondary_open_angle_pseudoexfoliation_glaucoma: [
      {
        name: "Mild",
        key: "mild_pseudoexfoliation_glaucoma",
        diagnosis: "Mild Pseudoexfoliation Glaucoma",
      },
      {
        name: "Moderate",
        key: "moderate_pseudoexfoliation_glaucoma",
        diagnosis: "Moderate Pseudoexfoliation Glaucoma",
      },
      {
        name: "Severe",
        key: "severe_pseudoexfoliation_glaucoma",
        diagnosis: "Severe Pseudoexfoliation Glaucoma",
      },
      {
        name: "End-Stage",
        key: "end_stage_pseudoexfoliation_glaucoma",
        diagnosis: "End-Stage Pseudoexfoliation Glaucoma",
      },
    ],
    pigment_dispersion_glaucoma: [
      {
        name: "Mild",
        key: "mild_pigment_dispersion_glaucoma",
        diagnosis: "Mild Pigment Dispersion Glaucoma",
      },
      {
        name: "Moderate",
        key: "moderate_pigment_dispersion_glaucoma",
        diagnosis: "Moderate Pigment Dispersion Glaucoma",
      },
      {
        name: "Severe",
        key: "severe_pigment_dispersion_glaucoma",
        diagnosis: "Severe Pigment Dispersion Glaucoma",
      },
      {
        name: "End-Stage",
        key: "end_stage_pigment_dispersion_glaucoma",
        diagnosis: "End-Stage Pigment Dispersion Glaucoma",
      },
    ],
    secondary_open_angle_other_glaucoma: [
      {
        name: "Mild",
        key: "secondary_open_angle_mild_other_glaucoma",
        diagnosis: "Mild [OTHER] Glaucoma",
      },
      {
        name: "Moderate",
        key: "secondary_open_angle_moderate_other_glaucoma",
        diagnosis: "Moderate [OTHER] Glaucoma",
      },
      {
        name: "Severe",
        key: "secondary_open_angle_severe_other_glaucoma",
        diagnosis: "Severe [OTHER] Glaucoma",
      },
      {
        name: "End-Stage",
        key: "secondary_open_angle_end_stage_other_glaucoma",
        diagnosis: "End-Stage [OTHER] Glaucoma",
      },
    ],
    secondary_angle_closure_neovascular: [
      {
        name: "Mild",
        key: "secondary_angle_closure_mild_neovascular_glaucoma",
        diagnosis: "Mild Neovascular Glaucoma",
      },
      {
        name: "Moderate",
        key: "secondary_angle_closure_moderate_neovascular_glaucoma",
        diagnosis: "Moderate Neovascular Glaucoma",
      },
      {
        name: "Severe",
        key: "secondary_angle_closure_severe_neovascular_glaucoma",
        diagnosis: "Severe Neovascular Glaucoma",
      },
      {
        name: "End-Stage",
        key: "secondary_angle_closure_end_stage_neovascular_glaucoma",
        diagnosis: "End-Stage Neovascular Glaucoma",
      },
    ],
    secondary_angle_closure_other_glaucoma: [
      {
        name: "Mild",
        key: "secondary_angle_closure_mild_other_glaucoma",
        diagnosis: "Mild [OTHER] Glaucoma",
      },
      {
        name: "Moderate",
        key: "secondary_angle_closure_moderate_other_glaucoma",
        diagnosis: "Moderate [OTHER] Glaucoma",
      },
      {
        name: "Severe",
        key: "secondary_angle_closure_severe_other_glaucoma",
        diagnosis: "Severe [OTHER] Glaucoma",
      },
      {
        name: "End-Stage",
        key: "secondary_angle_closure_end_stage_other_glaucoma",
        diagnosis: "End-Stage [OTHER] Glaucoma",
      },
    ],
    vein_occlusion_brvo: [
      {
        name: "ST BRVO",
        key: "vein_occlusion_brvo_st",
        diagnosis: "Superotemporal Branch Retinal Vein Occlusion",
      },
      {
        name: "IT BRVO",
        key: "vein_occlusion_brvo_it",
        diagnosis: "Inferotemporal Branch Retinal Vein Occlusion ",
      },
      {
        name: "Macular BRVO",
        key: "vein_occlusion_brvo_macular",
        diagnosis: "Macular Branch Retinal Vein Occlusion ",
      },
      {
        name: "SN BRVO",
        key: "vein_occlusion_brvo_sn",
        diagnosis: "Superonasal Branch Retinal Vein Occlusion ",
      },
      {
        name: "IN BRVO",
        key: "vein_occlusion_brvo_in",
        diagnosis: "Inferonasal Branch Retinal Vein Occlusion ",
      },
    ],
    vein_occlusion_hrvo: [
      {
        name: "Superior HRVO",
        key: "vein_occlusion_hrvo_superior",
        diagnosis: "Superior HRVO",
      },
      {
        name: "Inferior HRVO",
        key: "vein_occlusion_hrvo_inferior",
        diagnosis: "Inferior HRVO",
      },
    ],
    arterial_occlusion_brao: [
      {
        name: "ST BRAO",
        key: "arterial_occlusion_brao_st",
        diagnosis: "Superotemporal Branch Retinal Artery Occlusion",
      },
      {
        name: "IT BRAO",
        key: "arterial_occlusion_brao_it",
        diagnosis: "Inferotemporal Branch Retinal Artery Occlusion",
      },
      {
        name: "Cilioretinal BRAO",
        key: "arterial_occlusion_brao_cilioretinal ",
        diagnosis: "Cilioretinal Branch Retinal Artery Occlusion",
      },
      {
        name: "SN BRAO",
        key: "arterial_occlusion_brao_sn",
        diagnosis: "Superonasal Branch Retinal Artery Occlusion",
      },
      {
        name: "IN BRAO",
        key: "arterial_occlusion_brao_in",
        diagnosis: "Inferonasal Branch Retinal Artery Occlusion",
      },
    ],
  };

  private MODIFIERS = [
    {
      name: "CSME",
      key: "csme",
      diagnosis: "with Macular Oedema",
    },
    {
      name: "non-CSME",
      key: "non-csme",
      diagnosis: "with Non-Central Macular Oedema",
    },
    {
      name: "CMO",
      key: "cmo",
      diagnosis: "with Macular Oedema",
    },
    {
      name: "NV",
      key: "nv",
      diagnosis: "with Neovascularisation",
    },
  ];

  private DIAGNOSIS_MODIFIERS = [
    {
      keys: [
        "diabetic_retinopathy_min_npdr",
        "diabetic_retinopathy_mod_npdr",
        "diabetic_retinopathy_severe_npdr",
        "diabetic_retinopathy_pdr",
        "diabetic_retinopathy_high_risk_pdr",
      ],
      modifiers: ["csme", "non-csme"],
    },
    {
      keys: [
        "vein_occlusion_brvo_st",
        "vein_occlusion_brvo_it",
        "vein_occlusion_brvo_macular",
        "vein_occlusion_brvo_sn",
        "vein_occlusion_brvo_in",
        "vein_occlusion_hrvo_superior",
        "vein_occlusion_hrvo_inferior",
      ],
      modifiers: ["cmo", "nv"],
    },
  ];

  private DEFAULT_EMPTY_DIAGNOSIS: GlDiagnosis = {
    level1: undefined,
  };

  private DEFAULT_DIAGNOSIS: GlDiagnosis = {
    level1: { name: "Healthy", key: "healthy", diagnosis: "Healthy" },
  };

  private DEFAULT_CATARACT_DIAGNOSIS: GlDiagnosis = {
    level1: {
      name: "Cataract",
      key: "cataract",
      diagnosis: "Cataract",
    },
  };

  private DEFAULT_RETINA_DIAGNOSIS: GlDiagnosis = {
    level1: {
      name: "Retina",
      key: "retina",
      diagnosis: "Retina",
    },
    level2: {
      name: "Diabetic Retinopathy",
      key: "diabetic_retinopathy",
    },
    level3: {
      name: "No DR",
      key: "diabetic_retinopathy_no_dr",
      diagnosis: "No diabetic retinopathy",
    },
  };

  constructor() {
    "ngInject";
  }

  diagnosisIncludesDisease(
    diagnosis: GlBilateral<GlDiagnosis[]>,
    disease: GlManagementType,
    side: IGlSide
  ) {
    if (side === "both") {
      return (
        this.sideDiagnosisIncludesDisease(diagnosis?.left, disease) ||
        this.sideDiagnosisIncludesDisease(diagnosis?.right, disease)
      );
    } else {
      return this.sideDiagnosisIncludesDisease(diagnosis?.[side], disease);
    }
  }

  sideDiagnosisIncludesDisease(
    diagnosis: GlDiagnosis[] = [],
    disease: GlManagementType
  ) {
    return diagnosis.some((d) => {
      if (disease === "glaucoma") {
        return [
          "primary_open_angle",
          "primary_angle_closure",
          "secondary_open_angle",
          "secondary_angle_closure",
        ].includes(d.level1?.key);
      } else {
        return d.level1?.key === disease;
      }
    });
  }

  getPrimaryDiagnosis(diagnosis: GlBilateral<GlDiagnosis[]>, side: IGlSide) {
    const primaryDiagnosis = diagnosis?.[side]?.[0];
    return primaryDiagnosis ? this.getDiagnosis(primaryDiagnosis) : undefined;
  }

  getDefaultDiagnosis(condition: GL_VIEWS) {
    if (!condition) {
      return this.DEFAULT_EMPTY_DIAGNOSIS;
    } else if (condition === "Cataract") {
      return this.DEFAULT_CATARACT_DIAGNOSIS;
    } else if (condition === "Retina") {
      return this.DEFAULT_RETINA_DIAGNOSIS;
    }
    return this.DEFAULT_DIAGNOSIS;
  }

  getDiagnosis(diagnosis: GlDiagnosis) {
    if (!diagnosis) {
      return;
    }
    const { level1, level2, level2_other, level3, level4 } = diagnosis;

    const diagnosisText = level4?.diagnosis
      ? level4.diagnosis
      : level3?.diagnosis
        ? level3.diagnosis
        : level2?.diagnosis
          ? level2.diagnosis
          : level1?.diagnosis;

    if (this.level1ShouldShowOtherTextField(diagnosis)) {
      return level2_other || "Other";
    } else if (this.level2ShouldShowOtherTextField(diagnosis)) {
      return replace(
        diagnosisText,
        OTHER_COMMENT_PLACEHOLDER,
        level2_other || "Other"
      );
    } else {
      return diagnosisText;
    }
  }

  getDiagnosisForSide(
    bilateralDiagnosis: GlBilateral<GlDiagnosis>,
    side: IGlSide
  ) {
    if (!bilateralDiagnosis?.[side]) {
      return;
    }
    return this.getDiagnosis(bilateralDiagnosis[side]);
  }

  showOtherTextField(diagnosis: GlDiagnosis) {
    return (
      this.level1ShouldShowOtherTextField(diagnosis) ||
      this.level2ShouldShowOtherTextField(diagnosis)
    );
  }

  level1ShouldShowOtherTextField(diagnosis: GlDiagnosis) {
    return (
      diagnosis?.level1 &&
      (diagnosis.level1.key === "diagnosis_other" ||
        diagnosis.level1?.showOther)
    );
  }

  level2ShouldShowOtherTextField(diagnosis: GlDiagnosis) {
    return (
      diagnosis?.level2 &&
      (["secondary_open_angle_other", "secondary_angle_closure_other"].includes(
        diagnosis.level2.key
      ) ||
        diagnosis.level2?.showOther)
    );
  }

  mapOldDiagnosisToNew(diagnosis: any) {
    const newDiagnosis: any = {};
    if (isNil(diagnosis)) {
      return newDiagnosis;
    }

    Object.assign(
      newDiagnosis,
      this.mapBySideOldDiagnosisToNew(diagnosis, "left"),
      this.mapBySideOldDiagnosisToNew(diagnosis, "right")
    );
    return newDiagnosis;
  }

  mapBySideOldDiagnosisToNew(
    diagnosis: any,
    side: IGlSide
  ): GlBilateral<GlDiagnosis> {
    const existingDiagnosis = get(diagnosis, side);
    const newDiagnosis: GlBilateral<GlDiagnosis> = {};
    if ("level1" in diagnosis) {
      // this is already a new diagnosis record.
      // there is no need to map it to the new
      newDiagnosis[side] = existingDiagnosis;
      return newDiagnosis;
    }
    let level1: GlDiagnosisOption;
    let level2: GlDiagnosisOption;
    let level3: GlDiagnosisOption;
    let level4: GlDiagnosisOption;
    switch (existingDiagnosis.key) {
      case "notGlaucoma": // Not Glaucoma
        level1 = this.getVal(this.LEVEL1_OPTIONS, "not_glaucoma");
        break;
      case "ocularHypertension":
      case "occularHypertension": // Ocular Hypertension (primary)
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "ocular_hypertension"
        );
        break;
      case "glaucomaSuspect": // Glaucoma Suspect (primary)
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "glaucoma_suspect"
        );
        break;
      case "chronicOpenAngleGlaucoma": // Chronic Open Angle Glaucoma
      case "normalTensionGlaucoma": // Normal Tension Glaucoma
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "ocular_ht_glaucoma"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "mild_open_angle_glaucoma"
        );
        break;
      case "angleClosureSuspect": // Angle Closure Suspect
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "angle_closure_suspect"
        );
        break;
      case "treatedAngleClosure": // Treated Angle Closure
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "treated_angle_closure_suspect"
        );
        break;
      case "angleGlaucoma": // Angle Closure
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "primary_angle_closure"
        );
        break;
      case "angleClosureGlaucoma": // Angle Closure Glaucoma
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "primary_angle_closure_glaucoma"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "mild_angle_closure_glaucoma"
        );
        break;
      case "pxf": // Pseudoexfoliation Syndrome
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_open_angle_pseudoexfoliation"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "pseudoexfoliation_syndrome"
        );
        break;
      case "pxg": // Pseudoexfoliation Glaucoma
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_open_angle_pseudoexfoliation"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "secondary_open_angle_pseudoexfoliation_glaucoma"
        );
        level4 = this.getVal(
          this.LEVEL4_OPTIONS[level3.key],
          "mild_pseudoexfoliation_glaucoma"
        );
        break;
      case "pds": // Pigment Dispersion Syndrome
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_open_angle_pigment_dispersion"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "pigment_dispersion_syndrome"
        );
        break;
      case "pdg": // Pigment Dispersion Glaucoma
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_open_angle_pigment_dispersion"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "pigment_dispersion_glaucoma"
        );
        level4 = this.getVal(
          this.LEVEL4_OPTIONS[level3.key],
          "mild_pigment_dispersion_glaucoma"
        );
        break;
      case "pic": // Plateau Iris Configuration
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "plateau_iris_configuration"
        );
        break;
      case "pis": // Plateau Iris Syndrome
        level1 = this.getVal(this.LEVEL1_OPTIONS, "primary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "plateau_iris_syndrome"
        );
        break;
      case "secondaryOpenAngleGlaucoma": // Secondary Open Angle Glaucoma
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_open_angle_other"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "secondary_open_angle_other_glaucoma"
        );
        level4 = this.getVal(
          this.LEVEL4_OPTIONS[level3.key],
          "secondary_open_angle_mild_other_glaucoma"
        );
        break;
      case "secondaryAngleClosureGlaucoma": // Secondary Angle Closure Glaucoma
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_angle_closure_other"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "secondary_angle_closure_other_glaucoma"
        );
        level4 = this.getVal(
          this.LEVEL4_OPTIONS[level3.key],
          "secondary_angle_closure_mild_other_glaucoma"
        );
        break;
      case "secondaryOpenAngleOcularHypertension": // Secondary Open Angle Ocular Hypertension
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_open_angle");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_open_angle_other"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "secondary_open_angle_other_ocular_hypertension"
        );
        break;
      case "secondaryAngleClosureOcularHypertension": // Secondary Angle Closure Ocular Hypertension
        level1 = this.getVal(this.LEVEL1_OPTIONS, "secondary_angle_closure");
        level2 = this.getVal(
          this.LEVEL2_OPTIONS[level1.key],
          "secondary_angle_closure_other"
        );
        level3 = this.getVal(
          this.LEVEL3_OPTIONS[level2.key],
          "secondary_angle_closure_other_ocular_hypertension"
        );
        break;
    }
    if (level1) {
      set(newDiagnosis, this.getLevel1FieldName(side), level1);
    }
    if (level2) {
      set(newDiagnosis, this.getLevel2FieldName(side), level2);
    }
    if (level3) {
      set(newDiagnosis, this.getLevel3FieldName(side), level3);
    }
    if (level4) {
      set(newDiagnosis, this.getLevel4FieldName(side), level4);
    }

    return newDiagnosis;
  }

  getVal(fromList: GlDiagnosisOption[], key: string) {
    return find(fromList, (o) => {
      return o.key === key;
    });
  }

  getLevel1FieldName(side: IGlSide) {
    return `${side}.level1`;
  }

  getLevel2FieldName(side: IGlSide) {
    return `${side}.level2`;
  }

  getLevel2OtherFieldName(side: IGlSide) {
    return `${side}.level2_other`;
  }

  getLevel3FieldName(side: IGlSide) {
    return `${side}.level3`;
  }

  getLevel4FieldName(side: IGlSide) {
    return `${side}.level4`;
  }

  getDiagnosisForLetter(diagnosis: GlBilateral<GlDiagnosis[]>) {
    // first create a copy of the left & right diagnosis arrays
    // filter out anything that is empty or "Healthy"
    const right = diagnosis.right
      ? diagnosis.right
        .filter((d) => !isNil(d) && d?.level1?.key !== "healthy")
        .map((d) => this.getDiagnosis(d))
      : [];
    const rightDiagnosisSet = new Set(right);
    const left = diagnosis.left
      ? diagnosis.left
        .filter((d) => !isNil(d) && d?.level1?.key !== "healthy")
        .map((d) => this.getDiagnosis(d))
      : [];
    const leftDiagnosisSet = new Set(left);

    // remove any Not Glaucoma Diagnosis
    if (leftDiagnosisSet.has("Not Glaucoma")) {
      leftDiagnosisSet.delete("Not Glaucoma");
    }
    if (rightDiagnosisSet.has("Not Glaucoma")) {
      rightDiagnosisSet.delete("Not Glaucoma");
    }

    // now work out which diagnosis are bilateral
    const bilateralDiagnosisSet = intersection(
      rightDiagnosisSet,
      leftDiagnosisSet
    );

    // now remove all bilateral diagnosis from each eye
    for (const diagnosis of bilateralDiagnosisSet) {
      if (rightDiagnosisSet.has(diagnosis)) {
        rightDiagnosisSet.delete(diagnosis);
      }
      if (leftDiagnosisSet.has(diagnosis)) {
        leftDiagnosisSet.delete(diagnosis);
      }
    }

    // now convert each diagnosis to text
    let allDiagnosis = [];
    allDiagnosis = allDiagnosis.concat(
      convertDiagnosisToText(bilateralDiagnosisSet, "Bilateral")
    );
    // R/E -> L/E always
    allDiagnosis = allDiagnosis.concat(
      convertDiagnosisToText(rightDiagnosisSet, "R/E")
    );
    allDiagnosis = allDiagnosis.concat(
      convertDiagnosisToText(leftDiagnosisSet, "L/E")
    );

    return joinTextArray(allDiagnosis);
  }

  getModifiersForDiagnosis(diagnosis: GlDiagnosisOption) {
    const modifiers = this.DIAGNOSIS_MODIFIERS.find((modifiers) =>
      modifiers.keys.includes(diagnosis.key)
    );
    if (modifiers) {
      return this.MODIFIERS.filter((m) => modifiers.modifiers.includes(m.key));
    }
  }

  // this taes
  convertDiagnosisOptionToKey(diagnosis: GlDiagnosis) {
    // max is 2 levels
    const { level1, level2 } = diagnosis;
    return [level1?.key, level2?.key].filter((k) => !isNil(k)).join(".");
  }

  // check if certain diagnosis are restricted
  checkIfDiagnosisAllowsMultiSelect(diagnosis: GlDiagnosisOption) {
    // can extend to extra dictionaries if needed
    const foundDiagnosis: GlDiagnosisOptionExtra[] = [
      this.LEVEL1_OPTIONS_EXTRA,
    ].find((dict) =>
      dict.find((d) => d?.key === diagnosis?.key && d?.disableMultiSelect)
    );

    // if not empty then its not allowed, otherwise it is allowed
    return isEmpty(foundDiagnosis);
  }

  checkIfDiagnosisArrayAllowsMultiSelectValues(diagnosisArray: GlDiagnosis[]) {
    for (const diagnosis of diagnosisArray ?? []) {
      const { level1, level2 } = diagnosis;

      if (
        some(
          [level1, level2],
          (o) => !this.checkIfDiagnosisAllowsMultiSelect(o)
        )
      ) {
        return false;
      }
    }

    return true;
  }
}

function intersection(setA: Set<string>, setB: Set<string>) {
  const _intersection = new Set<string>();
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

function joinTextArray(array: string[] = []) {
  if (array.length <= 1) {
    return array.join(", ");
  } else {
    const lastElement = array[array.length - 1];
    const startOfArray = array.slice(0, array.length - 1);
    return `${startOfArray.join(", ")} and ${lastElement}`;
  }
}

function convertDiagnosisToText(
  diagnosisSet: Set<string>,
  description: string
) {
  const text: string[] = [];
  for (const diagnosis of diagnosisSet) {
    text.push(`${description} ${diagnosis}`);
  }
  return text;
}
