import { get, isNaN, isNull, isUndefined } from "lodash";
import { IPatientDocumentDicomData } from "models/patient-document.model";
import { GlBilateral, PatientRecordData } from "models/patient-record.model";
import { Appendix, IGlOption } from "../../../services/appendix";
import {
  DICOM_TAG_FIELD_GHT,
  DICOM_TAG_FIELD_MD,
  DICOM_TAG_FIELD_PSD,
  DICOM_TAG_FIELD_VFI,
  DicomTagHelper,
} from "../../../services/dicom-tag-helper/dicom-tag-helper";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";

import "./segment-field.scss";

class SegmentFieldController
  extends GlFormController
  implements angular.IController, angular.IOnChanges {
  record: PatientRecordData;
  leftFieldDicomData: IPatientDocumentDicomData;
  rightFieldDicomData: IPatientDocumentDicomData;

  dicomIdPsd = DICOM_TAG_FIELD_PSD;
  dicomIdVFI = DICOM_TAG_FIELD_VFI;
  dicomIdReliability = DICOM_TAG_FIELD_GHT;
  dicomIdMd = DICOM_TAG_FIELD_MD;

  defaultLeftMd: number;
  defaultLeftVfi: number;
  defaultLeftPsd: number;
  defaultLeftVfiStatus: IGlOption;

  defaultRightMd: number;
  defaultRightVfi: number;
  defaultRightPsd: number;
  defaultRightVfiStatus: IGlOption;

  showMd = false;
  showVfi = true;
  showPsd = false;

  constructor(
    private DicomTagHelper: DicomTagHelper,
    private appendix: Appendix
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.record && this.record) {
      // update default field to display
      this.updateMdVfiPsdToDisplay();
    }
    if (changes.leftFieldDicomData && this.leftFieldDicomData) {
      this.defaultLeftMd = +this.DicomTagHelper.getValueForDicomId(
        this.dicomIdMd,
        this.leftFieldDicomData
      );
      this.defaultLeftVfi = +this.DicomTagHelper.getValueForDicomId(
        this.dicomIdVFI,
        this.leftFieldDicomData
      );
      this.defaultLeftPsd = +this.DicomTagHelper.getValueForDicomId(
        this.dicomIdPsd,
        this.leftFieldDicomData
      );
      const vfi_status = this.DicomTagHelper.getValueForDicomId(
        this.dicomIdReliability,
        this.leftFieldDicomData
      );
      this.defaultLeftVfiStatus = this.mapDicomVfiStatusToGlStatus(vfi_status);
    }
    if (changes.rightFieldDicomData && this.rightFieldDicomData) {
      this.defaultRightMd = +this.DicomTagHelper.getValueForDicomId(
        this.dicomIdMd,
        this.rightFieldDicomData
      );
      this.defaultRightVfi = +this.DicomTagHelper.getValueForDicomId(
        this.dicomIdVFI,
        this.rightFieldDicomData
      );
      this.defaultRightPsd = +this.DicomTagHelper.getValueForDicomId(
        this.dicomIdPsd,
        this.rightFieldDicomData
      );
      const vfi_status = this.DicomTagHelper.getValueForDicomId(
        this.dicomIdReliability,
        this.rightFieldDicomData
      );
      this.defaultRightVfiStatus = this.mapDicomVfiStatusToGlStatus(vfi_status);
    }
  }

  reliableDidChange() {
    if (this.isEditMode()) {
      if (
        this.record.vfi_reliable?.right?.key === "no" &&
        this.record.vfi_status
      ) {
        this.record.vfi_status.left = undefined;
      }
      if (
        this.record.vfi_reliable?.left?.key === "no" &&
        this.record.vfi_status
      ) {
        this.record.vfi_status.right = undefined;
      }
    }
  }

  isEmpty(value: any) {
    return isUndefined(value) || isNull(value) || isNaN(value);
  }

  mapDicomVfiStatusToGlStatus(value: string) {
    const opts = this.appendix.get("vfi_status");
    if (value === "Outside Normal Limits") {
      return opts.find((o) => o.key === "glaucomatous");
    } else if (value === "Within Normal Limits") {
      return opts.find((o) => o.key === "WNL");
    } else if (value === "Borderline") {
      return opts.find((o) => o.key === "suspect");
    }
  }

  hasField(fieldArray: string[]) {
    return fieldArray.some(
      (field) =>
        !!get(this.record, `${field}.left`) ||
        !!get(this.record, `${field}.right`)
    );
  }

  updateMdVfiPsdToDisplay() {
    if (this.hasField(["vfi"])) {
      this.showVfi = true;
      this.showMd = this.showPsd = false;
    } else if (this.hasField(["md"])) {
      this.showMd = true;
      this.showVfi = this.showPsd = false;
    } else if (this.hasField(["psd"])) {
      this.showPsd = true;
      this.showMd = this.showVfi = false;
    }
  }

  toggleMd() {
    // only allow the field to be toggled if either it is already off, or another field is active
    if (!this.showMd || this.showPsd || this.showVfi) {
      this.showMd = !this.showMd;
    }
  }

  toggleVfi() {
    // only allow the field to be toggled if either it is already off, or another field is active
    if (!this.showVfi || this.showMd || this.showPsd) {
      this.showVfi = !this.showVfi;
    }
  }

  togglePsd() {
    // only allow the field to be toggled if either it is already off, or another field is active
    if (!this.showPsd || this.showMd || this.showVfi) {
      this.showPsd = !this.showPsd;
    }
  }

  requireMdVfiOrPsd(field: "md" | "vfi" | "psd") {
    const { md, psd, vfi } = this.record ?? {};

    if (field === "md") {
      return (
        this.hasField2(md) || !(this.hasField2(psd) || this.hasField2(vfi))
      );
    } else if (field === "vfi") {
      return (
        this.hasField2(vfi) || !(this.hasField2(md) || this.hasField2(psd))
      );
    } else if (field === "psd") {
      return (
        this.hasField2(psd) || !(this.hasField2(md) || this.hasField2(vfi))
      );
    }
  }

  private hasField2(field: GlBilateral<number>) {
    return !!field?.left || !!field?.right;
  }
}

export class SegmentField implements angular.IComponentOptions {
  static selector = "segmentField";
  static template = require("./segment-field.html");
  static controller = SegmentFieldController;
  static bindings = {
    enableLeft: "<",
    enableRight: "<",
    leftFieldDicomData: "<",
    rightFieldDicomData: "<",
    record: "<",
    ...GlFormControllerBindings,
  };
}
