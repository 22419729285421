import { AuthService } from "app/core/services/auth.service";
import { GlClinicActiveRecord } from "models/clinic-active-records.model";
import { ClinicData } from "models/clinic.model";
import { PatientRecord } from "models/patient-record.model";
import { Patient, User } from "models/user.model";
import * as moment from "moment";
import {
  STORAGE_KEY_DOCUMENT_UPDATE_INTERVAL,
  STORAGE_KEY_LAST_CHECK_TIME,
  UPDATE_INTERVAL_10_MINS,
  UPDATE_INTERVAL_2_MINS,
  UPDATE_INTERVAL_30_MINS,
  UPDATE_INTERVAL_5_MINS,
  UPDATE_INTERVAL_MANUAL
} from "../../../../../app/core/services/patient-document.service";
import {
  IDocumentImportMatchError,
  IDocumentImportMatchUpload,
  IDocumentUploadPatientMatch,
  IOrthancDocumentMatchResults,
  OrthancDocumentService,
} from "../../services/orthanc-document/orthanc-document.service";
import "./document-uploads.scss";

export interface IDocumentMatchWithId extends IDocumentImportMatchUpload {
  id: number;
  patient: User;
}

export interface IDocumentErrorWithId extends IDocumentImportMatchError {
  id: number;
  patient: User;
}

/**
 * @deprecated
 * in favour of DocumentImport on record page load
 */
export class DocumentUploadsController
  implements
  angular.IComponentController,
  angular.IOnChanges,
  angular.IOnDestroy {
  documentImportIsEnabled = false;
  watchUploads = false;

  // @Inputs()
  clinicConfig: ClinicData;
  activeRecords: GlClinicActiveRecord[] = [];

  checkInProgress = false;
  patientCheckProgressCounter = 0;
  patientCheckProgressTotal = 0;
  lastUpdateTime: Date;
  uploadInProgress = false; // No uploads at the moment

  documents: IDocumentMatchWithId[] = [];
  errors: IDocumentErrorWithId[] = [];
  nextAutomaticCheckTimer: angular.IPromise<void>;

  updateIntervals = [
    UPDATE_INTERVAL_MANUAL,
    UPDATE_INTERVAL_2_MINS,
    UPDATE_INTERVAL_5_MINS,
    UPDATE_INTERVAL_10_MINS,
    UPDATE_INTERVAL_30_MINS,
  ];

  updateInterval =
    this.$window.localStorage.getItem(STORAGE_KEY_DOCUMENT_UPDATE_INTERVAL) ||
    UPDATE_INTERVAL_10_MINS;

  constructor(
    private $q: angular.IQService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private AuthService: AuthService,
    private OrthancDocumentService: OrthancDocumentService,
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.clinicConfig && this.clinicConfig) {
      this.documentImportIsEnabled = this.OrthancDocumentService.checkIfDocumentImportsAreEnabled(this.clinicConfig);
    }
    if (this.documentImportIsEnabled) {
      this.lastUpdateTime = this.getNextUpdateTime();
      this.watchUploads = !!this.lastUpdateTime;
      if (
        this.watchUploads &&
        this.activeRecords &&
        this.activeRecords.length > 0
      ) {
        this.initAutoCheckTimer();
      } else {
        this.cancelAutomaticTimer();
      }
    }
  }

  $onDestroy() {
    this.cancelAutomaticTimer();
  }

  stopWatch() {
    this.watchUploads = false;
    this.$window.localStorage.removeItem(STORAGE_KEY_LAST_CHECK_TIME);
  }

  startWatch() {
    this.watchUploads = true;
    this.doAutomaticCheck();
  }

  dismissError(id: number) {
    this.errors = this.errors.filter((e) => e.id !== id);
  }

  // old document workflow
  checkNow() {
    this.setUpdateTime();
    this.patientCheckProgressCounter = 0;
    this.checkInProgress = true;
    this.documents = [];
    this.errors = [];

    // change this to import by records that belong
    // to the current patient atthe day of call
    const activeRecordsToImport = this.OrthancDocumentService.patientsToCheckForDocuments(
      this.activeRecords || []
    );

    // number of records to import?
    this.patientCheckProgressTotal = activeRecordsToImport.length;

    // this creates a promise chain
    // to import for all active records 
    // take inspiration from this to create your own subroutines
    activeRecordsToImport
      .reduce((promises, ar, index) => {
        return promises.then(() => {
          this.patientCheckProgressCounter = index + 1;
          return promises.then(() => this.importOrthancDocForActiveRecord(ar));
        });
      }, this.$q.resolve())
      .finally(() => {
        this.checkInProgress = false;
      });
  }

  removeUpload(id: number) {
    this.documents = this.documents.filter((d) => d.id !== id);
  }

  updateTimerInterval() {
    this.$window.localStorage.setItem(
      STORAGE_KEY_DOCUMENT_UPDATE_INTERVAL,
      this.updateInterval
    );
    this.cancelAutomaticTimer();
    this.initAutoCheckTimer();
  }

  private cancelAutomaticTimer() {
    if (this.nextAutomaticCheckTimer) {
      this.$timeout.cancel(this.nextAutomaticCheckTimer);
      this.nextAutomaticCheckTimer = null;
    }
  }

  private initAutoCheckTimer() {
    if (
      this.nextAutomaticCheckTimer ||
      this.updateInterval === UPDATE_INTERVAL_MANUAL
    ) {
      // do nothing as the time is already running;
      return;
    }
    const now = moment();
    if (now.isAfter(this.getNextUpdateTime())) {
      this.doAutomaticCheck();
    } else {
      this.setNextUpdateInterval();
    }
  }

  private doAutomaticCheck() {
    // continue if session is authenticated
    // this is to avoid spamming 401's
    const canContinue: boolean = this.AuthService.autoProcessCanContinue();
    if (canContinue) {
      this.checkNow();
    }
    // else reset and try again
    this.setNextUpdateInterval();
  }

  private setNextUpdateInterval() {
    this.nextAutomaticCheckTimer = this.$timeout(
      () => this.doAutomaticCheck(),
      moment(this.getNextUpdateTime()).diff(moment(), "milliseconds")
    );
  }

  // for active records
  private importOrthancDocForActiveRecord(ar: GlClinicActiveRecord) {
    return this.importOrthancDocForPatientRecord(ar.record, (ar.patient as Patient));
  }

  // generalised function
  private importOrthancDocForPatientRecord(record: PatientRecord, patient: Patient) {
    return this.OrthancDocumentService.importOrthancDocsForPatient(patient?.data?.file_no, record?.appointment_date)
      .then((orthancInstances: IOrthancDocumentMatchResults) =>
        // after mapping them out into known orthanc documents
        // sort them into actual gl documents
        this.OrthancDocumentService.sortOrthancDocumentsForRecord(
          record,
          orthancInstances
        )
      )
      .then((results: IDocumentUploadPatientMatch) => {
        // results that are mapped
        return {
          uploads: this._mapUploads(results?.uploads ?? [], patient) ?? [],
          errors: this._mapErrors(results?.errors ?? [], patient) ?? []
        };
      })
      .catch((error) => {
        console.error("Error importing documents for ", { patient, record }, error);
      });
  }

  private getNextUpdateTime() {
    const updateInterval = this.getUpdateIntervalInMins();

    const lastUpdateTimeFromLocalStorage = this.$window.localStorage.getItem(
      STORAGE_KEY_LAST_CHECK_TIME
    );
    return (
      lastUpdateTimeFromLocalStorage &&
      moment(lastUpdateTimeFromLocalStorage)
        .add(updateInterval, "minutes")
        .toDate()
    );
  }

  private getUpdateIntervalInMins() {
    switch (this.updateInterval) {
      case UPDATE_INTERVAL_2_MINS:
        return 2;
      case UPDATE_INTERVAL_5_MINS:
        return 5;
      case UPDATE_INTERVAL_10_MINS:
        return 10;
      case UPDATE_INTERVAL_30_MINS:
        return 30;
    }
  }

  private setUpdateTime() {
    const now = new Date();
    this.$window.localStorage.setItem(
      STORAGE_KEY_LAST_CHECK_TIME,
      now.toISOString()
    );
    this.lastUpdateTime = now;
  }


  // formatted
  private _mapUploads(documents: IDocumentImportMatchUpload[], patient: User) {
    return documents.map((d) => {
      // documents add
      return {
        ...d,
        patient,
        id: Math.random(),
      };
    });
  }

  private _mapErrors(errors: IDocumentImportMatchError[], patient: User) {
    return errors.map((d) => {
      return {
        ...d,
        patient,
        id: Math.random(),
      };
    });
  }
}

/**
 * @deprecated
 * in favour of DocumentImport on record page load
 */
export class DocumentUploadsComponent implements angular.IComponentOptions {
  static selector = "documentUploads";
  static template = require("./document-uploads.html");
  static controller = DocumentUploadsController;
  static bindings = {
    activeRecords: "<",
    clinicConfig: "<",
  };
}
