import { IGlSide } from "../../../../models/gl-side.model";
import { PatientDocument } from "../../../../models/patient-document.model";
import { IOrthancDicomTags } from "../orthanc-api/orthanc.models";

export type DocumentTypes = keyof IRecordDocuments;

export const GL_DOCUMENT_DISC_LEFT = "disc_left";
export const GL_DOCUMENT_DISC_RIGHT = "disc_right";
export const GL_DOCUMENT_FIELD_LEFT = "field_left";
export const GL_DOCUMENT_FIELD_RIGHT = "field_right";
export const GL_DOCUMENT_OCT_RNFL = "oct_rnfl";
export const GL_DOCUMENT_OCT_GCC = "oct_gcc";
export const GL_DOCUMENT_OCT_MAC = "oct_mac";
export const GL_DOCUMENT_A_SCAN = "a_scan";
export const GL_DOCUMENT_OTHER = "other";
export const GL_DOCUMENT_REFERRAL = "referral";

export type GlDocumentType =
  | typeof GL_DOCUMENT_DISC_LEFT
  | typeof GL_DOCUMENT_DISC_RIGHT
  | typeof GL_DOCUMENT_FIELD_LEFT
  | typeof GL_DOCUMENT_FIELD_RIGHT
  | typeof GL_DOCUMENT_OCT_RNFL
  | typeof GL_DOCUMENT_OCT_GCC
  | typeof GL_DOCUMENT_OCT_MAC
  | typeof GL_DOCUMENT_A_SCAN
  | typeof GL_DOCUMENT_OTHER
  | typeof GL_DOCUMENT_REFERRAL;

export interface IRecordDocuments {
  [GL_DOCUMENT_DISC_LEFT]?: PatientDocument;
  [GL_DOCUMENT_DISC_RIGHT]?: PatientDocument;
  [GL_DOCUMENT_FIELD_LEFT]?: PatientDocument;
  [GL_DOCUMENT_FIELD_RIGHT]?: PatientDocument;
  [GL_DOCUMENT_OCT_RNFL]?: PatientDocument;
  [GL_DOCUMENT_OCT_GCC]?: PatientDocument;
  [GL_DOCUMENT_OCT_MAC]?: PatientDocument;
  [GL_DOCUMENT_REFERRAL]?: PatientDocument;
  [GL_DOCUMENT_A_SCAN]?: PatientDocument;
  [GL_DOCUMENT_OTHER]?: PatientDocument[];
}

export interface IRecordDicomDocuments {
  [GL_DOCUMENT_DISC_LEFT]?: IOrthancDicomTags;
  [GL_DOCUMENT_DISC_RIGHT]?: IOrthancDicomTags;
  [GL_DOCUMENT_FIELD_LEFT]?: IOrthancDicomTags;
  [GL_DOCUMENT_FIELD_RIGHT]?: IOrthancDicomTags;
  [GL_DOCUMENT_OCT_RNFL]?: IOrthancDicomTags;
  [GL_DOCUMENT_OCT_GCC]?: IOrthancDicomTags;
  [GL_DOCUMENT_OCT_MAC]?: IOrthancDicomTags;
  [GL_DOCUMENT_REFERRAL]?: IOrthancDicomTags;
  [GL_DOCUMENT_A_SCAN]?: IOrthancDicomTags;
  [GL_DOCUMENT_OTHER]?: IOrthancDicomTags[];
}

const TYPES_DISC = [
  "baseline_disc",
  "optom_review_disc",
  "tech_review_disc",
  "disc"
];
const TYPES_FIELD = [
  "baseline_field",
  "optom_review_field",
  "tech_review_field",
  "field"
];

// right eye is RNFL
const TYPES_OCT_RNFL = ["baseline_oct", "optom_review_oct", "tech_review_oct"];
const TYPES_OCT_GCC = ["oct_gcc"];

// left eye is MAC
const TYPES_OCT_MAC = ["baseline_oct", "optom_review_oct", "tech_review_oct"];
const TYPES_REFERRAL = ["referral"];
const TYPES_A_SCAN = ["a_scan"];

export class DocumentsService {
  static injectionName = "DocumentsService";
  constructor() {
    "ngInject";
  }

  /**
   * given an array of documents for a record,
   * sorts them into known document types that we are after
   * 
   * @param documents 
   * @returns 
   */
  getNamedDocuments(documents: PatientDocument[] = []): IRecordDocuments {
    // create a known mapping of documents
    const recordDocuments: IRecordDocuments = {
      disc_left: this.getDocument(documents, TYPES_DISC, "left"),
      disc_right: this.getDocument(documents, TYPES_DISC, "right"),
      field_left: this.getDocument(documents, TYPES_FIELD, "left"),
      field_right: this.getDocument(documents, TYPES_FIELD, "right"),
      oct_gcc: this.getDocument(documents, TYPES_OCT_GCC),
      oct_mac: this.getOctMac(documents),
      // right eye is RNFL
      oct_rnfl: this.getOctRNFL(documents),
      a_scan: this.getDocument(documents, TYPES_A_SCAN),
      referral: this.getDocument(documents, TYPES_REFERRAL)
    };

    // filter out any undefined docs
    const knownDocuments = Object.values(recordDocuments).filter(d => !!d);
    const otherDocuments = this.getOtherDocuments(documents, knownDocuments);
    // adds other unknown documents
    if (otherDocuments.length > 0) {
      recordDocuments.other = otherDocuments;
    }

    // returns it
    return recordDocuments;
  }

  getDocumentDataFieldsForDocType(
    docType: GlDocumentType
  ): { eye?: IGlSide; field: string; } {
    switch (docType) {
      case GL_DOCUMENT_A_SCAN: {
        return {
          field: GL_DOCUMENT_A_SCAN
        };
      }
      case GL_DOCUMENT_DISC_LEFT: {
        return {
          eye: "left",
          field: "disc"
        };
      }
      case GL_DOCUMENT_DISC_RIGHT: {
        return {
          eye: "right",
          field: "disc"
        };
      }
      case GL_DOCUMENT_FIELD_LEFT: {
        return {
          eye: "left",
          field: "field"
        };
      }
      case GL_DOCUMENT_FIELD_RIGHT: {
        return {
          eye: "right",
          field: "field"
        };
      }
      case GL_DOCUMENT_OCT_GCC: {
        return {
          field: GL_DOCUMENT_OCT_GCC
        };
      }
      case GL_DOCUMENT_OCT_MAC: {
        return {
          field: GL_DOCUMENT_OCT_MAC
        };
      }
      case GL_DOCUMENT_OCT_RNFL: {
        return {
          field: GL_DOCUMENT_OCT_RNFL
        };
      }
    }
  }

  getHumanDocumentName(docType: GlDocumentType) {
    switch (docType) {
      case GL_DOCUMENT_A_SCAN: {
        return "A Scan";
      }
      case GL_DOCUMENT_DISC_LEFT: {
        return "Left Disc Photo";
      }
      case GL_DOCUMENT_DISC_RIGHT: {
        return "Right Disc Photo";
      }
      case GL_DOCUMENT_FIELD_LEFT: {
        return "Left Field";
      }
      case GL_DOCUMENT_FIELD_RIGHT: {
        return "Right Field";
      }
      case GL_DOCUMENT_OCT_GCC: {
        return "GCC OCT";
      }
      case GL_DOCUMENT_OCT_MAC: {
        return "MAC OCT";
      }
      case GL_DOCUMENT_OCT_RNFL: {
        return "RNFL OCT";
      }
    }
  }

  private getOctRNFL(documents: PatientDocument[]) {
    const doc = this.getDocument(documents, TYPES_OCT_RNFL, "right");
    return doc || this.getDocument(documents, ["oct_rnfl"]);
  }

  private getOctMac(documents: PatientDocument[]) {
    const doc = this.getDocument(documents, TYPES_OCT_MAC, "left");
    return doc || this.getDocument(documents, ["oct_mac"]);
  }

  private getDocument(
    documents: PatientDocument[],
    fieldList: string[],
    side?: IGlSide
  ) {
    // this just gets the first scan that has a match for 
    // any of the fields
    return documents.find(d => {
      return fieldList.some(f => this.documentIsMatch(d, f, side));
    });
  }

  private documentIsMatch(d: PatientDocument, type: string, side?: IGlSide) {
    if (side) {
      return d.data.eye === side && d.data.field === type;
    }
    return d.data.field === type;
  }

  private getOtherDocuments(
    documents: PatientDocument[],
    knownDocuments: PatientDocument[] = []
  ) {
    return documents.filter(
      d => !knownDocuments.some(known_doc => known_doc.id === d.id)
    );
  }
}
