import { isFunction } from "angular";
import { GlClinicActiveRecord } from "models/clinic-active-records.model";
import { GlPatientRecordWorkflowState } from "models/patient-record.model";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { DASHBOARD_STATES } from "../../../../core/services/appendix";
import "./button-record-progress.scss";
const MCES_CLINIC_ID = 50;
const SUNBURY_CLINIC_ID = 2;
class ButtonRecordProgressController implements angular.IController {
  currentState: string;
  user: User;
  gotoState: ({ state }: { state: string; }) => void;
  updateWorkflow?: (args: {
    state: string;
    activeRecord: GlClinicActiveRecord;
  }) => void;
  createInjection: () => void;
  record: PatientRecord;
  dashboardStates = DASHBOARD_STATES;
  // button functions
  completeRecord: () => void;
  stateDecided = false;
  accordionStates: any;
  selectedState: any;

  constructor(private WHITELISTED_OPTOMS: number[]) {
    "ngInject";
  }

  ifSigned() {
    return !!this.record && this.record.data_signed_by_id;
  }

  setState(state: string, activeRecord: GlClinicActiveRecord) {
    this.stateDecided = false;
    this.updateWorkflow({ state, activeRecord });
    this.saveAndProgressClicked(state);
  }

  saveAndProgressClicked(state: string) {
    if (isFunction(this.gotoState)) {
      this.gotoState({ state });
    }
  }

  // Stop the event from closing the dropdown
  handleEvent(event: Event) {
    event.stopPropagation();
  }

  toLower(s: string) {
    return s?.toLowerCase();
  }

  getCurrentState(workflowState: GlPatientRecordWorkflowState) {
    this.stateDecided = false;
    const re = /\((.*)\)/i;
    const wfs = workflowState?.match(re)?.[1];
    switch (wfs) {
      case "INJ": {
        this.selectedState = "Injection";
        break;
      }
      case "R": {
        this.selectedState = "Retina";
        break;
      }
      case "G": {
        this.selectedState = "Glaucoma";
        break;
      }
      case "C": {
        this.selectedState = "Cataract";
        break;
      }
      case "DE": {
        this.selectedState = "Dry Eye";
        break;
      }
      case "O":
      default: {
        this.selectedState = "Other";
        break;
      }
    }
  }

  userIsOptometrist() {
    return !!(this.user.type.name === "optometrist");
  }

  canSign() {
    return !!(
      (this.user.type.name === "optometrist" &&
        this.record.type !== "virtual_review") ||
      this.user.type.name === "ophthalmologist"
    );
  }

  fromSameClinic() {
    return (
      !this.record?.clinic ||
      !!(this.record?.clinic?.id === this.user?.clinic?.id)
    );
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }

  isWhiteListed() {
    // Simply allow optoms at MCES & Sunbury to send patients to other team members.
    if (this.user) {
      return [MCES_CLINIC_ID, SUNBURY_CLINIC_ID].includes(this.user.clinic_id);
    }
  }
}

export class ButtonRecordProgressComponent
  implements angular.IComponentOptions {
  static selector = "buttonRecordProgress";
  static transclude = {
    sendToButtons: "?sendToButtons",
  };
  static template = require("./button-record-progress.html");
  static controller = ButtonRecordProgressController;
  static bindings = {
    canCreateInjection: "<",
    completeRecord: "&",
    createInjection: "&",
    currentState: "<",
    gotoState: "&",
    record: "<",
    showLoader: "<",
    user: "<",
    updateWorkflow: "&?",
  };
}
