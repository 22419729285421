/**
 * Import the Component styles
 */

import { ClinicService } from "app/core/services/clinic.service";
import { LetterService } from "app/core/services/letter.service";
import { PageTitleService } from "app/core/services/page-title.service";
import {
  IGlSignature,
  SignatureService,
} from "app/core/services/signature-service/signature.service";
import { AccessLock } from "../../../models/access-lock.model";
import { Clinic } from "../../../models/clinic.model";
import { IGlFormMode } from "../../../models/gl-form-mode";
import { GlThread } from "../../../models/messages.model";
import { PatientDocument } from "../../../models/patient-document.model";
import {
  GlPatientRecordWorkflowState,
  PatientRecord,
  PatientReferral,
} from "../../../models/patient-record.model";
import { User } from "../../../models/user.model";
import {
  getClinicalLateralities,
  getManagementLateralities,
} from "../../core/helpers/laterality-helper";
import { AccessLockService } from "../../core/services/access-lock.service";
import { AuthService } from "../../core/services/auth.service";
import { DocumentWindowService } from "../../core/services/document-window.service";
import {
  DocumentsService,
  GL_DOCUMENT_A_SCAN,
  GL_DOCUMENT_FIELD_LEFT,
  GL_DOCUMENT_FIELD_RIGHT,
  GlDocumentType,
  IRecordDocuments,
} from "../../core/services/documents-service/documents.service";
import { GlModelService } from "../../core/services/gl-model.service";
import { PatientClinicService } from "../../core/services/patient-clinic.service";
import { PatientDocumentService } from "../../core/services/patient-document.service";
import { PatientProcedureService } from "../../core/services/patient-procedure.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import { ProviderService } from "../../core/services/provider.service";
import {
  SegmentHelperService,
  Segments,
} from "../../core/services/segment-helper.service";
import { ThreadFacadeService } from "./../../core/services/thread-facade.service";
import "./referral.scss";
import { IOrthancDocumentMatchResults } from "../main.document-upload/services/orthanc-document/orthanc-document.service";
import { IOrthancDicomTags } from "app/core/services/orthanc-api/orthanc.models";

export interface IGlCardState {
  collapsed: boolean;
  isEditable: boolean;
  mode: IGlFormMode;
  leftEnabled: boolean;
  rightEnabled: boolean;
}

const DEFAULT_GL_CARD_STATE: IGlCardState = {
  collapsed: false,
  isEditable: true,
  mode: "edit",
  leftEnabled: true,
  rightEnabled: true,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function glCardStateFactory(state: Partial<IGlCardState>) {
  return { ...DEFAULT_GL_CARD_STATE, ...state };
}

type GlCardStates = { [key in Segments]: IGlCardState };

const DEFAULT_SEGMENT_STATES: GlCardStates = {
  notes: { ...DEFAULT_GL_CARD_STATE },
  info: { ...DEFAULT_GL_CARD_STATE },
  providers: { ...DEFAULT_GL_CARD_STATE },
  medicalHistory: { ...DEFAULT_GL_CARD_STATE },
  whatToDo: { ...DEFAULT_GL_CARD_STATE },
  lensStatus: { ...DEFAULT_GL_CARD_STATE },
  clinicalData: { ...DEFAULT_GL_CARD_STATE },
  documents: { ...DEFAULT_GL_CARD_STATE },
  dryEye: { ...DEFAULT_GL_CARD_STATE },
  anterior: { ...DEFAULT_GL_CARD_STATE },
  patientUploads: { ...DEFAULT_GL_CARD_STATE },
  gonio: { ...DEFAULT_GL_CARD_STATE },
  general: { ...DEFAULT_GL_CARD_STATE },
  vaccineStatus: { ...DEFAULT_GL_CARD_STATE },
  posterior: { ...DEFAULT_GL_CARD_STATE },
  field: { ...DEFAULT_GL_CARD_STATE },
  letters: { ...DEFAULT_GL_CARD_STATE },
  drops: { ...DEFAULT_GL_CARD_STATE },
  externalProcedures: { ...DEFAULT_GL_CARD_STATE },
  inHouseProcedures: { ...DEFAULT_GL_CARD_STATE },
  management: { ...DEFAULT_GL_CARD_STATE },
  todaysDrops: { ...DEFAULT_GL_CARD_STATE },
  dayProcedures: { ...DEFAULT_GL_CARD_STATE },
  drugs: { ...DEFAULT_GL_CARD_STATE },
  prescriptions: { ...DEFAULT_GL_CARD_STATE },
  postDilationIop: { ...DEFAULT_GL_CARD_STATE },
};

/**
 * @deprecated
 * not used from what i can see
 */
class ReferralPageController
  implements angular.IController, angular.IOnInit, angular.IOnDestroy {
  threads: GlThread[];
  user: User;
  patientId: number = this.$stateParams.patientId;
  recordId: number = this.$stateParams.recordId;
  patient: IPatientResource;
  document: angular.resource.IResource<PatientDocument>;
  records: PatientRecord[];
  rightEnabled = true;
  leftEnabled = true;
  record: PatientReferral;
  accessLockForRecord: AccessLock;
  recordDocuments: IRecordDocuments;
  segmentStates: Partial<GlCardStates> = { ...DEFAULT_SEGMENT_STATES };
  debug: boolean = this.$stateParams.debug;
  autoSaveIntervalPromise: angular.IPromise<void>;
  saveInProgress = false;
  saveAndProgressInProgress = false;
  savePatientInProgress = false;
  userSignature: IGlSignature;
  lockInProgress = false;
  reopenInProgress = false;
  disableAccessLockInProgress = false;
  updateReferralInProgress = false;

  referralClinics: Clinic[];

  docIdLeftField = GL_DOCUMENT_FIELD_LEFT;
  docIdRightField = GL_DOCUMENT_FIELD_RIGHT;
  docIdAscan = GL_DOCUMENT_A_SCAN;

  pageForm: angular.IFormController;
  patientInfoForm: angular.IFormController;

  referralLetterTemplate = require("./components/referral-letter/referral-letter.html");

  // for the document imports
  // if we narrow it down to dicom data based on study date
  orthancDocuments: IOrthancDocumentMatchResults;

  constructor(
    private $q: angular.IQService,
    private $state: angular.ui.IStateService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private AccessLockService: AccessLockService,
    private AUTO_SAVE_INTERVAL: number,
    private ClinicService: ClinicService,
    private DocumentsService: DocumentsService,
    private DocumentWindowService: DocumentWindowService,
    private GlModelService: GlModelService,
    private LetterService: LetterService,
    private PageTitleService: PageTitleService,
    private SegmentHelperService: SegmentHelperService,
    private SignatureService: SignatureService,
    private toastr: angular.toastr.IToastrService,
    public $stateParams: angular.ui.IStateParamsService,
    public AuthService: AuthService,
    public PatientClinicService: PatientClinicService,
    public PatientDocumentService: PatientDocumentService,
    public PatientProcedureService: PatientProcedureService,
    public PatientRecordService: PatientRecordService,
    public PatientService: PatientService,
    public ProviderService: ProviderService,
    public ThreadFacadeService: ThreadFacadeService
  ) {
    "ngInject";
  }

  $onInit() {
    this.GlModelService.disableGlModelDefaults();
    this.PageTitleService.set(`New Referral - GlaucoNet`);
    // this.patient = this.PatientService.get(this.patientId);
    // this.patient.$promise.then((patient) =>

    // );

    if (this.patientId) {
      this.patient = this.PatientService.get(this.patientId);
    } else {
      this.patient = new this.PatientService.patientResource();
    }

    this.SignatureService.fetchAll().then(
      ([signature] = []) => (this.userSignature = signature)
    );

    if (this.recordId) {
      const recordPromise = this.PatientRecordService.getRecordV2ForEditing(
        this.patientId,
        this.recordId
      ).then((record: PatientReferral) => {
        if (record && !record?.referral_clinic_id) {
          record.referral_clinic_id = 292; // default it to PAGS clinic;
        }
        this.record = record;

        this.setManagementLateralities(record);
        this.privateSetClinicalLateralities(record);
        // this.leftEnabled = checkSideAvailableClinicalData(record.data, "left");
        // this.rightEnabled = checkSideAvailableClinicalData(
        //   record.data,
        //   "right"
        // );
      });

      recordPromise
        .then(() => this.initalize())
        .catch((error) => {
          console.error("Record Page - Error loading details", error);
          this.toastr.error("Unable to load patient details");
          this.$state.go("main.dashboard");
        });
    }
  }

  getCurrentReferrer() {
    return this.GlModelService.getFromRecordOrHistory(
      "providers.referrer",
      this.record.data,
      this.getConsolidatedRecord()
    );
  }

  $onDestroy() {
    // clear any saved patient procedures on destroy
    this.PatientProcedureService.reset();
    this.cancelAutoSaveTimeout();
    this.GlModelService.enableGlModelDefaults();
  }

  uiCanExit() {
    if (
      this.pageForm.$dirty &&
      !this.$window.confirm(
        "There are unsaved changes, are you sure you want to exit without saving?"
      )
    ) {
      return false;
    }
    // this callback can be used to release any record locks
    return this.accessLockForRecord
      ? this.AccessLockService.clearPatientLock()
      : true;
  }

  initalize() {
    const accessLockPromise = this.AccessLockService.initPatientLock({
      recordId: this.recordId,
      onBeforeUnload: this.onBeforeUnload.bind(this),
    }).catch(() => undefined);

    const lettersPromise = this.LetterService.init(
      this.patient?.id || this.patientId,
      this.recordId
    );

    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const clinicPromise = this.ClinicService.getAll().$promise.then(
    //   (clinics) => {
    //     this.referralClinics = clinics.filter((c) => [292].includes(c.id));
    //   }
    // );

    const patientProceduresPromise =
      this.PatientProcedureService.getAllForPatient({
        patientId: this.patient?.id || this.patientId,
      });

    return this.$q
      .all([accessLockPromise, lettersPromise, patientProceduresPromise])
      .then(([accessLock]) => {
        this.accessLockForRecord = accessLock;
        this.recordDocuments = this.DocumentsService.getNamedDocuments(
          this.record.documents
        );
        this.setInitialCardStates(this.record);
        // setup autosave time
        this.setAutoSaveTimeout();
        return this.setFormPristine();
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setInitialCardStates(record: PatientRecord) {
    this.segmentStates = DEFAULT_SEGMENT_STATES;
  }

  setAllCardsToDisplayMode() {
    Object.keys(this.segmentStates).forEach((segmentName) => {
      const segment = this.segmentStates[segmentName];
      segment.mode = "display";
    });
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }

  userHasAccessLockForRecord() {
    if (this.record?.id) {
      return !!this.accessLockForRecord;
    } else {
      return true;
    }
  }

  canEditRecord() {
    if (this.record?.id) {
      return !this.recordIsSigned() && this.userHasAccessLockForRecord();
    } else {
      return true;
    }
  }

  recordIsComplete() {
    if (!this.record) {
      return true;
    }
    return this.record.status === "COMPLETE";
  }

  getCollapsed(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.collapsed;
  }
  toggleCollapsed(segment: Segments) {
    this.segmentStates[segment].collapsed =
      !this.segmentStates[segment].collapsed;
  }

  getIsEditable(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return !this.fromSameClinic() ? "false" : state.isEditable;
  }

  setIsEditable(segment: Segments, isEditable: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.isEditable = isEditable;
      if (state.isEditable) {
        // when setting isEditable to true, default to edit mode
        this.setDisplayMode(segment, "edit");
        state.collapsed = false;
      } else {
        this.setDisplayMode(segment, "display");
      }
    }
  }

  fromSameClinic() {
    if (this.record.id) {
      // this is an existing record
      return !!(
        this.record?.clinic &&
        this.user?.clinic &&
        this.record.clinic.id === this.user.clinic.id
      );
    } else {
      // this is a new record so don't worry about the same clinic check
      return true;
    }
  }

  getDisplayMode(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return !this.fromSameClinic() || this.recordIsSigned()
      ? "display"
      : state.mode;
  }

  setDisplayMode(segment: Segments, displayMode: IGlFormMode) {
    const state = this.segmentStates[segment];
    if (state) {
      state.mode = displayMode;
      state.collapsed = false;
    }
  }

  getLeftEnabled(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.leftEnabled;
  }

  setLeftEnabled(segment: Segments, enabled: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.leftEnabled = enabled;
    }
  }

  getRightEnabled(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.rightEnabled;
  }

  setRightEnabled(segment: Segments, enabled: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.rightEnabled = enabled;
    }
  }

  autoSave() {
    // authenticated
    const canContinue: boolean = this.AuthService.autoProcessCanContinue();
    // only auto save if the form has changes
    // and the session is authenticated to avoid issues
    // with spamming 401's to api
    if (this.pageForm.$dirty && canContinue) {
      this.save();
    }
  }

  save() {
    return this.$q
      .resolve()
      .then(() => {
        this.saveInProgress = true;
        return this.saveOrCreatePatient();
      })
      .then(() => {
        if (this.record.id) {
          return this.PatientRecordService.update(this.record as PatientRecord);
        } else {
          return this.PatientRecordService.createReferral(this.patient.id);
        }
      })
      .then(() => this.pageForm.$setPristine())
      .finally(() => {
        this.saveInProgress = false;
        this.setAutoSaveTimeout();
      });
  }

  forceSignRecord() {
    const confirm = this.$window.confirm(
      "There are missing values from the referral. Press cancel to review and complete these. Press OK it you  are sure you want to sing and send this referral? "
    );
    if (confirm) {
      this.signRecord();
    }
  }

  createPatientAndInitReferral() {
    return this.saveOrCreatePatient()
      .then(() => this.PatientRecordService.createReferral(this.patient.id))
      .then((referral) => {
        (referral as PatientReferral).referral_clinic_id = 292; // default to PAGS clinic;
        this.record = referral as PatientReferral;
        return this.initalize();
      });
  }

  signRecord() {
    return this.$q
      .resolve()
      .then(() => (this.saveInProgress = true))
      .then(() => this.saveOrCreatePatient())
      .then(() => {
        if (!this.record.id) {
          return this.PatientRecordService.createReferral(this.patient.id).then(
            (referral) => (this.record = referral as PatientReferral)
          );
        }
      })
      .then(() => {
        this.record.status = "COMPLETE";
        return this.PatientRecordService.sign({
          record: this.record as PatientRecord,
        });
      })
      .then(() => this.setFormPristine())
      .then(() => {
        // the record is save, so redirect back to the dashboard
        return this.$state.go("main.dashboard");
      })
      .catch((error) => {
        console.error("Error in signRecord saving record", error);
        this.toastr.error("Could not save record. Please try again");
      })
      .finally(() => (this.saveInProgress = false));
  }

  saveOrCreatePatient() {
    return this.$q
      .resolve()
      .then(() => {
        this.savePatientInProgress = true;
        if (this.patientInfoForm.$dirty && !this.patient.id) {
          return this.patient.$save();
        } else if (this.patientInfoForm.$dirty && this.patient.id) {
          return this.patient.$update();
        } else {
          return this.patient;
        }
      })
      .finally(() => (this.savePatientInProgress = false));
  }

  reopenRecord() {
    return this.$q
      .resolve()
      .then(() => {
        this.reopenInProgress = true;
        return this.PatientRecordService.reopen(this.record as PatientRecord);
      })
      .then((record) => {
        this.record = record as PatientReferral;
      })
      .finally(() => (this.reopenInProgress = false));
  }

  print() {
    this.PatientRecordService.openPrintRecordWindow({
      patientId: this.patientId,
      recordId: this.recordId,
      printOnLoad: true
    });
  }

  saveAndProgressClicked(nextWorkflowState?: GlPatientRecordWorkflowState) {
    this.record.workflow_state = nextWorkflowState;
    this.saveAndProgressInProgress = true;
    // return this.PatientRecordService.updateAndSetPractitioner(
    //   this.record,
    //   this.user
    // )
    //   .then(() => this.setFormPristine())
    //   .then(() => {
    //     // the record is save, so redirect back to the dashboard
    //     return this.$state.go("main.dashboard");
    //   })
    //   .catch((error) => {
    //     console.error("Error in saveAndProgressClicked saving record", error);
    //     this.toastr.error("Could not save record. Please try again");
    //   })
    //   .finally(() => {
    //     this.saveAndProgressInProgress = false;
    //   });
  }

  completeRecord() {
    this.record.status = "COMPLETE";
    return this.saveAndProgressClicked();
  }

  openDocumentViewer() {
    this.DocumentWindowService.openDocumentWindows(
      this.patientId,
      this.recordId
    );
  }

  getConsolidatedRecord() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  shouldShowAdminSummary() {
    return this.user.type.name === "administrator";
  }

  overrideAccessLock() {
    this.disableAccessLockInProgress = true;
    this.AccessLockService.disablePageLock({
      recordId: this.recordId,
      force: true,
    })
      .then(() => {
        return this.$state.reload();
      })
      .finally(() => (this.disableAccessLockInProgress = false));
  }

  showReferralConfirmation() {
    return (
      this.record?.id && this.user?.clinic_id === this.record.referral_clinic_id
    );
  }

  updateReferral() {
    this.$q
      .resolve()
      .then(() => (this.updateReferralInProgress = true))
      .then(() => this.PatientRecordService.referralUpdate(this.record))
      .then((referral) => (this.record = referral))
      .finally(() => (this.updateReferralInProgress = false));
  }
  // on documents ready
  onOrthancDocumentsReady(documents: IOrthancDocumentMatchResults) {
    this.orthancDocuments = documents;
  }

  // fetch from the dicom document tags
  // same as IPatientDocumentDicomData
  getOrthancDocumentByTag(tag: GlDocumentType): IOrthancDicomTags {
    // return first instance
    return this.orthancDocuments?.[tag]?.[0].tags;
  }

  private setAutoSaveTimeout() {
    this.cancelAutoSaveTimeout();
    if (this.AUTO_SAVE_INTERVAL > 0) {
      this.autoSaveIntervalPromise = this.$timeout(this.AUTO_SAVE_INTERVAL);
      this.autoSaveIntervalPromise
        .then(() => this.autoSave())
        .catch(() => {
          // If this timeout is cancelled, it throws an error. Catch all errors
          // to silence this error
        });
    }
  }

  private cancelAutoSaveTimeout() {
    if (this.autoSaveIntervalPromise) {
      this.$timeout.cancel(this.autoSaveIntervalPromise);
      this.autoSaveIntervalPromise = null;
    }
  }

  private onBeforeUnload() {
    if (this.pageForm.$dirty) {
      return "Are you sure you want to close this window and discard any changes?";
    }
  }

  // This sets the form to pristine after a short delay to allow gl-model to set
  // any defaults for fields
  private setFormPristine() {
    return this.$timeout(250).then(() => this.pageForm.$setPristine());
  }

  private defaultOtherSegmentsToEdit(selectedSegment: string) {
    Object.keys(this.segmentStates).forEach((segmentName) => {
      if (
        this.segmentStates[segmentName].mode === "edit" &&
        ![selectedSegment, "management"].includes(segmentName)
      ) {
        this.segmentStates[segmentName].mode = "display";
      }
    });
  }

  private privateSetClinicalLateralities(currentRecord: PatientRecord) {
    const { enableLeft, enableRight } = getClinicalLateralities(currentRecord);
    this.setLeftEnabled("clinicalData", enableLeft);
    this.setRightEnabled("clinicalData", enableRight);
  }

  private setManagementLateralities(currentRecord: PatientRecord) {
    const { enableLeft, enableRight } =
      getManagementLateralities(currentRecord);
    this.setLeftEnabled("management", enableLeft);
    this.setRightEnabled("management", enableRight);
  }
}

/**
 * @deprecated
 * not in use from what i can see
 */
export class ReferralPage implements angular.IComponentOptions {
  static selector = "referralPage";
  static template = require("./referral.html");
  static controller = ReferralPageController;
  static bindings = {
    user: "<",
  };
}
