import { GlPatientRecordWorkflowState } from "models/patient-record.model";

export const pipeName = "workflowState";
export function workflowStatePipe() {
  "ngInject";
  return (workflow: GlPatientRecordWorkflowState) => {
    if (!workflow) {
      return "Other";
    }
    const re = /\((.*)\)/i;
    const wfs = workflow.match(re)?.[1];
    switch (wfs) {
      case "O": {
        return "Other";
      }
      case "INJ": {
        return "Injection";
      }
      case "R": {
        return "Retina";
      }
      case "G": {
        return "Glaucoma";
      }
      case "C": {
        return "Cataract";
      }
      case "DE": {
        return "Dry Eye";
      }
      default: {
        return "Other";
      }
    }
    // console.log(workflow);
    // return startCase((workflow || "").replace(/_|-/g, " ").toLocaleLowerCase());
  };
}
