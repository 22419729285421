import { isFinite, mean, round } from "lodash";
import { IGlSide } from "models/gl-side.model";
import { IOrthancDicomTags } from "../orthanc-api/orthanc.models";

export const DICOM_TAG_ID_SOP_INSTANCE_UID = "0008,0018";
export const DICOM_TAG_ID_MODAILTY = "0008,0060";
export const DICOM_TAG_ID_SERIES_DESCRIPTION = "0008,103e";
export const DICOM_TAG_ID_PATIENT_ID = "0010,0020";
export const DICOM_TAG_ID_LATERALITY = "0020,0060";
export const DICOM_TAG_ID_IMAGE_LATERALITY = "0020,0062";
export const DICOM_TAG_DOCUMENT_TITLE = "0042,0010";

// list of dicom files we track
export const DICOM_MODALITY_ASCAN = "OAM";
export const DICOM_MODALITY_OCT = "OPT";
export const DICOM_MODALITY_DISC = "XC";
export const DICOM_MODALITY_FIELD = "OPV";
export const DICOM_MODALITY_OPHTHALMIC_PHOTO = "OP";

export const DICOM_TAG_MANUFACTURER_MODEL_NAME = "0008,1090";

// list of dicom fields we are interested in
export const DICOM_TAG_FIELD_VFI = "7717,1034";
export const DICOM_TAG_FIELD_GHT = "7717,1023";
export const DICOM_TAG_FIELD_FALSE_POS_ERRORS = "7717,1010";
export const DICOM_TAG_FIELD_MD = "7717,1016";
export const DICOM_TAG_FIELD_PSD = "7717,1018";
export const DICOM_TAG_FIELD_PSD_P_VALUE = "7717,1019";

export const DICOM_TAG_FIELD_ACD = "771b,1034";
export const DICOM_TAG_FIELD_ACD_SIDE = "771b,1008";

export class DicomTagHelper {
  static injectionName = "DicomTagHelper";

  getTagForDicomId(id: string, data: IOrthancDicomTags) {
    return data?.[id];
  }

  getTagForDicomName(tagName: string, data: IOrthancDicomTags) {
    const id = Object.keys(data).find((id) => {
      const value = this.getTagForDicomId(id, data);
      return value.Name === tagName;
    });

    return id && this.getTagForDicomId(id, data);
  }

  getValueForDicomId(id: string, data: IOrthancDicomTags) {
    const tag = this.getTagForDicomId(id, data);
    return tag?.Value;
  }

  getValueForDicomName(tagName: string, data: IOrthancDicomTags) {
    const tag = this.getTagForDicomName(tagName, data);
    return tag?.Value;
  }

  getAcd(side: IGlSide, data: IOrthancDicomTags = {}) {
    const acdValueArray = this.getValueForDicomId(DICOM_TAG_FIELD_ACD, data);
    if (Array.isArray(acdValueArray)) {
      const laterality = side === "left" ? "L" : "R";
      const acdObjForSide =
        acdValueArray.find(
          (values) =>
            laterality ===
            this.getValueForDicomId(DICOM_TAG_FIELD_ACD_SIDE, values)
        ) || {};
      // calculate the average of the values
      const acdValues = Object.keys(acdObjForSide)
        // The acd object has 2 keys that should be excluded when calculating
        // the average
        .filter((key) => !["771b,0010", "771b,1008"].includes(key))
        // Note the + which converts the string value into a number so the
        // average can be calculated
        .map((key) => +this.getValueForDicomId(key, acdObjForSide));

      const acd = round(mean(acdValues), 2);
      return isFinite(acd) ? acd : undefined;
    }
  }
}
