import * as angular from "angular";
import { IOnChangesObject, IPromise } from "angular";
import { SegmentLettersController } from "app/core/components/data/segment-letters/segment-letters";
import { SegmentPosteriorController } from "app/core/components/data/segment-posterior/segment-posterior";
import { IGlCardState } from "app/core/components/ui/card/card";
import { VisitDropHelperService } from "app/core/services/VisitDropHelper/VisitDropHelper.service";
import { AutofillExternalProcedureService } from "app/core/services/autofill-external-procedure.service/autofill-external-procedure.service";
import { AutofillService } from "app/core/services/autofill/autofill.service";
import { ClinicService } from "app/core/services/clinic.service";
import { DataService } from "app/core/services/data.service";
import { DiagnosisService } from "app/core/services/diagnosis.service";
import { IConsolidatedInjection, InjectionHelperService } from "app/core/services/injection-helper/injection-helper.service";
import { LoggingService } from "app/core/services/logging/logging.service";
import { MediaRecordingService } from "app/core/services/media-recording/media-recording.service";
import { IOrthancDicomTags } from "app/core/services/orthanc-api/orthanc.models";
import { PageTitleService } from "app/core/services/page-title.service";
import { PatientRecordHelperService } from "app/core/services/patient-record-helper/patient-record-helper.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { SubscriptionService } from "app/core/services/subscription.service";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { diff } from "json-diff";
import { cloneDeep, filter, isNil } from "lodash";
import { AccessLock } from "models/access-lock.model";
import { Clinic } from "models/clinic.model";
import { Contact } from "models/contact.model";
import {
  PatientProcedure,
  PatientProcedureDrug
} from "models/patient-procedure";
import { GlPrescription } from "models/prescription.model";
import { AudioTranscript, IAudioTranscriptTemplate } from "models/record-transcript.model";
import { Subscription } from "models/subscription.model";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { IGlFormMode } from "../../../models/gl-form-mode";
import { GlThread } from "../../../models/messages.model";
import { PatientDocument } from "../../../models/patient-document.model";
import {
  GlPatientRecordWorkflowState,
  PatientRecord,
} from "../../../models/patient-record.model";
import { GlStaff, GlUserTypeString, User } from "../../../models/user.model";
import {
  checkSideAvailableClinicalData,
  getClinicalLateralities,
  getManagementLateralities,
} from "../../core/helpers/laterality-helper";
import { getActiveReferral } from "../../core/pipes/active-referral.pipe";
import { AccessLockService } from "../../core/services/access-lock.service";
import { AuthService } from "../../core/services/auth.service";
import { ContactService } from "../../core/services/contact.service";
import {
  DocumentsService,
  GL_DOCUMENT_A_SCAN,
  GL_DOCUMENT_FIELD_LEFT,
  GL_DOCUMENT_FIELD_RIGHT,
  GlDocumentType,
  IRecordDocuments,
} from "../../core/services/documents-service/documents.service";
import { GlModelService } from "../../core/services/gl-model.service";
import { LetterService } from "../../core/services/letter.service";
import { PatientClinicService } from "../../core/services/patient-clinic.service";
import { PatientDocumentService } from "../../core/services/patient-document.service";
import { PatientProcedureService } from "../../core/services/patient-procedure.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import { ProviderService } from "../../core/services/provider.service";
import {
  SegmentHelperService,
  Segments,
} from "../../core/services/segment-helper.service";
import { VisualAcuityService } from "../../core/services/va.service";
import { IOrthancDocumentMatchResults } from "../main.document-upload/services/orthanc-document/orthanc-document.service";
import { ThreadFacadeService } from "./../../core/services/thread-facade.service";
import { GL_VIEWS } from "./components/view-manager/view-manager.component";
import "./record.scss";

export const PATIENT_RECORD_EVENT_SIGN: string = "recordPage:sign";
export const PATIENT_RECORD_EVENT_SAVE: string = "recordPage:save";
export const PATIENT_RECORD_EVENT_WORKFLOW_CHANGE: string = "recordPage:workflowChange";
export const PATIENT_RECORD_EVENT_SAVE_FROM_CHILD: string =
  "recordPage:saveFromChild";
export const PATIENT_RECORD_SET_PRISTINE: string = "recordPage:setPristine";

const DEFAULT_GL_CARD_STATE: IGlCardState = {
  collapsed: false,
  isEditable: false,
  mode: "display",
  leftEnabled: true,
  rightEnabled: true,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function glCardStateFactory(state: Partial<IGlCardState>) {
  return { ...DEFAULT_GL_CARD_STATE, ...state };
}

type GlCardStates = { [key in Segments]: IGlCardState };

const DEFAULT_SEGMENT_STATES: GlCardStates = {
  notes: { ...DEFAULT_GL_CARD_STATE },
  info: { ...DEFAULT_GL_CARD_STATE },
  providers: { ...DEFAULT_GL_CARD_STATE },
  vaccineStatus: { ...DEFAULT_GL_CARD_STATE },
  medicalHistory: { ...DEFAULT_GL_CARD_STATE },
  patientUploads: { ...DEFAULT_GL_CARD_STATE },
  whatToDo: { ...DEFAULT_GL_CARD_STATE },
  lensStatus: { ...DEFAULT_GL_CARD_STATE },
  clinicalData: { ...DEFAULT_GL_CARD_STATE },
  documents: { ...DEFAULT_GL_CARD_STATE },
  dryEye: { ...DEFAULT_GL_CARD_STATE },
  anterior: { ...DEFAULT_GL_CARD_STATE },
  gonio: { ...DEFAULT_GL_CARD_STATE },
  general: { ...DEFAULT_GL_CARD_STATE },
  posterior: { ...DEFAULT_GL_CARD_STATE },
  field: { ...DEFAULT_GL_CARD_STATE },
  letters: { ...DEFAULT_GL_CARD_STATE },
  drops: { ...DEFAULT_GL_CARD_STATE },
  externalProcedures: { ...DEFAULT_GL_CARD_STATE },
  inHouseProcedures: { ...DEFAULT_GL_CARD_STATE },
  management: { ...DEFAULT_GL_CARD_STATE },
  todaysDrops: { ...DEFAULT_GL_CARD_STATE },
  dayProcedures: { ...DEFAULT_GL_CARD_STATE },
  drugs: { ...DEFAULT_GL_CARD_STATE },
  prescriptions: { ...DEFAULT_GL_CARD_STATE },
  postDilationIop: { ...DEFAULT_GL_CARD_STATE },
};

const DEFAULT_TECHNICIAN_SEGMENT_COLLAPSED: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  clinicalData: {
    isEditable: true,
    mode: "display",
  },
  dryEye: { collapsed: true },
  anterior: { collapsed: true },
  gonio: { collapsed: true },
  posterior: { collapsed: true },
  field: { collapsed: true },
  letters: { collapsed: true },
  drops: { collapsed: true },
  externalProcedures: { collapsed: true },
  inHouseProcedures: { collapsed: true },
  management: { collapsed: true, mode: "display" },
  todaysDrops: { collapsed: true },
  patientUploads: { collapsed: true },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
  postDilationIop: { collapsed: false },
  medicalHistory: { collapsed: false },
  info: { collapsed: false },
};

const DEFAULT_ADMIN_SEGMENT_STATE: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  notes: { collapsed: true },
  info: { collapsed: false },
  providers: { collapsed: false },
  medicalHistory: { collapsed: false },
  vaccineStatus: { collapsed: false },
  whatToDo: { collapsed: true },
  lensStatus: { collapsed: true },
  clinicalData: { collapsed: true },
  documents: { collapsed: true },
  dryEye: { collapsed: true },
  anterior: { collapsed: true },
  gonio: { collapsed: true },
  general: { collapsed: false },
  posterior: { collapsed: true },
  field: { collapsed: true },
  letters: { collapsed: false },
  drops: { collapsed: true },
  externalProcedures: { collapsed: true },
  inHouseProcedures: { collapsed: true },
  management: { collapsed: true },
  todaysDrops: { collapsed: true },
  patientUploads: { collapsed: true },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
  postDilationIop: { collapsed: false },
};

const DEFAULT_OPTOMETRIST_SEGMENT_COLLAPSED: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  notes: { collapsed: false },
  info: { collapsed: false, isEditable: false },
  providers: { collapsed: true, isEditable: false },
  medicalHistory: { collapsed: false },
  whatToDo: { collapsed: false },
  lensStatus: { collapsed: false },
  clinicalData: { collapsed: false },
  documents: { collapsed: true },
  dryEye: { collapsed: false },
  anterior: { collapsed: false },
  gonio: { collapsed: false },
  general: { collapsed: false },
  vaccineStatus: { collapsed: false },
  posterior: { collapsed: false },
  field: { collapsed: false },
  letters: { collapsed: true },
  drops: { collapsed: false },
  externalProcedures: { collapsed: false },
  inHouseProcedures: { collapsed: false },
  management: { collapsed: false, mode: "edit" },
  todaysDrops: { collapsed: true },
  patientUploads: { collapsed: true },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
  postDilationIop: { collapsed: false },
};

const DEFAULT_OPHTHAL_SEGMENT_COLLAPSED: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  notes: { collapsed: false },
  info: { collapsed: false, isEditable: false },
  providers: { collapsed: true, isEditable: false },
  medicalHistory: { collapsed: false, isEditable: false },
  whatToDo: { collapsed: true },
  vaccineStatus: { collapsed: true },
  lensStatus: { collapsed: true },
  clinicalData: { collapsed: false },
  documents: { collapsed: true },
  dryEye: { collapsed: false },
  anterior: { collapsed: false },
  gonio: { collapsed: false },
  general: { collapsed: false },
  posterior: { collapsed: false },
  field: { collapsed: false },
  letters: { collapsed: false },
  drops: { collapsed: false },
  externalProcedures: { collapsed: false },
  inHouseProcedures: { collapsed: false },
  management: { collapsed: false, mode: "edit" },
  todaysDrops: { collapsed: true },
  patientUploads: { collapsed: true },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
  postDilationIop: { collapsed: false },
};

export class RecordPageController
  implements
  angular.IController,
  angular.IOnInit,
  angular.IOnDestroy,
  angular.IOnChanges {
  threads: GlThread[];
  user: User;
  patientId: number = this.$stateParams.patientId;
  recordId: number = this.$stateParams.recordId;
  virtualReviewRecordId: number = this.$stateParams["review-record"];
  goToMessages: boolean = this.$stateParams.goToMessages;
  patient: IPatientResource;
  document: angular.resource.IResource<PatientDocument>;
  records: PatientRecord[];
  contacts: Contact[];
  rightEnabled = true;
  leftEnabled = true;
  record: PatientRecord;
  accessLockForRecord: AccessLock;
  recordDocuments: IRecordDocuments;
  segmentStates: Partial<GlCardStates> = {};
  segmentLetters: SegmentLettersController;
  posteriorSegment: SegmentPosteriorController;
  debug: boolean = this.$stateParams.debug;
  autoSaveIntervalPromise: angular.IPromise<void>;
  saveInProgress = false;
  saveAndProgressInProgress = false;
  lockInProgress = false;
  reopenInProgress = false;
  disableAccessLockInProgress = false;
  patientInfo: angular.IFormController;
  recordType: "history" | "record" = this.$stateParams.type;
  docIdLeftField = GL_DOCUMENT_FIELD_LEFT;
  docIdRightField = GL_DOCUMENT_FIELD_RIGHT;
  docIdAscan = GL_DOCUMENT_A_SCAN;
  selectedConditionView: GL_VIEWS;
  patientDocuments: PatientDocument[];
  activeReferral: { url: string; expiryStatus: string; klass: string; };

  favouriteDrugs: PatientProcedureDrug[] | [];
  favouriteDrugGroups: UserFavouriteDrugGroup[] | [];

  // prescriptions and drugs
  prescriptions: GlPrescription[];
  // issue with causing infinite re-renders so this is a workaround
  prescriptionsFiltered: GlPrescription[];
  drugs: PatientProcedureDrug[];
  drugsFiltered: PatientProcedureDrug[];

  recordMessages = this.ToastrAppendix.getRecordsMessages();
  orthancDocumentMessages = this.ToastrAppendix.getOrthancDocumentMessages();

  // clinic
  clinic: Clinic;
  clinicProviders: GlStaff[];

  // record clone on init that is used for a record diff
  initialRecord: PatientRecord;
  // reference of the current recording template
  // to be passed down
  transcriptModel: IAudioTranscriptTemplate;
  currentTranscriptUploaded: boolean = false;
  transcriptUploadInProgress: boolean = false;
  // for future referece
  recordings: AudioTranscript[];

  // ref for recording transcript
  recordTranscriptRef: JQLite;

  // to pass down for the icon indicator
  subscriptionDetails: Subscription;

  // for the document imports
  // if we narrow it down to dicom data based on study date
  orthancDocuments: IOrthancDocumentMatchResults;

  constructor(
    private $anchorScroll: angular.IAnchorScrollService,
    private $location: angular.ILocationService,
    private $q: angular.IQService,
    private $state: angular.ui.IStateService,
    private $timeout: angular.ITimeoutService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $window: angular.IWindowService,
    private AccessLockService: AccessLockService,
    private AUTO_SAVE_INTERVAL: number,
    private DataService: DataService,
    private DocumentsService: DocumentsService,
    private GlModelService: GlModelService,
    private LetterService: LetterService,
    private PageTitleService: PageTitleService,
    private ContactService: ContactService,
    private SegmentHelperService: SegmentHelperService,
    private toastr: angular.toastr.IToastrService,
    private VisualAcuityService: VisualAcuityService,
    private PrescriptionsService: PrescriptionsService,
    private $scope: angular.IScope,
    public $stateParams: angular.ui.IStateParamsService,
    public AuthService: AuthService,
    public PatientClinicService: PatientClinicService,
    public PatientDocumentService: PatientDocumentService,
    public PatientProcedureService: PatientProcedureService,
    public PatientRecordService: PatientRecordService,
    public PatientService: PatientService,
    public ProviderService: ProviderService,
    public ThreadFacadeService: ThreadFacadeService,
    public UserFavouriteDrugsService: UserFavouriteDrugsService,
    public VisitDropHelperService: VisitDropHelperService,
    public ToastrAppendix: ToastrAppendix,
    public DiagnosisService: DiagnosisService,
    public AutofillService: AutofillService,
    public LoggingService: LoggingService,
    private PatientRecordHelperService: PatientRecordHelperService,
    private AutofillExternalProcedureService: AutofillExternalProcedureService,
    public ClinicService: ClinicService,
    public MediaRecordingService: MediaRecordingService,
    private SubscriptionService: SubscriptionService,
    private InjectionHelperService: InjectionHelperService,
  ) {
    "ngInject";
  }

  $onInit() {
    const patientPromise = this.updatePatientDetails();

    // subscription
    const patientSubscriptionPromise = this.updatePatientSubscriptionDetails(this.patientId);

    // refetch user
    const userPromise = this.AuthService.getUser();

    const messagePromise = this.ThreadFacadeService.init(
      this.recordId,
      0,
      0,
      1
    );

    const getRecordPromise = this.PatientRecordService.getRecordV2ForEditing(
      this.patientId,
      this.recordId
    );
    const getRecordHistoryPromise =
      this.PatientRecordService.getRecordHistoryForUser(this.patientId);
    const accessLockPromise = this.AccessLockService.initPatientLock({
      recordId: this.recordId,
      onBeforeUnload: this.onBeforeUnload.bind(this),
    }).catch(() => undefined);

    const patientProceduresPromise =
      this.PatientProcedureService.getAllForPatient({
        patientId: this.patientId,
      });
    const patientClinicsPromise = this.PatientClinicService.initForPatient({
      patientId: this.patientId,
    });
    const clinicProvidersPromise = this.updateProvidersForUserClinic(this.user.clinic);
    const lettersPromise = this.LetterService.init(
      this.patientId,
      this.recordId
    );

    const patientDocPromise = this.updateDocuments();
    const patientContactsPromise = this.getPatientContacts();

    // prescriptions
    const patientPrescriptionsPromise = this.updatePatientPrescriptions(
      this.patientId
    );

    const patientDrugsPromise = this.updatePatientDrugs(this.patientId);

    const userFavouriteDrugsPromise = this.updatePrescriberFavouriteDrugs(
      this.user?.id
    );
    const userFavouriteDrugGroupsPromise =
      this.updatePrescriberFavouriteDrugGroups(this.user?.id);

    // can be renabled to view record
    // const recordTranscriptsPromise =
    //   this.updateRecordingTranscripts(this.patientId, this.recordId);

    this.$q
      .all([
        patientPromise,
        getRecordPromise,
        getRecordHistoryPromise,
        accessLockPromise,
        userPromise,
        patientClinicsPromise,
        clinicProvidersPromise,
        patientDocPromise,
        patientContactsPromise,
        patientProceduresPromise,
        lettersPromise,
        messagePromise,
        patientPrescriptionsPromise,
        patientDrugsPromise,
        userFavouriteDrugsPromise,
        userFavouriteDrugGroupsPromise,
        patientSubscriptionPromise,
        // recordTranscriptsPromise
      ])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(([patient, record, recordHistory, accessLock, user, patientClinics, clinicProviders]) => {

        // move patient promise here to allow syncing with orthanc api
        patient.$promise.then((patient) => {
          this.PageTitleService.set(
            `${patient.data.first_name} ${patient.data.last_name} - Edit - GlaucoNet`
          );

          // depending on if they have consented - start the recording
          // must also be unsigned and can edit the record
          if (this.patientConsentsToRecording() && this.canEditRecord()) {
            this.startCurrentRecording();
          }
        });

        // assign 
        this.record = record;
        // clone for reference
        this.initialRecord = cloneDeep(record);
        this.accessLockForRecord = accessLock;

        // legacy scans
        // but we dont need them for DICOM scans since 
        // thats fetched from the APi
        this.recordDocuments = this.DocumentsService.getNamedDocuments(
          this.record.documents
        );

        // current auth user
        this.user = user;
        this.clinicProviders = clinicProviders;
        this.setInitialCardStates(record);
        const recordHistoryExcludingCurrentRecord = recordHistory.filter(
          (r) => r.id !== this.recordId
        );
        this.records = recordHistoryExcludingCurrentRecord;
        this.GlModelService.setRecordHistory(
          recordHistoryExcludingCurrentRecord
        );
        this.activeReferral = getActiveReferral(
          this.records,
          this.patientDocuments
        );

        this.setManagementLateralities(recordHistory, record);
        this.privateSetClinicalLateralities(recordHistory, record);

        this.setAutoSaveTimeout();
        return this.setFormPristine();
      })
      .catch((error) => {
        console.error("Record Page - Error loading details", error);
        this.toastr.error("Unable to load patient details");
        this.$state.go("main.dashboard");
      })
      .finally(() => {
        //
        if (this.goToMessages) {
          // Scroll to messages
          this.$location.hash("messages");
          this.$anchorScroll();
        }
      });

    // LISTENERS
    // set pristine if needed
    this.$scope.$on(
      PATIENT_RECORD_SET_PRISTINE,
      (event: any, recordId: number) => {
        if (this.record.id === recordId && this?.patientInfo) {
          this.patientInfo?.$setPristine();
        }
      }
    );
  }


  $onChanges(changes: IOnChangesObject): void {
    if ((changes.drugs || changes.record) && this.drugs && this.record) {
      this.drugsFiltered = this.drugs.filter(
        (d: PatientProcedureDrug) => d.record_id === this.record.id
      );
    }
    if (
      (changes.prescriptions || changes.record) &&
      this.prescriptions &&
      this.record
    ) {
      this.prescriptionsFiltered = this.prescriptions.filter(
        (p: GlPrescription) => p.record_id === this.record.id
      );
    }
  }

  updatePatientDetails() {
    const p = this.PatientService.get(this.patientId);
    p.$promise.then(() => {
      this.patient = p;
    });
    return p;
  }

  getCurrentReferrer() {
    return this.GlModelService.getFromRecordOrHistory(
      "providers.referrer",
      this.record.data,
      this.getConsolidatedRecord()
    );
  }

  // update uploaded documents
  // fetches based on documents that are linked to the patient id
  updateDocuments() {
    return this.PatientDocumentService.getAllForPatient(this.patientId).then(
      (documents) => {
        this.patientDocuments = documents.filter(
          (d) => d.patient_record_id == null && d.data.field === "generic"
        );
        this.activeReferral = getActiveReferral(
          this.records,
          this.patientDocuments
        );
      }
    );
  }

  $onDestroy() {
    // clear any saved patient procedures on destroy
    this.PrescriptionsService.reset();
    this.PatientProcedureService.reset();
    this.PatientRecordHelperService.resetChangesStack();
    this.AutofillExternalProcedureService.resetTemporaryProcedures();
    this.cancelAutoSaveTimeout();
  }

  uiCanExit() {
    if (
      this.patientInfo?.$dirty &&
      !this.$window.confirm(
        "There are unsaved changes, are you sure you want to exit without saving?"
      )
    ) {
      return false;
    }

    // if there are any current recordings happening, stop the recording
    // this wont interrupt the upload on sign process as that will
    // only run if its clicked 
    if (this.isRecording()) {
      this.stopCurrentRecording();
    }

    // this callback can be used to release any record locks
    return this.accessLockForRecord
      ? this.AccessLockService.clearPatientLock()
      : true;
  }

  setInitialCardStates(record: PatientRecord) {
    let segmentDefaultsForCurrentUser = DEFAULT_TECHNICIAN_SEGMENT_COLLAPSED;
    if (this.user.type.name === "ophthalmologist") {
      segmentDefaultsForCurrentUser = DEFAULT_OPHTHAL_SEGMENT_COLLAPSED;
    } else if (this.user.type.name === "optometrist") {
      segmentDefaultsForCurrentUser = DEFAULT_OPTOMETRIST_SEGMENT_COLLAPSED;
    } else if (this.user.type.name === "administrator") {
      segmentDefaultsForCurrentUser = DEFAULT_ADMIN_SEGMENT_STATE;
    }
    // for each segment work out what the current state should be
    this.segmentStates = Object.keys(DEFAULT_SEGMENT_STATES).reduce(
      (segmentState, segment: Segments) => {
        // check if this record has data for the given segment
        const recordHasDataForSegment =
          this.SegmentHelperService.recordHasSegment(record.data, segment);
        const collapsed = recordHasDataForSegment
          ? false
          : segmentDefaultsForCurrentUser[segment]?.collapsed ?? false;
        // the segment state is a combination of
        // the default segment state
        // the segmentDefaults for the current user type
        // and if there is any data in the record for the current segment
        segmentState[segment] = {
          ...DEFAULT_SEGMENT_STATES[segment],
          ...segmentDefaultsForCurrentUser[segment],
          collapsed,
        };

        // edge case for segments, else default to the usual
        let canEdit: boolean = this.canEditRecord();
        if (segment === "postDilationIop") {
          canEdit = this.canEditRecordSegment(segment);
        }

        // if you can edit record set to this
        if (canEdit) {
          segmentState[segment] = {
            ...segmentState[segment],
            isEditable: false, //recordHasDataForSegment
          };
        } else {
          // otherwise display only
          segmentState[segment] = {
            ...segmentState[segment],
            isEditable: false,
            mode: "display",
          };
        }

        return segmentState;
      },
      {}
    );
  }

  setAllCardsToDisplayMode() {
    Object.keys(this.segmentStates).forEach((segmentName) => {
      const segment = this.segmentStates[segmentName];
      segment.mode = "display";
    });
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }

  userHasAccessLockForRecord() {
    return !!this.accessLockForRecord;
  }

  canEditRecord() {
    return !this.recordIsSigned() && this.userHasAccessLockForRecord();
  }

  // segment independent ones
  canEditRecordSegment(segment: Segments) {
    // specifics
    if (segment === "postDilationIop") {
      return !this.isDilationRecordSigned();
    }
    // else check defaults
    return this.canEditRecord();
  }

  recordIsComplete() {
    if (!this.record) {
      return true;
    }
    return this.record.status === "COMPLETE";
  }

  getCollapsed(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.collapsed;
  }

  toggleCollapsed(segment: Segments) {
    this.segmentStates[segment].collapsed =
      !this.segmentStates[segment].collapsed;
  }

  getIsEditable(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    // add extra workflow if segment is post dilation iop
    // if not signed can edit
    if (segment === "postDilationIop") {
      // not from same clinic or is already signed? cant edit
      return !this.fromSameClinic() || this.isDilationRecordSigned()
        ? "false"
        : state.isEditable;
    }

    // otherwise it works as usual
    return !this.fromSameClinic() ? "false" : state.isEditable;
  }

  isDilationRecordSigned() {
    return this.record?.dilation_review?.data_status === "SIGNED";
  }

  getPatientContacts() {
    return this.ContactService.getAllPatientContacts(this.patientId).then(
      (c) => {
        this.contacts = c;
      }
    );
  }

  setIsEditable(segment: Segments, isEditable: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.isEditable = isEditable;
      if (state.isEditable) {
        // when setting isEditable to true, default to edit mode
        this.setDisplayMode(segment, "edit");
        state.collapsed = false;
      } else {
        this.setDisplayMode(segment, "display");
      }
    }
  }

  canCreateLetter() {
    return (
      this.user.type.name === "optometrist" ||
      this.user.type.name === "ophthalmologist"
    );
  }

  openSegment(selectedView: GL_VIEWS) {
    this.selectedConditionView = selectedView;
    this.DataService.selectedCondition = selectedView;
  }

  fromSameClinic() {
    return !!(
      this.record &&
      this.user &&
      this.record.clinic?.id === this.user.clinic.id
    );
  }

  getDisplayMode(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    // segment specific
    if (segment === "postDilationIop") {
      return !this.fromSameClinic() || this.isDilationRecordSigned()
        ? "display"
        : state.mode;
    }

    // regular
    return !this.fromSameClinic() || this.recordIsSigned()
      ? "display"
      : state.mode;
  }

  setDisplayMode(segment: Segments, displayMode: IGlFormMode) {
    const state = this.segmentStates[segment];
    if (state) {
      state.mode = displayMode;
      state.collapsed = false;
      // set all the other modes to display
      if (displayMode === "edit") {
        this.defaultOtherSegmentsToEdit(segment);
      }
    }
  }

  getLeftEnabled(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.leftEnabled;
  }

  setLeftEnabled(segment: Segments, enabled: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.leftEnabled = enabled;
    }
  }

  getRightEnabled(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.rightEnabled;
  }

  setRightEnabled(segment: Segments, enabled: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.rightEnabled = enabled;
    }
  }

  autoSave() {
    // authenticated
    const canContinue: boolean = this.AuthService.autoProcessCanContinue();
    // only auto save if the form has changes
    // and the session is authenticated to avoid issues
    // with spamming 401's to api
    if (this.patientInfo.$dirty && !this.saveInProgress && canContinue) {
      this.save(true);
    }
  }

  // one is whether to prompt or not to avoid spam
  // the other is to detect a manual trigger
  save(prompt: boolean = true, manual: boolean = false) {
    /**
     * ! SAVING ISSUE [HIGH PRIORITY]
     * 
     * i suspect the following:
     * 
     * a transient bug occurs where the user saves/ an autosave is performed
     * then the page is redirected
     * the reference is cleaned out by gl-model or cleanDeep, that means an empty
     * reference is saved
     * 
     * we could solve this by creating a clone of the record to use 
     * 
     * OR
     * 
     * there might be a case where if two saves are occuring at
     * the same time, there could be a secario where
     * it silently errors out and does not retain the data
     * 
     * OR 
     * 
     * it could be a problem with cleanDeep where it unintentionally
     * cleans all the data because its empty?
    */

    // create a record reference of the current state you want saved
    // as early as possible
    const recordClone: PatientRecord = cloneDeep(this.record);

    // otherwise continue
    if (recordClone?.id) {
      // then save
      this.saveInProgress = true;
      // resetting autosave timeout before it saves
      // minimising conflict between autosave <-> save and 
      // having issues where data is overwritten (i.e. lost)
      this.setAutoSaveTimeout();

      this.$q
        .resolve()
        .then(() => {
          // if triggered manually, save transcript
          if (manual) {
            this.stopAndUploadCurrentTranscript();
          }
        })
        .then(() => {
          /* 
            ! PROBLEM SECTION

            most of the time it saves without any errors
            what i believe is happening is that its saving an empty reference and
            theres no exit guard to prevent that from happening

            also explains reason why record data is empty but letters are saved
            it silenty does so
          */
          return this.PatientRecordService.updateAndSetPractitioner(
            recordClone,
            this.user
          );
        })
        .then(() => {
          // save letters the controller exists
          if (this.segmentLetters) {
            this.segmentLetters.saveAll();
          }
        })
        .then(() => {
          // only pristine if all were successful
          if (prompt) {
            this.toastr.success("Successfully saved record!");
          }
          this.patientInfo?.$setPristine();
        })
        .catch((err: any) => {
          console.error(err);
          this.toastr.error(this.recordMessages.error.save.error_try_again);
        })
        .finally(() => {
          this.saveInProgress = false;
          this.setAutoSaveTimeout();
        });
    }
  }

  signRecord(skipOphthalReview: boolean = false, proxyUser?: GlStaff) {
    // emit event to set all autofill forms to prisitne
    this.$scope.$broadcast(PATIENT_RECORD_EVENT_SIGN);
    // confirm all autofills to avoid any issues
    this.AutofillService.confirmAllAutofills();

    // this.lockInProgress = true;

    return this.$q
      .resolve()
      // TEMP: save patient data before signing whilst bug is being fixed
      .then(() => this.save(false, true))
      .then(() => {
        if (this.user.type.name === "optometrist") {
          // skipOphthalReview can be optionally set to skip automatically
          // creating a virtual review when an optometrist signs a record.  If a
          // patient is not subscribed, then this is set to true. ie: Patients
          // that are not subscribed do not have a virtual review created. If a
          // patient IS subscribed, then the Optom can optionally choose to skip
          // the virtual review. In this case skipOphthalReview is set to true
          // through the UI
          return {
            skipOphthalReview:
              (skipOphthalReview ?? false) || !this.patient.subscribed,
          };
        } else if (this.user.type.name === 'ophthalmologist') {
          /*
            if its an ophthal signing we can skip the review
          */
          skipOphthalReview = true;
          return { skipOphthalReview };
        } else {
          /*
            otherwise at this stage its most likely
            a technician or admin (or someone else)

            and there is a chance we have to proxy sign on behalf

            so if its an ophthal we are proxying as a VR can be skipped
            otherwise it depends on whether its a force override or patient is subscribed
          */
          skipOphthalReview = proxyUser?.type?.name !== 'ophthalmologist'
            ? (skipOphthalReview ?? false) || !this.patient?.subscribed
            : true;
          return { skipOphthalReview, proxyUser };
        }
      })
      .then(
        ({
          skipOphthalReview,
          // ? this looks like its not used, maybe we can delete?
          virtualReviewComment,
          proxyUser,
        }: {
          skipOphthalReview: boolean;
          virtualReviewComment?: string;
          proxyUser?: GlStaff;
        }) => {
          // if a comment exists, add it in,
          if (virtualReviewComment) {
            this.record.data.management.reason_for_virtual_review =
              virtualReviewComment;
          }
          this.lockInProgress = true;
          return this.PatientRecordService.sign({
            record: this.record,
            skipOphthalReview,
            proxyUser
          });
        }
      )
      .then((record: PatientRecord) => {
        this.toastr.success("Successfully signed record!");
        // if an Optometrist, close the record as well
        if (this.shouldAutomaticallyCloseRecordAfterSigning()) {
          return this.completeRecord();
        }

        this.record = record;
        this.patientInfo?.$setPristine();
        this.setAllCardsToDisplayMode();
      })
      .catch(() => {
        this.toastr.error(this.recordMessages.error.sign.error_try_again);
      })
      .finally(() => {
        this.lockInProgress = false;
      });
  }

  // clearcataractform
  cleanCataractForm() {
    const hasDiagnosis: boolean =
      this.DiagnosisService.diagnosisIncludesDisease(
        this?.record?.data?.management?.diagnosis_array,
        "cataract",
        "both"
      );
    const cataractFormExists: boolean = !isNil(
      this?.record?.cataract_consent_form
    );

    // if no cataract diagnosis exsists and cataract form is found
    if (!hasDiagnosis && cataractFormExists) {
      // delete cataract form
      return this.PatientRecordService.delete(
        this?.record?.cataract_consent_form
      )
        .then(() => {
          this.toastr.success("Successfully cleared cataract form");
        })
        .catch((error) => {
          console.error("Unable to delete record", error);
          return this.toastr.error("Unable to delete the unused cataract form");
        });
    }
  }

  showOptomVirtualReviewModal(
    skipOphthalReview: boolean = false
  ): IPromise<{ skipOphthalReview: boolean; }> {
    if (
      this.user.type.name === "optometrist" &&
      !skipOphthalReview //&&
      // this.patient.subscribed
    ) {
      return this.$uibModal.open({
        component: "gl-virtual-review-confirmation",
      }).result as IPromise<{ skipOphthalReview: boolean; }>;
    } else {
      return this.$q.resolve({ skipOphthalReview });
    }
  }

  reopenRecord() {
    this.reopenInProgress = true;
    this.PatientRecordService.reopen(this.record)
      .then((record) => {
        this.record = record;
        this.toastr.success("Successfully re-opened record!");
      })
      .catch(() => {
        this.toastr.error(this.recordMessages.error.re_open.error_try_again);
      })
      .finally(() => (this.reopenInProgress = false));
  }

  print({ adminNotes = false }: { adminNotes?: boolean; }) {
    this.PatientRecordService.openPrintRecordWindow({
      patientId: this.patientId,
      recordId: this.recordId,
      printOnLoad: true,
      printWithAdminNotes: adminNotes
    });
  }

  setState(state: string, record: PatientRecord) {
    this.saveAndProgressInProgress = true;
    return this.PatientRecordService.updateWorkflow(state, record)
      .then(() => {
        this.setFormPristine();
        this.toastr.success("Record state successfully changed!");
      })
      .catch((error) => {
        console.error("Unable to change state", error);
        return this.toastr.error(
          this.recordMessages.error.state.error_try_again
        );
      })
      .finally(() => (this.saveAndProgressInProgress = false));
  }

  saveAndProgressClicked(nextWorkflowState?: GlPatientRecordWorkflowState) {
    this.record.workflow_state = nextWorkflowState;
    this.saveAndProgressInProgress = true;

    // original here
    return (
      this.PatientRecordService.updateAndSetPractitioner(this.record, this.user)
        .then(() => this.stopAndUploadCurrentTranscript())
        // after that pristine everything
        .then(() => this.setFormPristine())
        .then(() => {
          // the record is save, so redirect back to the dashboard
          this.toastr.success(
            "Successfully saved and closed record, re-directing to dashboard..."
          );
          return this.$state.go("main.dashboard");
        })
        .catch((error) => {
          console.error("Error in saveAndProgressClicked saving record", error);
          this.toastr.error(this.recordMessages.error.save.error_try_again);
        })
        .finally(() => {
          this.saveAndProgressInProgress = false;
        })
    );
  }

  completeRecord() {
    this.record.status = "COMPLETE";
    if (this.record?.dilation_review) {
      this.record.dilation_review.status = "COMPLETE";
    }
    return this.saveAndProgressClicked();
  }

  // same but a mix of complete and save for dilation record
  completeAndSaveDilationRecord() {
    // dont do anything if no review
    if (isNil(this.record?.dilation_review)) {
      return;
    }

    // complete record
    this.record.dilation_review.status = "COMPLETE";

    // else return normally
    return this.PatientRecordService.updateAndSetPractitioner(
      this.record.dilation_review,
      this.user
    );
  }

  getConsolidatedRecord() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  shouldShowAdminSummary() {
    return this.user.type.name === "administrator";
  }

  overrideAccessLock() {
    this.disableAccessLockInProgress = true;
    this.AccessLockService.disablePageLock({
      recordId: this.recordId,
      force: true,
    })
      .then(() => {
        this.toastr.success("Successfully overriden access lock!");
        return this.$state.reload();
      })
      .catch(() => {
        this.toastr.error(
          this.recordMessages.error.access_lock.override.error_try_again
        );
      })
      .finally(() => (this.disableAccessLockInProgress = false));
  }



  docNameForDisplay(name: string) {
    if (!name) {
      return;
    }
    if (name.length > 50) {
      return name.slice(0, 50) + "...";
    } else {
      return name;
    }
  }

  isNewRecord() {
    return (
      this.record.updated_at === this.record.created_at ||
      (!checkSideAvailableClinicalData(this.record, "left") &&
        !checkSideAvailableClinicalData(this.record, "right"))
    );
  }

  // update prescriptions
  updatePatientPrescriptions(patientId: number) {
    if (patientId !== undefined) {
      return this.PrescriptionsService.fetchPrescriptions(patientId).then(
        (p) => {
          this.prescriptions = p ?? [];
          this.prescriptionsFiltered = this.prescriptions.filter(
            (p: GlPrescription) => p.record_id === this.recordId
          );
          return p ?? [];
        }
      );
    }
  }

  // update for the drugs
  // currently its filtered but in future there might be a way to clean this
  updatePatientDrugs(patientId: number) {
    if (patientId !== undefined) {
      return this.PatientProcedureService.getAllForPatient({
        patientId: patientId,
      }).then((procedures: PatientProcedure[]) => {
        // we just call since after promise its updated
        this.drugs = procedures?.filter(
          (p) => p.type === "drugs"
        ) as PatientProcedureDrug[];

        this.drugsFiltered = this.drugs.filter(
          (d: PatientProcedureDrug) => d.record_id === this?.recordId
        );
      });
    }
  }

  // FAVOURITE DRUGS
  updatePrescriberFavouriteDrugs(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugs(
        userId
      ).then((favourites: PatientProcedureDrug[]) => {
        this.favouriteDrugs = favourites;
        return favourites;
      });
    }
  }

  updatePrescriberFavouriteDrugGroups(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugGroups(
        userId
      ).then((groups: UserFavouriteDrugGroup[]) => {
        this.favouriteDrugGroups = groups;
        return groups;
      });
    }
  }

  updatePrescriberFavouritesCombinedHandler(userId: number) {
    this.updatePrescriberFavouriteDrugs(userId);
    this.updatePrescriberFavouriteDrugGroups(userId);
  }

  // DILATION DROPS RELATED
  getActiveDilationDrops() {
    return this.VisitDropHelperService.getActiveDilationDrops(
      this.record?.data?.visit_drops ?? []
    );
  }
  // providers
  updateProvidersForUserClinic(clinic: Clinic) {
    return this.ClinicService.getProvidersForClinic(clinic)
      .then((providers) => {
        this.clinicProviders = providers;
        return providers;
      });
  }

  // is curr user allowed to play with this feature?
  recordingWhitelisted() {
    return this.AuthService.isWhitelistedForRecordings();
  }

  // recording?
  isRecording() {
    return this.MediaRecordingService.isRecording();
  }

  updateRecordingTranscripts(patientId: number, recordId: number) {
    if ([patientId, recordId].every((k) => !isNil(k))) {
      return this.MediaRecordingService.getRecordings({
        patientId,
        recordId,
      }).then((recordings: AudioTranscript[]) => {
        // we just call since after promise its updated
        this.recordings = recordings;
      });
    }
  }

  uploadTranscript(transcript: IAudioTranscriptTemplate) {
    // since most of the time
    // upload only if confirmed tehre is a recording
    if (isNil(transcript?.recording)) {
      return this.toastr.info('No existing recording found, skipping upload...');
    }

    // if already uploaded, ignore
    if (this.currentTranscriptUploaded) {
      return this.toastr.info('Current transcript is already uploaded, skipping upload...');
    }

    // start upload
    this.transcriptUploadInProgress = true;

    this.MediaRecordingService.uploadLatestRecording({
      transcript,
      patient: this.patient,
      recordId: this.record.id,
      // get the data diff
      // see: https://www.npmjs.com/package/json-diff
      recordDataDiff: diff(this.initialRecord.data, this.record.data),
    })
      .then(() => {
        this.toastr.success('Successfully uploaded transcript!');
        // set transcript to be uploaded
        this.currentTranscriptUploaded = true;
        // TODO: update existing transcripts
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error('An error has occured, please try again.');
      })
      .finally(() => {
        this.transcriptUploadInProgress = false;
      });
  }

  // patient has consent?
  patientConsentsToRecording() {
    return this?.patient?.data?.consent_to_record;
  }

  // calls on the ref to stop the recording
  startCurrentRecording() {
    const ref: any = angular.element(this.recordTranscriptRef);
    // if not recording - start the recording
    if (ref && !this.isRecording()) {
      ref?.[0]?.startRecording();
    }
  }

  // calls on the ref to stop the recording
  stopCurrentRecording() {
    const ref: any = angular.element(this.recordTranscriptRef);
    if (ref) {
      ref?.[0]?.stopRecording();
    }
  }

  // stop and upload the current transcript
  stopAndUploadCurrentTranscript() {
    // no model, continue
    if (isNil(this.transcriptModel)) {
      return;
    }

    // no ref found, dont bother
    if (isNil(this.recordTranscriptRef)) {
      return;
    }

    // otherwise try to stop and save 
    this.$q
      .resolve()
      .then(() => {
        // if recording, stop the recording
        if (this.isRecording()) {
          return this.stopCurrentRecording();
        }
      })
      .then(() => {
        // set a delay before continuing to allow
        // everything to process
        this.$timeout(100)
          .then(() => {
            // if not uploaded, upload model
            if (!this.currentTranscriptUploaded) {
              return this.uploadTranscript(this.transcriptModel);
            }
          });
      })
      .catch((err) => {
        console.error(err);
        return this.toastr.error('An error had occured, will not save recording');
      });
  }

  updatePatientSubscriptionDetails(patient_id: number) {
    this.SubscriptionService.get({ patient_id })
      .then((subscription) => {
        this.subscriptionDetails = { ...subscription };
      });
  }


  // can create injection
  // logic is just simplified compared to above
  canCreateInjection(): boolean {
    const injections: IConsolidatedInjection[] = this.PatientProcedureService.getConsolidatedInjections() ?? [];
    return this.InjectionHelperService.canCreateInjection(injections);
  }

  // same as above but takes input
  canCreateInjectionFrom(injections: IConsolidatedInjection[]): boolean {
    return this.InjectionHelperService.canCreateInjection(injections);
  }

  // determines based on a certain set of criteria
  // whether the user can create an injection
  // also sorts out by simple prompts
  handleCreateInjection() {
    // get injections 
    const injections: IConsolidatedInjection[] = this.PatientProcedureService.getConsolidatedInjections() ?? [];
    // no injections? return
    if (!injections?.length) {
      return this.toastr.error('No injections to create a record from found.');
    }

    // valid injections?
    const nonCompletedInjections: IConsolidatedInjection[] =
      filter(
        injections,
        (g) => !this.InjectionHelperService.injectionIsCompleted(g?.injections?.[0])
      );
    // if there arent any that arent completed
    if (!nonCompletedInjections?.length) {
      return this.toastr.error('No continuing injections to create a record from found.');
    }

    // else continue to check
    // currentTotal = repeatCount
    const injectionsAtCycleMax: IConsolidatedInjection[] =
      filter(
        injections,
        (g) => this.InjectionHelperService.injectionGroupAtMaxRepeatCount(g)
      );
    // currentTotal + 1 = repeatCount
    const injectionsAtCycleOverflow: IConsolidatedInjection[] =
      filter(
        injections,
        (g) => this.InjectionHelperService.injectionGroupExceedsRepeatCount(g)
      );

    // CASE 1: injections at cycle overflow and amount is the same as current
    // injeciton length, immediately show the error
    if (injections.length === injectionsAtCycleOverflow.length) {
      return this.$window.alert(
        `All injections have a cycle count that exceeds their repeat count, please create a review or reset the cycle.`
      );
    }

    // CASE 2: any other potential warnings?
    if (injectionsAtCycleMax.length || injectionsAtCycleOverflow.length) {
      // open modal to get user confirmation
      return this.$uibModal.open({
        component: "injection-create-warning",
        keyboard: false,
        backdrop: 'static',
        resolve: {
          injectionsAtCycleMax: () => injectionsAtCycleMax,
          injectionsAtCycleOverflow: () => injectionsAtCycleOverflow,
          noInjections: () => !injections?.length
        }
      }).result
        .then(({ create }) => {
          if (create) {
            this._createInjection();
          } else {
            return this.toastr.error('Injection record creation cancelled.');
          }
        })
        .catch(() => {
          // surpress err
        });
    } else {
      // CASE 3: allow user to create injection, no problems detected
      this._createInjection();
    }
  }

  // on documents ready
  onOrthancDocumentsReady(documents: IOrthancDocumentMatchResults) {
    this.orthancDocuments = documents;
  }

  // fetch from the dicom document tags
  // same as IPatientDocumentDicomData
  getOrthancDocumentByTag(tag: GlDocumentType): IOrthancDicomTags {
    // return first instance
    return this.orthancDocuments?.[tag]?.[0].tags;
  }

  // user is of type
  // user is also set in auth service
  userIs(type: GlUserTypeString) {
    return this.AuthService.userIs(type);
  }

  // helper func to create injection
  private _createInjection() {
    this.$q
      .resolve()
      .then(() => {
        this.saveAndProgressInProgress = true;
        const params: any = {
          type: "procedure",
          workflowState: "Arrived (INJ)",
        };
        if (this.record.appointment_id) {
          params.appointment_id = this.record.appointment_id;
        }
        return this.PatientRecordService.create(this.patientId, params);
      })
      .then(() => {
        return this.toastr.success("New Injection Created");
      })
      // .then(() => {
      //   return this.saveAndProgressClicked();
      // })
      .catch(() => {
        return this.toastr.error("Unable to create an injection");
      })
      .finally(() => (this.saveAndProgressInProgress = false));
  }


  private setAutoSaveTimeout() {
    this.cancelAutoSaveTimeout();
    if (this.AUTO_SAVE_INTERVAL > 0) {
      this.autoSaveIntervalPromise = this.$timeout(this.AUTO_SAVE_INTERVAL);
      this.autoSaveIntervalPromise
        .then(() => this.autoSave())
        .catch(() => {
          // If this timeout is cancelled, it throws an error. Catch all errors
          // to silence this error
        });
    }
  }

  private cancelAutoSaveTimeout() {
    if (this.autoSaveIntervalPromise) {
      this.$timeout.cancel(this.autoSaveIntervalPromise);
      this.autoSaveIntervalPromise = null;
    }
  }

  private onBeforeUnload() {
    if (this?.patientInfo?.$dirty) {
      return "Are you sure you want to close this window and discard any changes?";
    }
  }

  // This sets the form to pristine after a short delay to allow gl-model to set
  // any defaults for fields
  private setFormPristine() {
    return this.$timeout(250).then(() => this.patientInfo?.$setPristine());
  }

  private defaultOtherSegmentsToEdit(selectedSegment: string) {
    Object.keys(this.segmentStates).forEach((segmentName) => {
      if (
        this.segmentStates[segmentName].mode === "edit" &&
        ![selectedSegment, "management"].includes(segmentName)
      ) {
        this.segmentStates[segmentName].mode = "display";
      }
    });
  }

  private shouldAutomaticallyCloseRecordAfterSigning() {
    const MCES_CLINIC_ID = 50;
    const SUNBURY_CLINIC_ID = 2;
    return (
      this.user.type.name === "optometrist" &&
      ![MCES_CLINIC_ID, SUNBURY_CLINIC_ID].includes(this.user.clinic_id)
    );
  }

  private privateSetClinicalLateralities(
    records: PatientRecord[],
    currentRecord: PatientRecord
  ) {
    if (
      (records.length === 1 && records[0]?.data === null) ||
      this.isNewRecord()
    ) {
      //if first record for patient then leave defaults.
      return;
    }
    const { enableLeft, enableRight } = getClinicalLateralities(
      currentRecord,
      records
    );
    this.setLeftEnabled("clinicalData", enableLeft);
    this.setRightEnabled("clinicalData", enableRight);
  }

  private setManagementLateralities(
    records: PatientRecord[],
    currentRecord: PatientRecord
  ) {
    if (currentRecord.data?.management?.diagnosis_array === undefined) {
      //if no diagnosis selected leave defaults.
      return;
    }
    const { enableLeft, enableRight } = getManagementLateralities(
      currentRecord,
      records
    );
    this.setLeftEnabled("management", enableLeft);
    this.setRightEnabled("management", enableRight);
  }
}

export class RecordPage implements angular.IComponentOptions {
  static selector = "recordPage";
  static template = require("./record.html");
  static controller = RecordPageController;
  static bindings = {
    user: "<",
  };
}
