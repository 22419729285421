import { Appendix, IGlOption } from "app/core/services/appendix";
import { DicomTagHelper } from "app/core/services/dicom-tag-helper/dicom-tag-helper";
import { IPatientDocumentDicomData } from "models/patient-document.model";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";

export const FIELDS = [
  "blepharitis",
  "conj",
  "cornea",
  "ac",
  "ac quantifier",
  "iris",
] as const;

class SegmentAnteriorController
  extends GlFormController
  implements angular.IController, angular.IOnChanges {
  ascanDicomData: IPatientDocumentDicomData;

  leftAcd: number;
  rightAcd: number;

  defaultLeftAc: IGlOption;
  defaultRightAc: IGlOption;

  constructor(
    private DicomTagHelper: DicomTagHelper,
    private appendix: Appendix
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.ascanDicomData && this.ascanDicomData) {
      this.leftAcd = this.DicomTagHelper.getAcd("left", this.ascanDicomData);
      this.defaultLeftAc = this.mapAcdToAcValue(this.leftAcd);

      this.rightAcd = this.DicomTagHelper.getAcd(
        "right",
        this.ascanDicomData
      );
      this.defaultRightAc = this.mapAcdToAcValue(this.rightAcd);
    }
  }

  mapAcdToAcValue(acd: number) {
    const opts = this.appendix.get("ac");
    if (acd && acd <= 2.75) {
      return opts.find((opt) => opt.key === "shallow");
    } else if (acd && acd > 2.75) {
      return opts.find((opt) => opt.key === "deep");
    }
  }
}

export class SegmentAnterior implements angular.IComponentOptions {
  static selector = "segmentAnterior";
  static template = require("./segment-anterior.html");
  static controller = SegmentAnteriorController;
  static bindings = {
    ascanDicomData: "<?",
    enableLeft: "<",
    enableRight: "<",
    record: "<",
    ...GlFormControllerBindings,
  };
}
